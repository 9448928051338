import React, { useState, useEffect } from "react";

import styled from "styled-components";

import categoryIcon from "../../../assets/icons/categoryicon.svg";
import emptyPic from "../../../assets/icons/live-class-empty-icon.svg";

import item1 from "../../../assets/images/freecourse-item1.svg";
import item2 from "../../../assets/images/freecourse-item2.svg";
import item3 from "../../../assets/images/freecourse-item3.svg";
import FreeCourseCard from "../../includes/freeCourse/FreeCourseCard";
import Loader from "../../includes/loader/Loader";

// import { devConfig } from "../../../axiosConfig";
import EmptyPage from "../../includes/emptyPages/EmptyPage";

export default function FreeCourse() {
    const [isLoading, setLoading] = useState(true);
    const [freeCourseData, setFreeCourseData] = useState([]);

    // useEffect(() => {
    //     const getFreeCourseData = () => {
    //         setLoading(true);
    //         // let accessToken = state.user_details.access_token;
    //         devConfig
    //             .get("/", {
    //                 headers: {
    //                     Authorization: "Bearer " + "accessToken",
    //                 },
    //             })
    //             .then((response) => {
    //                 const { StatusCode, data } = response.data;
    //                 setFreeCourseData([
    //                     // {
    //                     //     id: 1,
    //                     //     category: "Featured",
    //                     //     items: [
    //                     //         {
    //                     //             id: 1,
    //                     //             title: "Fundamentals of design system",
    //                     //             image: item1,
    //                     //         },
    //                     //         {
    //                     //             id: 2,
    //                     //             title: "design theory and structure",
    //                     //             image: item2,
    //                     //         },
    //                     //         {
    //                     //             id: 3,
    //                     //             title: "Trignometric mathematics",
    //                     //             image: item3,
    //                     //         },
    //                     //         {
    //                     //             id: 4,
    //                     //             title: "Fundamentals of design system",
    //                     //             image: item1,
    //                     //         },
    //                     //         {
    //                     //             id: 5,
    //                     //             title: "design theory and structure",
    //                     //             image: item2,
    //                     //         },
    //                     //         {
    //                     //             id: 6,
    //                     //             title: "Trignometric mathematics",
    //                     //             image: item3,
    //                     //         },
    //                     //         {
    //                     //             id: 7,
    //                     //             title: "Fundamentals of design system",
    //                     //             image: item1,
    //                     //         },
    //                     //         {
    //                     //             id: 8,
    //                     //             title: "design theory and structure",
    //                     //             image: item2,
    //                     //         },
    //                     //         {
    //                     //             id: 9,
    //                     //             title: "Trignometric mathematics",
    //                     //             image: item3,
    //                     //         },
    //                     //     ],
    //                     // },
    //                     // {
    //                     //     id: 2,
    //                     //     category: "Recommended",
    //                     //     items: [
    //                     //         {
    //                     //             id: 1,
    //                     //             title: "Fundamentals of design system",
    //                     //             image: item1,
    //                     //         },
    //                     //         {
    //                     //             id: 2,
    //                     //             title: "design theory and structure",
    //                     //             image: item2,
    //                     //         },
    //                     //         {
    //                     //             id: 3,
    //                     //             title: "Trignometric mathematics",
    //                     //             image: item3,
    //                     //         },
    //                     //         {
    //                     //             id: 4,
    //                     //             title: "Fundamentals of design system",
    //                     //             image: item1,
    //                     //         },
    //                     //         {
    //                     //             id: 5,
    //                     //             title: "design theory and structure",
    //                     //             image: item2,
    //                     //         },
    //                     //         {
    //                     //             id: 6,
    //                     //             title: "Trignometric mathematics",
    //                     //             image: item3,
    //                     //         },
    //                     //         {
    //                     //             id: 7,
    //                     //             title: "Fundamentals of design system",
    //                     //             image: item1,
    //                     //         },
    //                     //         {
    //                     //             id: 8,
    //                     //             title: "design theory and structure",
    //                     //             image: item2,
    //                     //         },
    //                     //         {
    //                     //             id: 9,
    //                     //             title: "Trignometric mathematics",
    //                     //             image: item3,
    //                     //         },
    //                     //     ],
    //                     // },
    //                 ]);
    //                 if (StatusCode === 6000) {
    //                     setLoading(false);
    //                 } else {
    //                     setLoading(false);
    //                 }
    //             })
    //             .catch((error) => {});
    //     };
    //     // getFreeCourseData();
    // }, []);

    return (
        <MainContainer>
            {!isLoading ? (
                <>
                    <FreeCourseText>Free Course</FreeCourseText>
                    {freeCourseData.length > 0 ? (
                        <ContentWrapper>
                            {freeCourseData.map((freeCourse) => (
                                <SingleCategorizedContent key={freeCourse.id}>
                                    <CategoryTextWrapper>
                                        <CategoryIcon
                                            src={categoryIcon}
                                            alt="icon"
                                        />
                                        <CategoryText>
                                            {freeCourse.category}
                                        </CategoryText>
                                    </CategoryTextWrapper>
                                    <FreeCourseItemsWrapper>
                                        {freeCourse.items.map((item) => (
                                            <FreeCourseCard item={item} />
                                        ))}
                                    </FreeCourseItemsWrapper>
                                </SingleCategorizedContent>
                            ))}
                        </ContentWrapper>
                    ) : (
                        <EmptyContainer>
                            <EmptyPage
                                img={emptyPic}
                                message={"Sorry, nothing here"}
                            />
                        </EmptyContainer>
                    )}
                </>
            ) : (
                <Loader />
            )}
        </MainContainer>
    );
}

const MainContainer = styled.div`
    background: #ffffff;
    padding: 25px 8px 25px 20px;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 10px;
    height: calc(100vh - 110px);
    overflow-y: scroll;

    /* ::-webkit-scrollbar {
        width: 8px;
    }
    ::-webkit-scrollbar-thumb {
        background: #d6dee1;
        border-right: 6px white solid;
        background-clip: padding-box;
        border-radius: 24px;
    } */
`;
const FreeCourseText = styled.h5`
    font-size: 1.1rem;
    color: #000000;
    font-weight: 500;
`;
const ContentWrapper = styled.div`
    margin-top: 30px;
    /* height: calc(100vh - 200px); */
`;
const SingleCategorizedContent = styled.div`
    margin-bottom: 25px;
    &:last-child {
        margin-bottom: 0;
    }
`;
const CategoryTextWrapper = styled.div`
    display: flex;
    align-items: center;
`;
const CategoryIcon = styled.img`
    height: 25px;
    object-fit: contain;
`;
const CategoryText = styled.h6`
    font-size: 0.95rem;
    color: #404040;
    margin-left: 20px;
`;
const FreeCourseItemsWrapper = styled.div`
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    grid-gap: 15px;

    @media (max-width: 1500px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
    /* @media (max-width: 1180px) {
        grid-template-columns: 1fr 1fr;
    } */
    @media (max-width: 980px) {
        grid-template-columns: 1fr 1fr;
    }
    /* @media (max-width: 840px) {
        grid-template-columns: 1fr;
    } */
`;
const EmptyContainer = styled.div`
    height: calc(100vh - 180px);
    overflow-y: scroll;
    /* border: 1px solid #000; */
`;
// const MainContainer = styled.div``;
// const MainContainer = styled.div``;
// const MainContainer = styled.div``;
// const MainContainer = styled.div``;
