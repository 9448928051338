import React from "react";
import styled, { keyframes } from "styled-components";

export default function WelcomeBoxBig({ student_data }) {
    return (
        <WelcomeBox>
            <WelcomeBoxLeftPart>
                <WelcomeText>Welcome Back</WelcomeText>
                <StudentNameText>{student_data?.name}</StudentNameText>
            </WelcomeBoxLeftPart>
            <WelcomeBoxRightPart>
                {student_data?.batch_name && (
                    <BatchText>{student_data?.batch_name}</BatchText>
                )}
                {student_data?.program_name && student_data?.year_or_sem && (
                    <SemesterText>
                        {student_data?.program_name} -{" "}
                        {student_data?.year_or_sem}
                    </SemesterText>
                )}
            </WelcomeBoxRightPart>
        </WelcomeBox>
    );
}

const WelcomeBox = styled.div`
    background: #9a4bcb;
    border-radius: 10px;
    width: 100%;
    padding: 25px;
    margin-bottom: 25px;
    box-shadow: 0px 25px 25px -10px rgba(154, 75, 203, 0.17);
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const WelcomeBoxLeftPart = styled.div``;
const WelcomeText = styled.p`
    font-size: 0.9rem;
    font-weight: 400;
    color: #fff;
    margin-bottom: 8px;
    @media (max-width: 980px) {
        font-size: 0.85rem;
    }
`;
const StudentNameText = styled.h4`
    font-size: 1.5rem;
    font-weight: 600;
    text-transform: capitalize;
    color: #fff;
    /* width: 20%; */

    @media (max-width: 980px) {
        font-size: 1.3rem;
    }
`;

const WelcomeBoxRightPart = styled.p``;
const BatchText = styled.p`
    font-size: 0.9rem;
    font-weight: 400;
    color: #fff;
    margin-bottom: 8px;
    text-align: right;
    @media (max-width: 980px) {
        font-size: 0.85rem;
    }
`;
const SemesterText = styled.p`
    font-size: 1rem;
    font-weight: 400, regular;
    color: #fff;
    text-align: right;
    text-transform: capitalize;
    @media (max-width: 980px) {
        font-size: 0.85rem;
    }
`;
