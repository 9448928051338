import React from "react";
import styled from "styled-components";
import rightArrow from "../../assets/icons/pagination-right-arrow.svg";

export default function LibraryPagination({
    paginationData,
    setCurrentPage,
    currentPage,
    type,
}) {
    return (
        <MainContainer>
            <PaginationDiv>
                {paginationData?.has_previous && (
                    <ButtonBox
                        onClick={() => {
                            if (paginationData.has_previous) {
                                setCurrentPage(
                                    paginationData?.previous_page_number
                                );
                            }
                        }}
                    >
                        <ButtonIcon
                            style={{
                                transform: "rotate(180deg)",
                            }}
                            src={rightArrow}
                            alt="icon"
                        />
                    </ButtonBox>
                )}
                {paginationData.has_previous && (
                    <NumberedButton
                        onClick={() => {
                            setCurrentPage(
                                paginationData?.previous_page_number
                            );
                        }}
                    >
                        {paginationData?.previous_page_number}
                    </NumberedButton>
                )}
                {paginationData?.has_other_pages && (
                    <NumberedButton current={true}>
                        {currentPage}
                    </NumberedButton>
                )}
                {paginationData.has_next && (
                    <NumberedButton
                        onClick={() => {
                            setCurrentPage(paginationData?.next_page_number);
                        }}
                    >
                        {paginationData?.next_page_number}
                    </NumberedButton>
                )}
                {/* <Count>2</Count> */}
                {paginationData?.has_next && (
                    <ButtonBox
                        onClick={() => {
                            if (paginationData.has_next) {
                                setCurrentPage(paginationData.next_page_number);
                            }
                        }}
                    >
                        <ButtonIcon src={rightArrow} alt="icon" />
                    </ButtonBox>
                )}
            </PaginationDiv>
        </MainContainer>
    );
}

const MainContainer = styled.div`
    margin-top: 16px;
`;
const PaginationDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    & > div {
        margin-right: 12px;
    }
    & > div:last-child {
        margin-right: 0;
    }
`;
const ButtonBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #917afd;
    border-radius: 8px;
    box-sizing: border-box;
    cursor: pointer;
`;
const ButtonIcon = styled.img``;
const NumberedButton = styled.div`
    background: ${(props) => (props.current ? "#917AFD" : "#FFFFFF")};
    border: ${(props) =>
        props.current ? "1px solid #917afd" : "1px solid #917afd"};

    color: ${(props) => (props.current ? "#FFFFFF" : "#333333")};
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    box-sizing: border-box;
    cursor: pointer;
`;
const Button = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.25s;
    &:hover {
        box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
        /* transform: translateY(-0.1em); */
        opacity: 0.8;
    }
`;
const ButtonText = styled.h4`
    margin-bottom: -3px;
    color: #522f91;
    font-size: 16px;
`;
const Count = styled.h4`
    /* margin-left: 10px;
    font-size: 16px;
    background: ${(props) => (props.background ? props.background : "#f5f7fa")};
    color: ${(props) => (props.color ? props.color : "#522f91")};
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.5s; */
    background: ${(props) => (props.background ? props.background : "#f5f7fa")};
    color: ${(props) => (props.color ? props.color : "#522f91")};
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    &:hover {
        box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
        transform: translateY(-0.1em);
    }
`;
const Arrow = styled.img`
    /* margin-right: 8px; */
`;
