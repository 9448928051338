import React from "react";
import styled from "styled-components";
// import videoImage from "../../../../assets/images/video-image.svg";
import nextArrow from "../../../../assets/icons/arrow-right.svg";
import Background from "../../../../assets/images/program-begin-bg.svg";

export default function ProgramBeginPage() {
    return (
        <LeftContentsContainer>
            <MediaContainer style={{ backgroundImage: `url(${Background})` }}>
                {/* <MediaImage src={videoImage} /> */}
                <ContentBox>
                    <LeftContent>
                        <LessonText>Total: 10 Lessons</LessonText>
                        <BulletText>&#9679;</BulletText>
                        <DurationText>Duration: 10 Hr</DurationText>
                    </LeftContent>
                    <BeginProgramButton>
                        <BeginProgramText>Begin Program</BeginProgramText>
                        <NextIcon src={nextArrow} />
                    </BeginProgramButton>
                </ContentBox>
            </MediaContainer>
            <DescriptionContainer>
                <TopSection>
                    <TopUp>
                        <TitleText>
                            Ui/Ux Differents in design elements
                        </TitleText>
                    </TopUp>
                    <TopDown>
                        <CourseText>Course: design of elements</CourseText>
                        {/* <NextTopicButton>
                            <NextTopicText>Next Topic</NextTopicText>
                            <NextIcon src={nextArrow} />
                        </NextTopicButton> */}
                    </TopDown>
                </TopSection>
                <AboutSubjectText>About Subject</AboutSubjectText>
                <AboutSubjectDetailedContainer>
                    <AboutSectionDetailedText>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Tellus aenean in viverra etiam a. Arcu quam tincidunt
                        vulputate leo ut orci.g Pellentesque feugiat sit in sed
                        auctor eget cras. Egestas elit, et ullamcorper vel et.
                        Tincidunt sed a purus congue volutpat sed. Lectus libero
                        iaculis iaculis blandit at viverra non amet.Etiam sem
                        interdum neque, aliquam nulla tellus scelerisque in. Sed
                        le hyms esd calp Etiam sem interdum neque, aliquam nulla
                        tellus scelerisque in. Sed lectus ut scelerisque nisi,
                        semper semper facilisis sem. Donec se Lorem ipsum dolor
                        sit amet, consectetur adipiscing elit. Tellus aenean in
                        viverra etiam a. Arcu quam tincidunt vulputate leo ut
                        orci.g Pellentesque feugiat sit in sed auctor eget cras.
                        Egestas elit, et
                    </AboutSectionDetailedText>
                </AboutSubjectDetailedContainer>
            </DescriptionContainer>
        </LeftContentsContainer>
    );
}

const LeftContentsContainer = styled.div`
    padding-right: 5px;
`;
const MediaContainer = styled.div`
    width: 100%;
    height: 480px;
    border-radius: 10px;
    /* margin-bottom: 25px; */
    background-size: cover;
    position: relative;
    @media (max-width: 1500px) {
        height: 400px;
    }
    @media (max-width: 1300px) {
        height: 360px;
    }
    @media (max-width: 1180px) {
        height: 330px;
    }
`;
const ContentBox = styled.div`
    width: 100%;
    height: 100px;
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) 70%
    );
    position: absolute;
    bottom: 0;
    border-radius: 0 0 10px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px;
    @media (max-width: 1300px) {
        height: 80px;
    }
`;
const LeftContent = styled.div`
    display: flex;
    align-items: center;
    color: #fff;
`;
const LessonText = styled.p`
    font-size: 0.85rem;
    margin-right: 8px;
    line-height: 0.8em;
    letter-spacing: 0.2px;
`;
const BulletText = styled.p`
    font-size: 0.85rem;
    margin-right: 8px;
`;
const DurationText = styled.p`
    font-size: 0.85rem;
    line-height: 0.8em;
    letter-spacing: 0.2px;
`;

const BeginProgramButton = styled.button`
    background: #15bf81;
    display: inline-flex;
    align-items: center;
    border-radius: 6px;
    padding: 10px;
    cursor: pointer;
    @media (max-width: 1300px) {
        /* height: 80px; */
        padding: 8px;
    }
`;
const BeginProgramText = styled.p`
    margin-right: 5px;
    font-size: 0.8rem;
`;
const NextIcon = styled.img`
    width: 16px;
    object-fit: cover;
`;

// const MediaImage = styled.img`
//     display: block;
//     width: 100%;
//     /* height: 100%; */
//     object-fit: contain;
//     border-radius: 10px;
// `;
const DescriptionContainer = styled.div`
    margin-top: 25px;
`;
const TopSection = styled.div`
    margin-bottom: 15px;
`;
const TopUp = styled.div`
    margin-bottom: 5px;
`;
const TitleText = styled.h5`
    font-size: 1.3rem;
    font-weight: 500;
    text-transform: capitalize;
    color: #000;
`;
const CourseText = styled.p`
    color: #c1c1c1;
    text-transform: capitalize;
    font-size: 1rem;
`;
const TopDown = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;
// const NextTopicButton = styled.button`
//     background: #15bf81;
//     display: inline-flex;
//     align-items: center;
//     border-radius: 6px;
//     padding: 6px 14px;
//     cursor: pointer;
// `;
// const NextTopicText = styled.p`
//     margin-right: 5px;
//     font-size: 0.9rem;
// `;
// const NextIcon = styled.img`
//     width: 20px;
//     object-fit: cover;
// `;
const AboutSubjectText = styled.div`
    font-size: 1.1rem;
    font-weight: 500;
    color: #404040;
    text-transform: capitalize;
    margin-bottom: 15px;
`;
const AboutSubjectDetailedContainer = styled.div`
    @media (max-width: 1500px) {
        width: 98%;
    }
`;
const AboutSectionDetailedText = styled.p`
    color: #9d9d9d;
    font-size: 0.9rem;
    /* width: 99%; */
`;
