import React from "react";
import styled from "styled-components";

export default function Pagination({
    paginationData,
    setCurrentPage,
    currentPage,
    type,
}) {
    return (
        <MainContainer>
            <PaginationDiv allsubjects={type === "allSubjects" ? true : false}>
                {paginationData?.has_previous && (
                    <Button
                        onClick={() => {
                            if (paginationData.has_previous) {
                                setCurrentPage(
                                    paginationData?.previous_page_number
                                );
                            }
                        }}
                    >
                        <Arrow
                            src={
                                require("../../assets/icons/arrow.svg").default
                            }
                        />
                        <ButtonText style={{ marginLeft: "8px" }}>
                            Previous
                        </ButtonText>
                    </Button>
                )}
                {paginationData.has_previous && (
                    <Count
                        onClick={() => {
                            setCurrentPage(
                                paginationData?.previous_page_number
                            );
                        }}
                    >
                        {paginationData?.previous_page_number}
                    </Count>
                )}
                {paginationData?.has_other_pages && (
                    <Count background={"#522f91"} color={"#fff"}>
                        {currentPage}
                    </Count>
                )}
                {paginationData.has_next && (
                    <Count
                        onClick={() => {
                            setCurrentPage(paginationData?.next_page_number);
                        }}
                    >
                        {paginationData?.next_page_number}
                    </Count>
                )}
                {/* <Count>2</Count> */}
                {paginationData?.has_next && (
                    <Button
                        onClick={() => {
                            if (paginationData.has_next) {
                                setCurrentPage(paginationData.next_page_number);
                            }
                        }}
                    >
                        <ButtonText
                            style={{ marginRight: "8px", marginLeft: "10px" }}
                        >
                            Next
                        </ButtonText>
                        <Arrow
                            style={{
                                transform: "rotate(180deg)",
                            }}
                            src={
                                require("../../assets/icons/arrow.svg").default
                            }
                        />
                    </Button>
                )}
            </PaginationDiv>
        </MainContainer>
    );
}

const MainContainer = styled.div``;
const PaginationDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* margin-right: 30px; */
    margin-top: 10px;
`;
const Button = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.25s;
    &:hover {
        box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
        /* transform: translateY(-0.1em); */
        opacity: 0.8;
    }
`;
const ButtonText = styled.h4`
    margin-bottom: -3px;
    color: #522f91;
    font-size: 16px;
`;
const Count = styled.h4`
    margin-left: 10px;
    font-size: 16px;
    background: ${(props) => (props.background ? props.background : "#f5f7fa")};
    color: ${(props) => (props.color ? props.color : "#522f91")};
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.5s;
    &:hover {
        box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
        transform: translateY(-0.1em);
    }
`;
const Arrow = styled.img`
    /* margin-right: 8px; */
`;
