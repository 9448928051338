import React from "react";
import styled from "styled-components";

export default function ProgressBar({ current, total, top, type, bg_color }) {
    const width = (current / total) * 100;
    const percentage = Math.round(width);

    const bar = {
        display: "block",
        height: "6px",
        width: `${percentage}%`,
        background: "#9178f8",
        borderRadius: "10px",
    };

    return (
        <>
            {type === "programCard" ? (
                <PgCardBarContainer>
                    <PgCardBar>
                        <Bar bg_color={bg_color}>
                            <Progress style={bar} />
                        </Bar>
                    </PgCardBar>
                    <PgCardPercentageText>{Math.round(current)}%</PgCardPercentageText>
                </PgCardBarContainer>
            ) : (
                <Container>
                    {top !== "not-required" && (
                        <Top>
                            <Step>
                                Question {current}/{total}
                            </Step>
                            <Percentage>{Math.round(percentage)}%</Percentage>
                        </Top>
                    )}
                    <Bar>
                        <Progress style={bar} />
                    </Bar>
                </Container>
            )}
        </>
    );
}

const Container = styled.div``;
const Top = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
`;
const Step = styled.h5`
    font-size: 14px;
    font-family: "inter-regular";
    color: #b0b1b1;
`;
const Percentage = styled.h6`
    font-size: 16px;
    font-family: "inter-semibold";
    color: #0b3f7a;
    @media (max-width: 460px) {
        font-size: 14px;
    }
`;
const Bar = styled.span`
    display: flex;
    margin: 0 auto;
    background: ${(bg_color) =>
        bg_color ? "rgba(31, 13, 219, 0.1)" : "#f5f5f5"};
    /* margin-bottom: 50px; */
    border-radius: 10px;
    @media (max-width: 768px) {
        margin-bottom: 0;
    }
`;
const Progress = styled.span``;
const PgCardBarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const PgCardBar = styled.div`
    width: 85%;
`;
const PgCardPercentageText = styled.p`
    color: #000;
    font-size: 0.8rem;
    color: #9d9d9d;
    width: 12%;
    text-align: end;
`;
