import React, { useState, useContext, useEffect } from "react";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import styled from "styled-components";
import QuestionInstruction from "./../attendExam/QuestionInstruction";
import { Context } from "./../../../contexts/Store";
import { baseConfig } from "./../../../axiosConfig";

export default function InstructionMainPage() {
	const history = useHistory();
	const { id } = useParams();
	const [allDetails, setAllDetails] = useState({});
	const { state } = useContext(Context);
	const [isLoading, setLoading] = useState(true);

	const getExamDetails = () => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`exams/exam-attend-data/${id}`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setAllDetails(data);
					setTimeout(() => {
						setLoading(false);
					}, 300);
				} else {
				}
			})
			.catch((error) => {});
	};

	useEffect(() => {
		getExamDetails();
	}, []);

	return (
		<MainContainer>
			<LogoContainer>
				<Logo
					// src={
					// 	require("../../../assets/images/singhania-logo.png")
					// 		.default
					// }
					src={require("../../../assets/images/sunrise-university logo.png").default}
				/>
			</LogoContainer>
			{!isLoading && (
				<CardContainer>
					<Top>
						<Flex>
							<Sub1>
								<Text1>{allDetails.exam_data.exam_name}</Text1>
							</Sub1>
							<Sub2>
								<Div>
									<Text3>Name:</Text3>
									<Text2>{allDetails.student_data.name}</Text2>
								</Div>
								<Flex>
									<Text3>Enrollment No:</Text3>
									<Text4>{allDetails.student_data.program_data.enrollment_no}</Text4>
								</Flex>
							</Sub2>
						</Flex>
					</Top>
					<Bottom>
						<Sub1>
							<Flex2>
								<Text3>Subject:</Text3>
								<Text4>{allDetails.subject_data.subject_name}</Text4>
							</Flex2>
							<Flex2>
								<Text3>Program:</Text3>
								<Text4>{allDetails.student_data.program_data.course}</Text4>
							</Flex2>
						</Sub1>
						<Div>
							<Box>
								<FlexBox>
									<Span>
										<Icon src={require("./../../../assets/icons/calender.svg").default} />
									</Span>
									<Text5>{allDetails.subject_data.date}</Text5>
								</FlexBox>
							</Box>
							<Box>
								<FlexBox>
									<Span>
										<Icon src={require("./../../../assets/icons/star.svg").default} />
									</Span>
									<Text5>{allDetails.exam_data.total_mark} Marks</Text5>
								</FlexBox>
							</Box>
						</Div>
					</Bottom>
				</CardContainer>
			)}
			<InstructionDiv>
				<QuestionInstruction />
			</InstructionDiv>
			<ButtonContainer
				onClick={() => {
					history.push(`/exam-questions/${id}`);
				}}>
				<Buttontext>Proceed To Exam</Buttontext>
				<ArrowSpan>
					<Arrow src={require("../../../assets/icons/righarrow.png").default} />
				</ArrowSpan>
			</ButtonContainer>
		</MainContainer>
	);
}
const MainContainer = styled.div`
	background: #f9f9f9;
	padding-bottom: 50px;
	//   height: 100vh;
`;
const LogoContainer = styled.div`
	/* padding-top: 50px; */
	/* width: 18%;
	margin: 0 auto; */
	width: 16%;
	margin: 30px auto;
`;
const Logo = styled.img`
	display: block;
	width: 100%;
`;
const CardContainer = styled.div`
	width: 80%;
	padding: 20px;
	border-radius: 16px;
	margin: 0 auto;
	margin-top: 20px;
	margin-bottom: 15px;
	background: #fff;
	border: 1px solid #e5e5e5;
`;
const Top = styled.div``;
const TopSpan = styled.span`
	position: absolute;
	padding: 8px;
	width: 6%;
	top: 0;
	right: 0;
	cursor: pointer;
`;
const Close = styled.img`
	display: block;
	width: 100%;
`;
const Bottom = styled.div`
	display: flex;
	justify-content: space-between;
`;
const Box = styled.div`
	border: 1px solid #c4c4c4;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px;
	/* margin-bottom: 10px; */
	border-radius: 12px;
	margin-right: 10px;
	:last-child {
		margin-right: 0px;
	}
`;
const Span = styled.span`
	/* width: 16%; */
	margin-right: 5px;
`;
const Icon = styled.img`
	display: block;
	width: 100%;
`;
const Flex = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const Flex2 = styled.div`
	display: flex;
	align-items: center;
`;
const FlexBox = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;
const Sub1 = styled.div`
	/* margin-bottom: 20px; */
`;
const Sub2 = styled.div`
	/* margin-bottom: 20px; */
`;
const Div = styled.div`
	display: flex;
	align-items: center;
	:nth-child(2){
		margin-top: 15px;
	}
`;
const Text1 = styled.h3`
	font-size: 18px;
	color: #522f91;
	line-height: 30px;
	font-family: "inter-medium";
`;
const Text2 = styled.h3`
	text-align: right;
	/* margin-bottom: 10px; */
	font-size: 16px;
	font-family: "inter-medium";
	text-transform: capitalize;
`;
const Text3 = styled.h4`
	font-family: "inter-regular";
	/* margin-bottom: 10px; */
	margin-right: 5px;
	color: #010101;
	opacity: 40%;
	font-size: 16px;
`;
const Text4 = styled.h4`
	font-family: "inter-medium";
	/* margin-bottom: 10px; */
	font-size: 14px;
`;
const Text5 = styled.h4`
	font-family: "inter-regular";
	font-size: 14px;
`;
const InstructionDiv = styled.div`
	width: 80%;
	margin: 0 auto;
`;
const ButtonContainer = styled.button`
	background: #917afd;
	display: flex;
	padding: 10px 30px;
	border-radius: 6px;
	margin: 0 auto;
	height: 50px;
	align-items: center;
	margin-top: 20px;

	cursor: pointer;
`;
const Buttontext = styled.h5`
	color: #fff;
	font-family: "inter-semibold";
	font-size: 16px;
`;
const ArrowSpan = styled.span`
	width: 10%;
	margin-left: 8px;
`;
const Arrow = styled.img`
	display: block;
	width: 100%;
`;
