import React, { useState, useRef } from "react";
import styled from "styled-components";

// Importing Icons
import dropdown from "../../../../assets/icons/dropdown-icon.svg";
import dropdownUp from "../../../../assets/icons/dropdown-up-icon.svg";

import TopicCard from "./TopicCard";
import SubTopicCard from "./SubTopicCard";

export default function LessonCard({
	data,
	index,
	selectedLesson,
	setSelectedLesson,
	selectedTopic,
	setSelectedTopic,
	selectedContent,
	setSelectedContent,
	// setSingleContentId,
	getSingleContent,
}) {
	return (
		<>
			<LessonBoxWrapper>
				<LessonBox
					completed={data?.status === "completed" ? true : false}
					selected={selectedLesson === data?.id ? true : false}
					onClick={() => {
						selectedLesson === data?.id
							? setSelectedLesson("")
							: setSelectedLesson(data?.id);
					}}
				>
					<LeftPart>
						{/* <Left>
                        <CompletedIcon src={topicCompleted} alt="icon" />
                    </Left> */}
						<Right>
							<LessonText
								completed={
									data.status === "completed" ? true : false
								}
							>
								{index + 1}. Lesson
							</LessonText>
							<TitleText
								completed={
									data.status === "completed" ? true : false
								}
							>
								{data.name.length > 20
									? data.name.substring(0, 20) + "..."
									: data.name}
							</TitleText>
						</Right>
					</LeftPart>
					{data.topics.length > 0 && (
						<>
							{selectedLesson === data.id ? (
								<RightPart
								// onClick={() => {
								//     setSelectedLesson("");
								// }}
								>
									<DropdownIcon src={dropdownUp} alt="icon" />
								</RightPart>
							) : (
								<RightPart
								// onClick={() => {
								//     setSelectedLesson(data.id);
								// }}
								>
									<DropdownIcon src={dropdown} alt="icon" />
								</RightPart>
							)}
						</>
					)}
				</LessonBox>

				{selectedLesson === data?.id && (
					<>
						<SubTopicCardWrapper>
							{data.contents.map((subTopic) => (
								<SubTopicCard
									data={subTopic}
									key={subTopic.id}
									selectedContent={selectedContent}
									setSelectedContent={setSelectedContent}
									getSingleContent={getSingleContent}
								/>
							))}
						</SubTopicCardWrapper>
						<TopicCardWrapper>
							{data?.topics.map(
								(topic, index) =>
									topic.contents.length >= 0 && (
										<TopicCard
											key={topic.id}
											data={topic}
											index={index}
											selectedTopic={selectedTopic}
											setSelectedTopic={setSelectedTopic}
											selectedContent={selectedContent}
											setSelectedContent={setSelectedContent}
											// setSingleContentId={setSingleContentId}
											getSingleContent={getSingleContent}
										/>
									)
							)}
						</TopicCardWrapper>
					</>
				)}
			</LessonBoxWrapper>
		</>
	);
}
const LessonBoxWrapper = styled.div`
	margin-bottom: 10px;
	/* animation: rotateMenu 0.3s ease-in-out forwards; */
	transform-origin: top center;
`;
const LessonBox = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px 5%;
	border: ${(props) => (props.completed ? "none" : "1px solid #E7E7E7;")};
	box-sizing: border-box;
	border-radius: ${(props) => (props.selected ? "10px 10px 0 0" : "10px")};
	user-select: none;
	background: ${(props) =>
		props.completed
			? "linear-gradient(91.69deg, #EA1752 -3.39%, #522F91 99.66%)"
			: "#fff"};
	@media (max-width: 1500px) {
		padding: 10px 5%;
	}
`;
const LeftPart = styled.div`
	display: flex;
	align-items: center;
`;
const Right = styled.div`
	/* margin-left: 8px; */
`;
const LessonText = styled.div`
	font-size: 0.71rem;
	color: ${(props) => (props.completed ? "#fff" : "#000")};
	opacity: 0.7;
	/* @media (max-width: 1180px) {
        font-size: 0.71rem;
    } */
`;
const TitleText = styled.h6`
	font-size: 0.85rem;
	margin-top: 10px;
	color: ${(props) => (props.completed ? "#fff" : "#000")};
`;
const RightPart = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 22px;
	height: 23px;
	border-radius: 50%;
	box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
		rgba(17, 17, 26, 0.1) 0px 0px 8px;
	cursor: pointer;
	&:hover {
		box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
	}
`;
const DropdownIcon = styled.img`
	margin-top: 1.5px;
	display: block;
	width: 80%;
	height: 75%;
	object-fit: cover;
`;
const TopicCardWrapper = styled.div`
	transition: 0.5s;
	animation: slideInBottom2 0.3s ease-in-out forwards;
	transform-origin: top center;
`;

const SubTopicCardWrapper = styled.div`
	transition: 0.5s;
	animation: slideInBottom2 0.3s ease-in-out forwards;
	transform-origin: top center;
`;
