import React, { useRef } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import styled from "styled-components";

export default function Dropdown({
    title,
    data,
    search = false,
    value,
    setValue,
    validation,
    msg,
    fieldName,
    isStatus,
    setIsStatus,
}) {
    const handleChange = (e) => {
        e.preventDefault();
        setValue(e.target.value);
        if (setIsStatus) {
            setIsStatus(false);
        }
    };

    let textInput = useRef(null);

    return (
        <Outer>
            <Label
                onClick={() => {
                    textInput.current.focus();
                }}
            >
                {title}
            </Label>
            <div>
                <div style={{ height: "100%" }}>
                    <Box
                        sx={
                            {
                                // background: `#ffffff`,
                                // borderRadius: "8px",
                                // height: "100%",
                                // border: "none"
                            }
                        }
                    >
                        <FormControl fullWidth variant="outlined" size="small">
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={value}
                                // label="Age"
                                onChange={handleChange}
                                sx={{
                                    height: "100%",
                                    // color: `#6B6B6B`,
                                    // "&& .MuiSvgIcon-root": {
                                    //     fill: `#6B6B6B`,
                                    // },
                                    "&& fieldset": {
                                        // border: `1px solid ${THEME_COLORS.background_4}`,
                                        // border: `1px solid #EFC1B4`,
                                        // borderRadius: "8px",
                                    },
                                    "&:hover": {
                                        "&& fieldset": {
                                            // border: `1px solid #EFC1B4`,
                                        },
                                    },
                                }}
                                inputRef={textInput}
                                MenuProps={{
                                    sx: {
                                        "&& .MuiList-root": {
                                            // backgroundColor: `#ffffff`,
                                            // color: `#6B6B6B`,
                                            // border: `1px solid #EFC1B4`,
                                            borderRadius: 0,
                                        },
                                        "&& .MuiMenuItem-root.Mui-selected:hover":
                                            {
                                                color: `#000`,
                                            },
                                        // "&& .MuiPaper-root": {
                                        // 	border: `1px solid ${THEME_COLORS.border_2}`,
                                        // 	borderRadius: "4px",
                                        // },
                                    },
                                }}
                            >
                                {/* {search && (
                                    // <MenuItem>
                                    //     <Search />
                                    // </MenuItem>
                                )} */}
                                {(data || []).map((item, i) => {
                                    return (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Box>
                </div>
            </div>
        </Outer>
    );
}

const Outer = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    /* height: 32px; */
    /* box-sizing: border-box; */
    width: 100%;
`;

const Label = styled.p`
    font-family: "inter-medium";
    color: #000;
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 4px;
`;
