import React, { useState, useRef, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import notificationIcon from "../../../assets/icons/notification-icon.svg";
import searchIcon from "../../../assets/icons/search.svg";
import profileIcon from "../../../assets/icons/profile.png";
import viewProfileIcon from "../../../assets/icons/user-simple.svg";
import logoutIcon from "../../../assets/icons/log-out.svg";

import LogOutModal from "./LogOutModal";

// material ui
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { TopUpContext } from "../../../contexts/TopUpContext/TopUpStore";
import { updateTopUpProgram } from "../../includes/functions/TopUpFunctions";
import { Context } from "../../../contexts/Store";

export default function TopBar({ student }) {
	const history = useHistory();
	const location = useLocation();
	const { topUpState, topUpDispatch } = useContext(TopUpContext);
	const { state } = useContext(Context);

	const [isModal, setIsModal] = useState(false);
	const [isLogOutModal, setLogOutModal] = useState(false);

	const [selectedProgram, setSelectedProgram] = useState("");

	const wrapperRef = useRef(null);
	useOutsideAlerter(wrapperRef);

	const toggleActiveBox = () => {
		setIsModal((prevState) => !prevState);
	};

	function useOutsideAlerter(ref) {
		useEffect(() => {
			function handleClickOutside(event) {
				if (ref.current && !ref.current.contains(event.target)) {
					setIsModal(false);
				}
			}
			document.addEventListener("mousedown", handleClickOutside);
			return () => {
				document.removeEventListener("mousedown", handleClickOutside);
			};
		}, []);
	}

	return (
		<MainDiv>
			<LeftPart>
				{location.pathname !== "/" && (
					<React.Fragment>
						{topUpState?.top_up_details?.selected_program?.id && (
							<Box>
								<FormControl fullWidth>
									<Select
										// sx={{
										// 	height: 40,
										// 	width: 300,
										// }}
										id="demo-simple-select"
										onChange={(e) => {
											updateTopUpProgram(
												state,
												topUpDispatch,
												e.target.value,
												null,
												history
											);
										}}
										sx={{
											height: 40,
											width: 300,
											color: "#404040",
											background: "#FBFBFB",
											".MuiOutlinedInput-notchedOutline":
												{
													border: "1px solid #E5E5E5",
												},
											"&.Mui-focused .MuiOutlinedInput-notchedOutline":
												{
													border: "1px solid #E5E5E5",
												},
											"&:hover .MuiOutlinedInput-notchedOutline":
												{
													border: "1px solid #E5E5E5",
												},
											".MuiSvgIcon-root ": {
												fill: "#E5E5E5 !important",
											},
										}}
										value={
											topUpState?.top_up_details
												?.selected_program?.id
										}
									>
										{topUpState?.top_up_details?.data?.map(
											(data) => (
												<MenuItem
													key={data.id}
													value={data.id}
												>
													{data.course_name}{" "}
													{data.specialization_name}
												</MenuItem>
											)
										)}

										{/* <MenuItem value={1}>1111</MenuItem>
									<MenuItem value={2}>ewe</MenuItem>
									<MenuItem value={3}>sewess</MenuItem> */}
									</Select>
								</FormControl>
							</Box>
						)}

						{topUpState?.top_up_details?.selected_program?.periods
							?.length > 0 && (
							<Box>
								<FormControl fullWidth>
									<Select
										sx={{
											height: 40,
											width: 150,
											color: "#404040",
											background: "#FBFBFB",
											".MuiOutlinedInput-notchedOutline":
												{
													border: "1px solid #E5E5E5",
												},
											"&.Mui-focused .MuiOutlinedInput-notchedOutline":
												{
													border: "1px solid #E5E5E5",
												},
											"&:hover .MuiOutlinedInput-notchedOutline":
												{
													border: "1px solid #E5E5E5",
												},
											".MuiSvgIcon-root ": {
												fill: "#E5E5E5 !important",
											},
										}}
										id="demo-simple-select"
										onChange={(e) => {
											// setSelectedSpecialization(
											// 	e.target.value
											// );
											updateTopUpProgram(
												state,
												topUpDispatch,
												topUpState?.top_up_details
													?.selected_program?.id,
												e.target.value,
												history
											);
										}}
										value={
											topUpState.top_up_details
												.selected_period.period
										}
									>
										{topUpState?.top_up_details?.selected_program?.periods?.map(
											(data) => (
												<MenuItem
													key={data.period}
													value={data.period}
												>
													{topUpState?.top_up_details
														?.selected_program
														?.semester_oriented
														? `Sem`
														: `Year`}{" "}
													{data.period}
												</MenuItem>
											)
										)}
									</Select>
								</FormControl>
							</Box>
						)}
					</React.Fragment>
				)}
				{/* <SearchBarDiv>
                    <SearchInput type="text" placeholder="Search" id="search" />
                    <SearchIcon src={searchIcon} alt="icon" htmlFor="search" />
                </SearchBarDiv> */}
			</LeftPart>
			<RightPart>
				{/* <NotificationIconDiv
                    onClick={() => {
                        history.push("/notifications/");
                    }}
                >
                    <NotificationIcon src={notificationIcon} alt="icon" />
                </NotificationIconDiv> */}
				<ProfileIconDiv
					ref={wrapperRef}
					onClick={() => {
						toggleActiveBox();
					}}
				>
					<ProfileIcon
						src={student.image ? student.image : profileIcon}
						alt="icon"
					/>

					{isModal && (
						<PopUpDiv>
							<MenuTextWrapper
								onClick={() => {
									history.push("/profile/");
								}}
							>
								<SubIcon src={viewProfileIcon} alt="icon" />
								<MenuText>View Profile</MenuText>
							</MenuTextWrapper>
							{/* <MenuTextWrapper>
                                <MenuText>Edit Profile</MenuText>
                            </MenuTextWrapper> */}
							<MenuTextWrapper
								onClick={() => {
									setLogOutModal(true);
								}}
							>
								<SubIcon src={logoutIcon} alt="icon" />
								<MenuText>Logout</MenuText>
							</MenuTextWrapper>
						</PopUpDiv>
					)}
				</ProfileIconDiv>
			</RightPart>
			{isLogOutModal && (
				<LogOutModal
					isLogOutModal={isLogOutModal}
					setLogOutModal={setLogOutModal}
				/>
			)}
		</MainDiv>
	);
}

const MainDiv = styled.div`
	background: #fff;
	padding: 0px 10px;
	border: 1px solid #e5e5e5;
	/* box-sizing: border-box; */
	border-radius: 10px;
	height: 55px;
	margin-bottom: 15px;
	display: flex;
	justify-content: space-between;
	position: sticky;
	align-items: center;
	/* position: fixed; */
`;
const LeftPart = styled.div`
	width: 45%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 20px;
`;
const SearchBarDiv = styled.label`
	width: 300px;
	height: 40px;
	padding: 10px;
	opacity: 0.3;
	background: rgba(219, 219, 219, 50%);
	border-radius: 8px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const SearchInput = styled.input`
	display: inline-block;
	width: 100%;
	height: 100%;
`;
const SearchIcon = styled.img`
	width: 18px;
	height: 18px;
	object-fit: contain;
	/* opacity: 0.3; */
`;

const RightPart = styled.div`
	display: flex;
	justify-content: end;
	align-items: center;
`;
const PopUpDiv = styled.div`
	width: 150px;
	position: absolute;
	right: -13px;
	top: 39px;
	background: #fff;
	border: 1px solid #e5e5e5;
	z-index: 5;
	/* display: none; */
	border-radius: 10px;
	animation: growDown 0.3s ease-in-out forwards;
	transform-origin: top center;
	transition: 1s;
`;

const MenuTextWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px 12px;
	border-bottom: 1px solid #e5e5e5;
	opacity: 0.6;
	transition: 0.3s;
	&:last-child {
		border-bottom: none;
	}
	&:hover {
		opacity: 1;
	}
`;
const SubIcon = styled.img`
	width: 18px;
	height: 18px;
	/* object-fit: cover; */
`;
const MenuText = styled.div`
	font-size: 0.86rem;
`;
const NotificationIconDiv = styled.div`
	width: 19px;
	height: 19px;
	margin-right: 25px;
	cursor: pointer;
`;
const NotificationIcon = styled.img`
	width: 100%;
	height: 100%;
	display: block;
`;
const ProfileIconDiv = styled.div`
	width: 28px;
	height: 28px;
	border-radius: 50%;
	cursor: pointer;
	position: relative;

	&:hover ${PopUpDiv} {
		/* display: block; */
	}
`;
const ProfileIcon = styled.img`
	width: 100%;
	height: 100%;
	display: block;
	border-radius: 50%;
	object-fit: cover;
`;

// const RightPart = styled.div``;
// const RightPart = styled.div``;
// const RightPart = styled.div``;
// const RightPart = styled.div``;
