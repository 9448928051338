import React, { useState } from "react";
import styled from "styled-components";

import download from "../../../assets/icons/download_blue.svg";
import read from "../../../assets/icons/menu_book.svg";
import book from "../../../assets/images/book1.png";
import unFavouritedIcon from "../../../assets/icons/unfavourite-icon.svg";
import favouritedIcon from "../../../assets/icons/new-favourite-icon.svg";
import removeIcon from "../../../assets/icons/rounded-remove.svg";

import { useHistory } from "react-router-dom";

export default function SingleBookCard({
    isModal,
    setIsModal,
    data,
    setSelectedBookId,
    handleFavourite,
    handleUnFavourite,
    type,
}) {
    // const [isModal, setIsModal] = useState(false);
    let history = useHistory();

    return (
        <>
            <BookDiv key={data.id}>
                {type == "favourite" ? (
                    <FavIcon
                        style={{ width: "22px", height: "22px" }}
                        // isFavourite={data.is_favourite}
                        onClick={() => {
                            handleFavourite(data.id);
                        }}
                        src={removeIcon}
                        alt="Image"
                    />
                ) : (
                    <FavIcon
                        onClick={() => {
                            handleFavourite(data.id);
                        }}
                        src={
                            data.is_favourite
                                ? favouritedIcon
                                : unFavouritedIcon
                        }
                        alt="Image"
                    />
                )}

                <BImgDiv
                    onClick={() => {
                        if (type == "inside-modal") {
                            setSelectedBookId(data?.id);
                        } else {
                            setSelectedBookId(data?.id);
                            setIsModal(true);
                        }
                    }}
                >
                    <BookImage src={data?.cover_photo} alt="Image" />
                </BImgDiv>
                <OtherDetailsContainer>
                    <BookName
                    // style={{
                    //     whiteSpace: "nowrap",
                    //     overflow: "hidden",
                    //     textOverflow: "ellipsis",
                    // }}
                    >
                        {data?.title}
                    </BookName>
                    <Author
                    // style={{
                    //     whiteSpace: "nowrap",
                    //     overflow: "hidden",
                    //     textOverflow: "ellipsis",
                    // }}
                    >
                        {data?.author}
                    </Author>
                    <MoreSection>
                        <OptionDiv>
                            <OptionItem
                                onClick={(event) => {
                                    event.stopPropagation();
                                    window.open(
                                        data.content_file,
                                        "_blank",
                                        "noreferrer"
                                    );
                                }}
                            >
                                <DownIcon src={download} alt="Icon" />
                            </OptionItem>
                            <OptionItem>
                                <ReadIcon
                                    onClick={() =>
                                        history.push({
                                            pathname: `/library/readpdf/${data.id}`,
                                        })
                                    }
                                    src={read}
                                    alt="Icon"
                                />
                            </OptionItem>
                        </OptionDiv>
                    </MoreSection>
                </OtherDetailsContainer>
            </BookDiv>
        </>
    );
}
const BookDiv = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 100%;
    /* min-width: 0; */
    background: #ffffff;
    border: 1px solid #e7e7e7;
    border-radius: 16px;
    box-sizing: border-box;
    width: 100%;
`;
const BImgDiv = styled.div`
    width: 60%;
    height: 230px;
    /* margin: 0 60px 0; */
    cursor: pointer;
    margin: 0 auto;
`;
const BookImage = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;
const BookName = styled.h3`
    /* max-width: 90%; */
    max-width: 220px;
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 12px;
    text-align: center;
    color: #010101;
    font-weight: 500;
    font-size: 15px;
    /* line-height: 17px; */
    line-height: 1.4em;
    letter-spacing: 0.01em;
    text-transform: capitalize;
`;
const Author = styled.h4`
    margin: 6px auto 0 auto;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #9d9d9d;
    max-width: 240px;
`;
const MoreSection = styled.div`
    padding: 12px;
`;
const OptionDiv = styled.div`
    /* display: flex;
    align-items: center;
    justify-content: space-around; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    background: #fff;
    border: 1px solid #e7e7e7;
    border-radius: 6px;
    /* padding: 10px 12px; */
`;
const DownIcon = styled.img`
    width: 20px;
    display: block;
    object-fit: cover;
    cursor: pointer;
`;
const ReadIcon = styled.img`
    width: 20px;
    display: block;
    object-fit: cover;
    cursor: pointer;
`;
const FavIcon = styled.img`
    cursor: pointer;
    right: 16px;
    top: 12px;
    position: absolute;
    display: block;
    width: 32px;
    height: 32px;
    object-fit: contain;
    filter: ${(props) => (props.isFavourite ? "invert(0.5)" : null)};
`;
const OtherDetailsContainer = styled.div`
    background: #f9f9f9;
    padding: 8px 12px;
    height: calc(100% - 230px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
const OptionItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    box-sizing: border-box;
    cursor: pointer;
    &:first-child {
        border-right: 1px solid #e7e7e7;
    }
`;
