import React from "react";
import styled from "styled-components";
import NotificationCard from "../../includes/NotificationCard";

export default function Notifications() {
    return (
        <MainContainer>
            <SectionTitle>Notications</SectionTitle>
            <ScrollDiv>
                <DayTile>
                    <DayText>Today</DayText>
                </DayTile>
                <NotificationsCardWrapper>
                    <NotificationCard />
                    <NotificationCard />
                    <NotificationCard />
                    <NotificationCard />
                    <NotificationCard />
                    <NotificationCard />
                    <NotificationCard />
                    <NotificationCard />
                    <NotificationCard />
                </NotificationsCardWrapper>
            </ScrollDiv>
        </MainContainer>
    );
}

const MainContainer = styled.div`
    background: #ffffff;
    padding: 20px 15px;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 10px;
    height: calc(100vh - 110px);
    width: 75%;
    /* &::-webkit-scrollbar {
        width: 3px;
    }
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #d6dee1;
        border-radius: 20px;
    } */

    @media (max-width: 980px) {
        width: 100%;
    }
`;
const ScrollDiv = styled.div`
    height: calc(100vh - 190px);
    overflow-y: scroll;
`;
const SectionTitle = styled.h3`
    font-size: 1.3rem;
    font-weight: 500;
    color: #000000;
    margin-bottom: 20px;
`;
const DayTile = styled.div`
    background: #f5f5f5;
    border-radius: 8px;
    padding: 16px 20px;
`;
const DayText = styled.h6`
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0.2px;
`;
const NotificationsCardWrapper = styled.div`
    padding: 0px 20px;
`;
// const SectionTitle = styled.h3``;
// const SectionTitle = styled.h3``;
