import React, { useState } from "react";
import styled from "styled-components";
export default function Tabs({
    data,
    clickedTab,
    setClickedTab,
    setClickedRequest,
    selectedCategories,
    setSelectedCategories,
    currentPage,
    setCurrentPage,
}) {
    return (
        <>
            <InsideButton
                className={selectedCategories.length === 0 ? "active" : ""}
                onClick={() => {
                    if (currentPage != "1") {
                        setCurrentPage("1");
                    }
                    setSelectedCategories([]);
                }}
            >
                All
            </InsideButton>
            {data.map((item) => (
                <InsideButton
                    key={item.id}
                    // className={clickedTab === item.name ? "active" : ""}
                    className={
                        selectedCategories.includes(item.id) ? "active" : ""
                    }
                    onClick={() => {
                        if (currentPage != "1") {
                            setCurrentPage("1");
                        }
                        if (selectedCategories === item.id) {
                            setSelectedCategories(
                                null
                            );
                            // handleCategoryDeselect(
                            //     category.id
                            // );
                        } else {
                            setSelectedCategories([
                                item.id
                            ]);
                            // handleCategorySelect(
                            //     category.id
                            // );
                        }
                    }}
                >
                    {item.name}
                </InsideButton>
            ))}
        </>
    );
}

const TabFrame = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    /* padding: 6px; */
    width: max-content;
    background: #fff;
    border-radius: 8px;
`;
const InsideButton = styled.a`
    display: block;
    white-space: nowrap;
    width: max-content;
    font-size: 13px;
    padding: 10px 20px;
    color: #404040;
    cursor: pointer;
    border: 1px solid #e7e7e7;
    border-radius: 59px;
    margin-left: 8px;
    :first-child {
        margin-left: 0;
    }
    &.active {
        color: #ffffff;
        background: #917afd;
        border-radius: 59px;
        border: 1px solid #917afd;
    }
`;
