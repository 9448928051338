import React, { useState, useRef, useEffect, forwardRef } from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";
import { findDOMNode } from "react-dom";
import nextArrow from "../../../../assets/icons/arrow-right.svg";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";

import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import FastRewindIcon from "@material-ui/icons/FastRewind";
import FastForwardIcon from "@material-ui/icons/FastForward";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeDown from "@material-ui/icons/VolumeDown";
import VolumeMute from "@material-ui/icons/VolumeOff";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import Popover from "@material-ui/core/Popover";
import screenful from "screenfull";

const PrettoSlider = withStyles({
	root: {
		color: "#fff",
		height: 6,
	},
	thumb: {
		height: 18,
		width: 18,
		backgroundColor: "#fff",
		border: "2px solid currentColor",
		marginTop: -7,
		marginLeft: -12,
		"&:focus,&:hover,&$active": {
			boxShadow: "inherit",
		},
	},
	active: {},
	valueLabel: {
		left: "calc(-50%+4px)",
	},
	track: {
		height: 6,
		borderRadius: 4,
	},
	rail: {
		height: 6,
		borderRadius: 4,
	},
	volumeSlider: {
		width: 100,
	},
})(Slider);

function ValueLabelComponent(props) {
	const { children, open, value } = props;
	return (
		<Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
			{children}
		</Tooltip>
	);
}

const format = (seconds) => {
	if (isNaN(seconds)) {
		return `00:00`;
	}
	const date = new Date(seconds * 1000);
	const hh = date.getUTCHours();
	const mm = date.getUTCMinutes();
	const ss = date.getUTCSeconds().toString().padStart(2, "0");
	if (hh) {
		return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
	}
	return `${mm}:${ss}`;
};

export default function ProgramVideo({ data, subjectName }) {
	const [state, setState] = useState({
		playing: true,
		muted: true,
		volume: 0.5,
		playbackRate: 1.0,
		played: 0,
		seeking: false,
	});
	const [timeDisplayFormat, setTimeDisplayFormat] = useState("normal");
	let count = 0;

	// useEffect(() => {
	//     if (screenfull.isEnabled) {
	//         screenfull.request(element);
	//         document.exitFullscreen();
	//     }
	//     if (screenfull.isFullscreen) {
	//         screenfull.request(element);
	//     }
	// }, []);
	// if (screenfull.isEnabled) {
	//     screenfull.on("error", (event) => {
	//     });
	// }

	const { playing, muted, volume, playbackRate, played, seeking } = state;

	const handlePlayPause = () => {
		setState({ ...state, playing: !state.playing });
	};
	// For Popover
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const id = open ? "playbackrate-popover" : undefined;

	const handlePopover = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const playerRef = useRef(null);
	const playerContainerRef = useRef(null);
	const controlsRef = useRef(null);

	const handleRewind = () => {
		playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10);
	};
	const handleFastForward = () => {
		playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10);
	};
	const handleMute = () => {
		setState({ ...state, muted: !state.muted });
	};
	const handleVolumeSeekUp = (e, newValue) => {
		setState({
			...state,
			seeking: false,
			volume: parseFloat(newValue / 100),
		});
	};
	const handleVolumeChange = (e, newValue) => {
		setState({
			...state,
			volume: parseFloat(newValue / 100),
			muted: newValue === 0 ? true : false,
		});
	};
	const handlePlaybackRate = (rate) => {
		setState({ ...state, playbackRate: rate });
	};

	const toggleFullScreen = () => {
		screenful.toggle(playerContainerRef.current);
	};

	const handleProgress = (changeState) => {
		if (count > 3) {
			controlsRef.current.style.visibility = "hidden";
			count = 0;
		}
		if (controlsRef.current.style.visibility == "visible") {
			count += 1;
		}
		if (!state.seeking) {
			setState({ ...state, ...changeState });
		}
	};
	const handleSeekChange = (e, newValue) => {
		setState({ ...state, played: parseFloat(newValue / 100) });
	};
	const handleSeekMouseDown = (e) => {
		setState({ ...state, seeking: true });
	};

	const handleSeekMouseUp = (e, newValue) => {
		setState({ ...state, seeking: false });
		playerRef.current.seekTo(newValue / 100, "fraction");
	};
	const duration =
		playerRef && playerRef.current
			? playerRef.current.getDuration()
			: "00:00";

	const currentTime =
		playerRef && playerRef.current
			? playerRef.current.getCurrentTime()
			: "00:00";

	const elapsedTime =
		timeDisplayFormat == "normal"
			? format(currentTime)
			: `-${format(duration - currentTime)}`;

	const totalDuration = format(duration);
	const handleTimeDisplayFormat = () => {
		setTimeDisplayFormat(
			timeDisplayFormat == "normal" ? "remaining" : "normal"
		);
	};

	const handleMouseMove = () => {
		controlsRef.current.style.visibility = "visible";
		count = 0;
	};

	const hanldeMouseLeave = () => {
		controlsRef.current.style.visibility = "hidden";
		count = 0;
		// handleDiv();
	};

	const handleOverlay = () => {
		setTimeout(() => {
			controlsRef.current.style.visibility = "hidden";
			count = 0;
		}, 5000);
		// handleDiv();
	};
	const toggleOverlay = () => {
		if (controlsRef.current.style.visibility === "visible") {
			controlsRef.current.style.visibility = "hidden";
			count = 0;
		} else {
			controlsRef.current.style.visibility = "visible";
			count = 0;
		}
	};

	// const playerHeight = screenful.isFullscreen ? "100%" : "auto !important";

	return (
		<LeftContentsContainer>
			{/* <Container maxWidth="md"> */}
			<PlayerContainer
				ref={playerContainerRef}
				onMouseMove={handleMouseMove}
				onMouseLeave={hanldeMouseLeave}
			>
				<ReactPlayer
					id="topic-video-player"
					className="vid-player"
					ref={playerRef}
					// url={videoSrc}
					// url={data.content.video_link}
					// url="https://vimeo.com/219903852" whitespace video
					url="https://vimeo.com/186191787"
					width="100%"
					// height={playerHeight}
					// height={screenful.isFullscreen ? "100%" : null}
					playing={playing}
					muted={muted}
					volume={volume}
					playbackRate={playbackRate}
					onProgress={handleProgress}
					config={{
						youtube: {
							playerVars: {
								autoplay: 0,
								// controls: 1,
								rel: 0,
								controls: 0,
								modestbranding: 1,
								loop: 1,
								fs: 0,
								related: false,
								showinfo: 0,
								nologo: 1,
								// end: alert("Completed"),

								// iv_load_policy: 3,
							},
						},
					}}
					onPlay={(e) => {
						handleOverlay();
					}}
					onPause={() => {
						handleOverlay();
					}}
				/>
				<ControlsWrapper
					ref={controlsRef}
					onClick={() => {
						toggleOverlay();
					}}
				>
					<TopPart>
						<VideoTitle>{data.content.title}</VideoTitle>
						<VolumePart>
							<IconButton onClick={handleMute} className="icons">
								{muted ? (
									<VolumeMute fontSize="inherit" />
								) : (
									<VolumeUpIcon fontSize="inherit" />
								)}
							</IconButton>
							<PrettoSlider
								min={0}
								max={100}
								value={volume * 100}
								ValueLabelComponent={ValueLabelComponent}
								onChange={handleVolumeChange}
								onChangeCommitted={handleVolumeSeekUp}
							/>
						</VolumePart>
					</TopPart>
					<MiddlePart>
						<IconButton
							onClick={handleRewind}
							className="icons"
							aria-label="reqind"
						>
							<FastRewindIcon fontSize="inherit" />
						</IconButton>
						<IconButton
							onClick={handlePlayPause}
							className="icons"
							aria-label="reqind"
						>
							{playing ? (
								<PauseIcon fontSize="inherit" />
							) : (
								<PlayArrowIcon fontSize="inherit" />
							)}
						</IconButton>
						<IconButton
							onClick={handleFastForward}
							className="icons"
							aria-label="reqind"
						>
							<FastForwardIcon fontSize="inherit" />
						</IconButton>
					</MiddlePart>
					<BottomPart>
						<PrettoSlider
							min={0}
							max={100}
							value={played * 100}
							ValueLabelComponent={(props) => (
								<ValueLabelComponent
									{...props}
									value={elapsedTime}
								/>
							)}
							onChange={handleSeekChange}
							onMouseDown={handleSeekMouseDown}
							onChangeCommitted={handleSeekMouseUp}
						/>
						<>
							<Typography onClick={handleTimeDisplayFormat}>
								{elapsedTime}/{totalDuration}
							</Typography>
							<Button onClick={handlePopover} variant="text">
								<Typography>{playbackRate}X</Typography>
							</Button>
							<Popover
								id={id}
								open={open}
								anchorEl={anchorEl}
								onClose={handleClose}
								anchorOrigin={{
									vertical: "top",
									horizontal: "center",
								}}
								transformOrigin={{
									vertical: "bottom",
									horizontal: "center",
								}}
							>
								<Grid container direction="column-reverse">
									{[0.5, 1, 1.5, 2].map((rate) => (
										<Button
											variant="text"
											key={rate}
											onClick={() =>
												handlePlaybackRate(rate)
											}
										>
											<Typography
												// sx={{ p: 2 }}
												color={
													rate === playbackRate
														? "secondary"
														: "inherit"
												}
											>
												{rate}
											</Typography>
										</Button>
									))}
								</Grid>
							</Popover>
							<IconButton
								onClick={toggleFullScreen}
								className="icons"
								aria-label="reqind"
							>
								<FullscreenIcon fontSize="inherit" />
							</IconButton>
						</>
					</BottomPart>
				</ControlsWrapper>
			</PlayerContainer>
			{/* </Container> */}
			<DescriptionContainer>
				<TopSection>
					<TopUp>
						<TitleText>{data.content.title}</TitleText>
					</TopUp>
					<TopDown>
						<CourseText>Course: {subjectName}</CourseText>
						<NextTopicButton>
							<NextTopicText>Next Topic</NextTopicText>
							<NextIcon src={nextArrow} />
						</NextTopicButton>
					</TopDown>
				</TopSection>
				<AboutSubjectText>Description</AboutSubjectText>
				<AboutSubjectDetailedContainer>
					<AboutSectionDetailedText>
						{data.content.description}
					</AboutSectionDetailedText>
				</AboutSubjectDetailedContainer>
			</DescriptionContainer>
		</LeftContentsContainer>
	);
}

const LeftContentsContainer = styled.div`
	padding-right: 5px;
`;
const PlayerContainer = styled.div`
	width: 100%;
	background-color: #fff;
	position: relative;
	/* border-radius: 10px; */
	margin-bottom: 25px;
	.vid-player {
		height: auto !important;
		aspect-ratio: 16/9;
	}
`;
const ControlsWrapper = styled.p`
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.4);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	z-index: 1;
	/* pointer-events: none; */
	transition: 0.5s;
	.icons {
		color: #fff;
		font-size: 1.7rem;
	}
`;
const TopPart = styled.div`
	padding: 15px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const VolumePart = styled.div`
	width: 120px;
	display: flex;
	align-items: center;
`;

const VideoTitle = styled.h5`
	font-size: 1.3rem;
	font-weight: 500;
	text-transform: capitalize;
	color: #fff;
`;
const MiddlePart = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #fff;
	padding: 0px 30%;
`;
const BottomPart = styled.div`
	padding: 0px 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const DescriptionContainer = styled.div``;
const TopSection = styled.div`
	margin-bottom: 15px;
`;
const TopUp = styled.div`
	margin-bottom: 5px;
`;
const TitleText = styled.h5`
	font-size: 1.3rem;
	font-weight: 500;
	text-transform: capitalize;
	color: #000;
`;
const CourseText = styled.p`
	color: #c1c1c1;
	text-transform: capitalize;
	font-size: 1rem;
`;
const TopDown = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
`;
const NextTopicButton = styled.button`
	background: #15bf81;
	display: inline-flex;
	align-items: center;
	border-radius: 6px;
	padding: 6px 14px;
	cursor: pointer;
`;
const NextTopicText = styled.p`
	margin-right: 5px;
	font-size: 0.9rem;
`;
const NextIcon = styled.img`
	width: 20px;
	object-fit: cover;
`;
const AboutSubjectText = styled.div`
	font-size: 1.1rem;
	font-weight: 500;
	color: #404040;
	text-transform: capitalize;
	margin-bottom: 15px;
`;
const AboutSubjectDetailedContainer = styled.div`
	@media (max-width: 1500px) {
		width: 98%;
	}
`;
const AboutSectionDetailedText = styled.p`
	color: #9d9d9d;
	font-size: 0.9rem;
	/* width: 99%; */
`;
