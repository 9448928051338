import React from "react";
import styled from "styled-components";
import ProgressBar from "./../../includes/ProgressBar";
import CoursePic from "../../../assets/images/course-image.png";
import Option from "../../../assets/images/menu.png";

export default function DashboardActivityCard() {
    return (
        <DashboardActivityItemBox>
            <LeftPart>
                <ActivityItemImage src={CoursePic} alt="Images" />
            </LeftPart>
            <RightPart>
                <RightTop>
                    <SubAndTopicText>Mathemathics Topic 3</SubAndTopicText>
                    <CourseNameText>Ui/Ux Designing Course</CourseNameText>
                    <RemainingTime>28 / 48 hr remaining</RemainingTime>
                </RightTop>
                <ProgressContainer>
                    <ProgressLeft>
                        <ProgressBar
                            current="30"
                            total="100"
                            top="not-required"
                        />
                    </ProgressLeft>
                    <ProgressText>30%</ProgressText>
                </ProgressContainer>
                <OptionIconBox>
                    <OptionIcon src={Option} alt="" />
                </OptionIconBox>
            </RightPart>
        </DashboardActivityItemBox>
    );
}

const DashboardActivityItemBox = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    background: #fff;
    box-shadow: 0px 7px 74px rgba(200, 200, 200, 0.25);
    border-radius: 12px;
    padding: 15px 20px;
    margin-bottom: 10px;
    &:last-child {
        margin-bottom: 0;
    }
    @media (max-width: 980px) {
        padding: 13px 17px;
    }
`;
const LeftPart = styled.div`
    width: 110px;
    @media (max-width: 1180px) {
        width: 100px;
    }
    @media (max-width: 980px) {
        width: 90px;
    }
`;
const ActivityItemImage = styled.img`
    display: block;
    width: 100%;
    height: 110px;
    object-fit: cover;
    border-radius: 6px;
    @media (max-width: 980px) {
        height: 100px;
    }
`;
const RightPart = styled.div`
    width: calc(100% - 130px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
    position: relative;
    @media (max-width: 1180px) {
        width: calc(100% - 110px);
    }
    @media (max-width: 980px) {
        width: calc(100% - 105px);
    }
`;
const RightTop = styled.div`
    /* min-height: 50px; */
`;
const SubAndTopicText = styled.h6`
    color: #917afd;
    font-size: 0.75rem;
    margin-bottom: 12px;
    @media (max-width: 980px) {
        /* font-size: 0.7rem; */
    }
`;
const CourseNameText = styled.h5`
    color: #010101;
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 6px;
    @media (max-width: 980px) {
        font-size: 1rem;
    }
`;
const RemainingTime = styled.p`
    font-size: 0.8rem;
    color: #9d9d9d;
    @media (max-width: 980px) {
        font-size: 0.75rem;
    }
`;
const ProgressContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const ProgressLeft = styled.div`
    /* width: 92%; */
    width: 95%;
    @media (max-width: 1280px) {
        width: 93%;
    }
    @media (max-width: 1180px) {
        width: 90%;
    }
    @media (max-width: 980px) {
        width: 80%;
    }
`;
const ProgressText = styled.p`
    /* width: 2%; */
    color: #000;
    font-size: 0.8rem;
    color: #9d9d9d;
    /* margin-left: 2px; */
    width: 4%;
    text-align: end;
    @media (max-width: 1280px) {
        width: 5%;
    }
    @media (max-width: 1180px) {
        width: 8%;
    }
    @media (max-width: 980px) {
        width: 15%;
    }
`;
const OptionIconBox = styled.div`
    position: absolute;
    right: 0%;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;
const OptionIcon = styled.img`
    display: block;
    width: 100%;
`;
