import React, { useRef, useEffect } from "react";
import styled from "styled-components";

import ReactPlayer from "react-player";
import CloseIcon from "../../assets/icons/close.png";
import Loader from "./loader/Loader";

// mui

export default function VideoModal({
    isVideoModal,
    setIsVideoModal,
    handleOpenVideoModal,
    handleCloseVideoModal,
    completedVideoLink,
    setIsVideoLoading,
    isVideoLoading,
}) {
    return (
        <MediaContainer>
            {isVideoLoading && <Loader />}
            <CloseIconDiv>
                <Close src={CloseIcon} alt="icon" />
            </CloseIconDiv>

            <ReactPlayer
                style={{ display: isVideoLoading ? "none" : "block" }}
                className="vid-player"
                // url="https://vimeo.com/186191787"
                url={completedVideoLink}
                controls={true}
                width="100%"
                onReady={() => {
                    setIsVideoLoading(false);
                }}
                config={{
                    youtube: {
                        playerVars: {
                            autoplay: 1,
                            // controls: 1,
                            rel: 0,
                            // controls: 0,
                            modestbranding: 1,
                            autoplay: 1,
                            loop: 1,
                            // end: alert("Completed"),

                            // iv_load_policy: 3,
                        },
                    },
                    vimeo: {
                        playerVars: {
                            controls: false,
                        },
                    },
                }}
            />
        </MediaContainer>
    );
}
const CloseIconDiv = styled.div`
    position: absolute;
    background: #fff;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    top: -10px;
    right: -10px;
    display: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
`;
const MediaContainer = styled.div`
    background: rgba(0, 0, 0, 0.4);
    width: 980px;
    position: relative;
    margin: 0 auto;
    border-radius: 24px;
    .vid-player {
        height: auto !important;
        aspect-ratio: 16/9 !important;
        border-radius: 24px;

        /* border-radius: 10px; */
    }
    .player,
    .player *,
    .player ::after,
    .player ::before {
        border-radius: 24px !important;
    }
    @media (max-width: 1380px) {
        width: 780px;
    }
    &:hover {
        ${CloseIconDiv} {
            display: flex;
        }
    }
    border-radius: 24px;
`;

const Close = styled.img`
    width: 50%;
    height: 50%;
    display: block;
`;
