import React from "react";
import styled from "styled-components";

export default function CRFeedback({ data }) {
    return (
        <CardContainer>
            <LivetitleSection>
                <Livetitle>Feedbacks</Livetitle>
            </LivetitleSection>
        </CardContainer>
    );
}
const CardContainer = styled.div``;
const LivetitleSection = styled.div`
    margin-bottom: 20px;
`;
const Livetitle = styled.h4`
    color: #010101;
    font-weight: 500;
    font-size: 22px;
    /* line-height: 29px; */
`;
