import React, { useState, useEffect, useContext } from "react";
import { baseConfig } from "./../../../axiosConfig";
import { Context } from "./../../../contexts/Store";
import styled from "styled-components";
import ProgressBar from "../ProgressBar";
import PracticeMultipleOptions from "./PracticeMultipleOptions";
import PracticeRadioOption from "./PracticeRadioOption";
import Loader from "../../includes/loader/Loader";

export default function PracticeModal({
    setIsWarningModal,
    data,
    isPracticeLoading,
    setIsSuccessModal,
    getLessonPractice,
}) {
    const { state } = useContext(Context);

    const [questionNumber, setQuestionNumber] = useState(1);
    const [multipleOptions, setMultipleOptions] = useState([
        {
            id: 1,
            option: "A. Around 1500",
        },
        {
            id: 2,
            option: "B. Around 1800",
        },
        {
            id: 3,
            option: "C. Around 1600",
        },
        {
            id: 4,
            option: "D. None of these",
        },
    ]);
    const [practiceType, setPracticeType] = useState("");
    //Single Choice Question
    const [selectedRadioButton, setSelectedRadioButton] = useState("");
    const [questionId, setQuestionId] = useState("");
    const [previousAnswers, setPreviousAnswers] = useState({});

    // Multiple Questions
    const [selectedMultipleButton, setSelectedMultipleButton] = useState([]);
    const toggleButton = (data) => {
        selectedMultipleButton?.find((element) => element === data)
            ? setSelectedMultipleButton(
                  selectedMultipleButton?.filter((elem) => elem !== data)
              )
            : setSelectedMultipleButton([...selectedMultipleButton, data]);
    };

    const handleSubmitAnswer = (value) => {
        let accessToken = state.user_details.access_token;
        let input = {
            // question_id: questionId,
            // descriptive_answer: description,
            // single_answer: selectedRadioButton ? selectedRadioButton : null,
            // multi_answers: selectedMultipleButton
            // 	? selectedMultipleButton
            // 	: null,
            question: questionId,
            options:
                practiceType === "single_choice"
                    ? [selectedRadioButton]
                    : selectedMultipleButton,
        };
        baseConfig
            .post(`students/practice-submit/`, input, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                const { StatusCode, reference_data } = response.data;
                if (StatusCode === 6000) {
                    if (value === "lastQuestion") {
                        setIsSuccessModal(true);
                    } else if (value === "prevoiusQuestion") {
                        setQuestionNumber(questionNumber - 1);
                    } else {
                        setQuestionNumber(questionNumber + 1);
                        setSelectedMultipleButton([]);
                        setSelectedRadioButton("");
                        // setDescription("");
                    }
                } else {
                }
            })
            .catch((error) => {});
    };

    // useEffect(() => {
    //     getLessonPractice();
    // }, []);

    return (
        <BackContainer>
            <Overlay
                onClick={() => {
                    setIsWarningModal(true);
                }}
            ></Overlay>

            <Modal>
                {!isPracticeLoading ? (
                    <>
                        <TopLine>
                            <QuestionNo>
                                Question: {questionNumber}/{data?.length}{" "}
                            </QuestionNo>
                            <PracticeButton>Practice</PracticeButton>
                        </TopLine>
                        <ProgressBarContainer>
                            <ProgressBar
                                top="not-required"
                                current={questionNumber}
                                total={data?.length}
                            />
                        </ProgressBarContainer>
                        {data?.map((practice, index) => (
                            <>
                                {questionNumber === practice.question_no && (
                                    <QuestionAnswerWrapper>
                                        {/* {practice.type &&
                                    setPracticeType(practice.type)} */}
                                        <QuestionText>
                                            <QNo>{practice.question_no}.</QNo>
                                            {practice.question}
                                        </QuestionText>
                                        <AnswersContainer>
                                            {practice.type ===
                                            "single_choice" ? (
                                                <PracticeRadioOption
                                                    options={practice}
                                                    selectedOption={
                                                        selectedRadioButton
                                                    }
                                                    setSelectedOption={
                                                        setSelectedRadioButton
                                                    }
                                                    setQuestionId={
                                                        setQuestionId
                                                    }
                                                    previousAnswers={
                                                        previousAnswers
                                                    }
                                                    setPracticeType={
                                                        setPracticeType
                                                    }
                                                />
                                            ) : (
                                                <PracticeMultipleOptions
                                                    options={practice}
                                                    selectedOption={
                                                        selectedMultipleButton
                                                    }
                                                    setSelectedOption={
                                                        setSelectedMultipleButton
                                                    }
                                                    toggleButton={toggleButton}
                                                    setQuestionId={
                                                        setQuestionId
                                                    }
                                                    previousAnswers={
                                                        previousAnswers
                                                    }
                                                    setPracticeType={
                                                        setPracticeType
                                                    }
                                                />
                                            )}
                                        </AnswersContainer>
                                    </QuestionAnswerWrapper>
                                )}
                            </>
                        ))}
                        <ButtonContainer>
                            {/* {questionNumber > 1 && (
                                <PreviousButtonDiv
                                    onClick={() => {
                                        // setQuestionNumber(
                                        // 	questionNumber - 1
                                        // );
                                        if (
                                            selectedMultipleButton?.length >
                                                0 ||
                                            selectedRadioButton !== ""
                                        ) {
                                            handleSubmitAnswer(
                                                "prevoiusQuestion"
                                            );
                                        } else {
                                            setQuestionNumber(
                                                questionNumber - 1
                                            );
                                        }
                                    }}
                                >
                                    <Previous
                                        src={
                                            require("./../../../assets/icons/left-arrow.svg")
                                                .default
                                        }
                                    />
                                    <PreviousButtonText>
                                        Previous
                                    </PreviousButtonText>
                                </PreviousButtonDiv>
                            )} */}

                            {questionNumber !== data.length && (
                                <NextButtonDiv
                                    onClick={() => {
                                        // if (
                                        //     selectedMultipleButton?.length >
                                        //         0 ||
                                        //     selectedRadioButton !== ""
                                        // ) {
                                        //     handleSubmitAnswer("", data.type);
                                        // } else {
                                        //     setQuestionNumber(
                                        //         questionNumber + 1
                                        //     );
                                        // }
                                        if (
                                            selectedMultipleButton?.length ===
                                                0 &&
                                            selectedRadioButton === ""
                                        ) {
                                            alert("Select at least one option");
                                        } else {
                                            // handleSubmitAnswer("lastQuestion");
                                            handleSubmitAnswer("", data.type);
                                        }
                                    }}
                                >
                                    <NextButtonText>Next</NextButtonText>
                                    <Next
                                        src={
                                            require("./../../../assets/icons/right-arrow.svg")
                                                .default
                                        }
                                    />
                                </NextButtonDiv>
                            )}

                            {data.length === questionNumber && (
                                <NextButtonDiv
                                    onClick={() => {
                                        // setSuccessModal(true);
                                        if (
                                            selectedMultipleButton?.length ===
                                                0 &&
                                            selectedRadioButton === ""
                                        ) {
                                            alert("Select at least one option");
                                        } else {
                                            handleSubmitAnswer("lastQuestion");
                                        }
                                    }}
                                >
                                    <NextButtonText>Submit</NextButtonText>
                                    <Next
                                        src={
                                            require("./../../../assets/icons/submit.svg")
                                                .default
                                        }
                                    />
                                </NextButtonDiv>
                            )}

                            {/* {allDetails.subject_data.subject_data.total_questions ===
                        questionNumber && (
                        <NextButtonDiv
                            onClick={() => {
                                handleSubmitAnswer("lastQuestion");
                            }}
                        >
                            <NextButtonText>Submit</NextButtonText>
                            <Next
                                src={
                                    require("./../../../assets/icons/submit.svg")
                                        .default
                                }
                            />
                        </NextButtonDiv>
                    )} */}
                        </ButtonContainer>
                    </>
                ) : (
                    <Loader />
                )}
            </Modal>
        </BackContainer>
    );
}

const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
    background: rgba(0, 0, 0, 0.3);
    // backdrop-filter: blur(2px);
`;
const Overlay = styled.div`
    position: fixed;
    left: 0;
    top: 0px;
    width: 100%;
    cursor: pointer;
    height: 100vh;
`;
const Modal = styled.div`
    padding: 40px;
    margin: 0 auto;
    background: #fff;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 8px;
    transition: 0.5s;
    z-index: 101;
    max-height: 70vh;
    width: 980px;
    overflow-y: scroll;
    /* @media all and (max-width: 1500px) {
        width: 1180px;
    } */
    /* @media all and (max-width: 1300px) {
        width: 1080px;
    } */
    /* @media all and (max-width: 1200px) {
        width: calc(100% - 60px);
        right: 10%;
        left: 60px;
    } */
    /* @media all and (max-width: 1100px) {
        width: 900px;
    } */
`;
const TopLine = styled.div`
    display: flex;
    justify-content: space-between;
`;
const QuestionNo = styled.span`
    display: inline-block;
    color: #010101;
    font-size: 0.95rem;
    opacity: 0.4;
`;
const PracticeButton = styled.button`
    background: linear-gradient(91.69deg, #ea1752 -3.39%, #522f91 99.66%);
    border-radius: 68px;
    padding: 8px 18px;
    color: #fff;
    font-size: 0.9rem;
`;
const ProgressBarContainer = styled.div`
    margin-top: 20px;
`;
const QuestionAnswerWrapper = styled.div`
    margin-top: 40px;
`;
const QuestionText = styled.p`
    color: #000;
    font-size: 1rem;
`;
const QNo = styled.span`
    color: #000;
    font-size: 1rem;
    margin-right: 8px;
`;
const AnswersContainer = styled.div``;
// const TopLine =styled.div``;
// const TopLine =styled.div``;
const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;
const PreviousButtonDiv = styled.div`
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    padding: 0 15px;
    border-radius: 8px;
    margin-right: 30px;
    border: 1px solid #9178f8;
    cursor: pointer;
`;
const PreviousButtonText = styled.p`
    color: #9178f8;
    font-size: 15px;
    margin-left: 15px;
    margin-bottom: -3px;
`;
const Previous = styled.img`
    color: #fff;
    font-size: 15px;
`;

const NextButtonDiv = styled.div`
    background-color: #9178f8;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    padding: 0 15px;
    border-radius: 8px;
    border: 1px solid #9178f8;
    margin-right: 30px;
    cursor: pointer;
`;
const NextButtonText = styled.p`
    color: #fff;
    font-size: 15px;
    margin-right: 15px;
`;
const Next = styled.img`
    color: #fff;
    font-size: 15px;
`;
