import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { baseConfig } from "../../../axiosConfig";
import { Context } from "../../../contexts/Store";
import { Tooltip, Typography } from "@mui/material";

import Loader from "../loader/Loader";
import EmptyPage from "../emptyPages/EmptyPage";
import emptyPic from "../../../assets/icons/live-class-empty-icon.svg";
import download from "../../../assets/icons/download_blue.svg";
import docIcon from "../../../assets/icons/doc-icon.svg";
import excelIcon from "../../../assets/icons/module-file-icon-xls.png";
import imageIcon from "../../../assets/icons/module-file-icon-image.png";
import pdfIcon from "../../../assets/icons/pdf-icon.svg";
import videoIcon from "../../../assets/icons/module-file-icon-video.png";
import pptIcon from "../../../assets/icons/ppt-icon.svg";
import otherIcon from "../../../assets/icons/oth.png";
import viewIcon from "../../../assets/icons/eyeIcon_white.svg";

export default function CRModuleFileCard({ fileData }) {
	const [fileType, setFileType] = useState("");
	useEffect(() => {
		// Set the file type when the component mounts or when fileData changes
		setFileType(getFileExtension(fileData.file_name));
	}, [fileData]);

	const getFileExtension = (fileName) => {
		const extension = fileName.split(".").pop().toLowerCase();

		if (["pdf"].includes(extension)) {
			return "pdf";
		} else if (["jpg", "jpeg", "png", "gif", "svg"].includes(extension)) {
			return "image";
		} else if (["mkv", "avi", "mp4"].includes(extension)) {
			return "video";
		} else if (["ppt", "pptx"].includes(extension)) {
			return "ppt";
		} else if (["xls", "xlsx"].includes(extension)) {
			return "excel";
		} else if (["doc", "docx"].includes(extension)) {
			return "document";
		} else {
			return "other";
		}
	};
	return (
		<FileItemCards>
			<FileTop>
				<FileIcon
					src={
						fileType == "pdf"
							? pdfIcon
							: fileType == "image"
							? imageIcon
							: fileType == "video"
							? videoIcon
							: fileType == "ppt"
							? pptIcon
							: fileType == "excel"
							? excelIcon
							: fileType == "document"
							? otherIcon
							: otherIcon
					}
					alt="icon"
				/>
				{fileData?.file_name.length > 22 ? (
					<Tooltip
						title={
							<Typography
								style={{
									fontSize: "13px",
									textTransform: "capitalize",
								}}>
								{fileData.file_name.replace(/_/g, " ")}
							</Typography>
						}
						placement="bottom-start">
						<FileName>{`${fileData?.file_name.slice(0, 22).replace(/_/g, " ")}...`}</FileName>
					</Tooltip>
				) : (
					<FileName>{fileData?.file_name.slice(0, 22).replace(/_/g, " ")}</FileName>
				)}
			</FileTop>
			<FileBottom>
				<AnchorWrapper>
					<DownloadFileButton href={fileData.file} target="_blank" download>
						<Icon src={download} alt="Icon" />
						Download
					</DownloadFileButton>

					{(fileType === "pdf" || fileType === "image" || fileType === "video" || fileType === "video") && (
						<ViewButton href={fileData.file} target="_blank">
							<FileActionIcon src={viewIcon} alt="view" />
							View
						</ViewButton>
					)}
					{/* <DownloadFileButton
						href={fileData.file}
						target="_blank"
						download={fileType === "pdf" || fileType === "image" ? fileData.file_name : undefined}>
						<Icon src={dowload} alt="Icon" />
						Download
					</DownloadFileButton> */}
					{/* new added */}
					{/* {fileType === "pdf" || fileType === "image" || fileType === "video" ? (
						<ViewButton href={fileData.file} target="_blank">
							<FileActionIcon src={viewIcon} alt="view" />
							View
						</ViewButton>
					) : (
						<DownloadFileButton href={fileData.file} target="_blank" download={fileData.file_name}>
							<Icon src={dowload} alt="Icon" />
							Download
						</DownloadFileButton>
					)} */}
				</AnchorWrapper>
			</FileBottom>
		</FileItemCards>
	);
}
const FileItemCards = styled.div`
	display: flex;
	padding: 16px;
	/* align-items: center; */
	justify-content: space-between;
	flex-direction: column;
	gap: 16px;
	border-radius: 8px;
	border: 1px solid #c1c1c1;
	background: #fff;
	transition: 0.3s;
	width: 280px; /* Set a fixed width */
	&:hover {
		box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.16);
	}
`;
const FileTop = styled.div`
	display: flex;
	align-items: center;
	gap: 12px;
	padding-bottom: 18px;
	border-bottom: 1px solid #e5e5e5;
`;
const FileIcon = styled.img`
	width: 40px;
	object-fit: cover;
	display: block;
`;
const FileName = styled.h6`
	font-size: 16px;
	font-family: "inter-medium";
	color: #404040;
	text-transform: capitalize;
`;
const FileBottom = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
	/* justify-content: center; */
`;
const AnchorWrapper = styled.div`
	display: flex;
	/* justify-content: center; */
	align-items: center;
	gap: 25px;
`;
const DownloadFileButton = styled.a`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	/* width: 90px; */
	height: 32px;
	box-sizing: border-box;
	border: 1px solid #917afd;
	color: #917afd;
	border-radius: 4px;
	padding: 0 22px;
	font-size: 13px;
	cursor: pointer;
	font-family: "inter-medium";
`;
const Icon = styled.img`
	width: 15px;
	object-fit: contain;
	/* margin-right: 5px; */
	display: block;
`;
const ViewButton = styled.a`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	/* width: 90px; */
	height: 32px;
	box-sizing: border-box;
	border: 1px solid #917afd;
	color: #fff;
	background: #917afd;
	border-radius: 4px;
	padding: 0 22px;
	font-size: 13px;
	cursor: pointer;
	font-family: "inter-medium";
`;
const FileActionIcon = styled.img`
	width: 15px;
	object-fit: cover;
	/* margin-right: 5px; */
	display: block;
`;
