import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import PracticeModal from "../../includes/practicemodals/PracticeModal";

// mui
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

// images
import mainImage from "../../../assets/images/practice-initial.svg";

export default function PracticeInitial({
    openInitialModal,
    setOpenInitialModal,
    handleOpenInitialModal,
    handleCloseInitialModal,
    setOpenPracticeModal,
    handleOpenPracticeModal,
    getLessonPractice,
}) {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        // border: "2px solid #000",
        borderRadius: 2,
        boxShadow: 24,
        pt: 5,
        px: 5,
        pb: 5,
    };

    return (
        <div>
            <Modal
                open={openInitialModal}
                onClose={handleCloseInitialModal}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style, width: 500 }}>
                    {/* <h2 id="parent-modal-title">Text in a modal</h2>
                    <p id="parent-modal-description">
                        Duis mollis, est non commodo luctus, nisi erat porttitor
                        ligula.
                    </p>
                    <ChildModal /> */}
                    <Image src={mainImage} alt="" />
                    <Middle>
                        <LeftBox>
                            <Title>Design Elements</Title>
                            <Questions>Total Questions: 4</Questions>
                        </LeftBox>
                        <PracticeButton>Practice</PracticeButton>
                    </Middle>
                    <Description>
                        Complete the practice in the topic UI Design Elements.
                        interdum. Praesent vestibulum augue cursus morbi feugiat
                        id quam aliquam pharetra. Orci, tincidunt lobortis
                        congue neque feugiat. Sapien,{" "}
                    </Description>
                    {/* <StartPracticeButton></StartPracticeButton> */}
                    <Button
                        className="start-button"
                        variant="contained"
                        endIcon={<ArrowRightAltIcon />}
                        color="secondary"
                        // onClick={() => setOpenInitialModal(false)}
                        onClick={() => {
                            // setOpenPracticeModal(true);
                            handleCloseInitialModal();
                            handleOpenPracticeModal();
                            getLessonPractice();
                        }}
                    >
                        Start Practice
                    </Button>
                </Box>
            </Modal>
        </div>
    );
}

const Image = styled.img`
    width: 350px;
    display: block;
    object-fit: cover;
    margin: 0 auto;
`;
const Middle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
`;
const LeftBox = styled.div``;
const Title = styled.h6`
    font-size: 1.1rem;
`;
const Questions = styled.span`
    display: inline-block;
    margin-top: 5px;
    color: #9d9d9d;
    font-size: 0.85rem;
`;
const PracticeButton = styled.button`
    background: linear-gradient(91.69deg, #ea1752 -3.39%, #522f91 99.66%);
    border-radius: 68px;
    padding: 8px 18px;
    color: #fff;
    font-size: 0.9rem;
`;
const Description = styled.p`
    color: #404040;
    font-size: 0.9rem;
    margin: 12px 0px;
`;
// const Image = styled.img``;
