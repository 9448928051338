import React from "react";
import paid from "../../../assets/icons/status-paid-icon.svg";
import due from "../../../assets/icons/status-lock-icon.svg";
import pending from "../../../assets/icons/status-pending-icon.svg";
import hold from "../../../assets/icons/status-hold.svg";

import styled from "styled-components";

export default function StatusButton({ status, text }) {
	return (
		<>
			<StatusDiv
				className={
					status === "Paid"
						? "paid"
						: status === "On Due"
						? "due"
						: status === "On Hold"
						? "hold"
						: status === "Pending"
						? "pending"
						: ""
				}
			>
				<SIcon
					src={
						status === "Paid"
							? paid
							: status === "On Due"
							? due
							: status === "On Hold"
							? hold
							: status === "Pending"
							? pending
							: null
					}
				/>
				<StatusText
					className={
						status === "Paid"
							? "paid"
							: status === "On Due"
							? "due"
							: status === "On Hold"
							? "hold"
							: status === "Pending"
							? "pending"
							: ""
					}
				>
					{text}
				</StatusText>
			</StatusDiv>
		</>
	);
}
const StatusDiv = styled.div`
	width: max-content;
	height: 30px;
	display: flex;
	align-items: center;
	/* justify-content: center; */
	padding: 12px 12px;
	border-radius: 50px;
	&.paid {
		background: #d7ebda;
		color: #399a47;
	}
	&.due {
		background: rgba(255, 0, 0, 0.2);
		color: #ff0000;
	}
	&.hold {
		color: #ffa143;
		background: #ffecd9;
	}
	&.pending {
		background: #ffecd9;
		color: #ffa143;
	}
	@media all and (max-width: 1280px) {
		width: 86%;
	}
`;
const SIcon = styled.img`
	display: inline-block;
	width: 12px;
	height: 12px;
	object-fit: contain;
`;
const StatusText = styled.span`
	margin-left: 6px;
	font-size: 13px;
	margin-bottom: -2px;
	&.paid {
		color: #399a47;
	}
	&.due {
		color: #ff0000;
	}
	&.hold {
		color: #ffa143;
	}
	&.pending {
		color: #ffa143;
	}
`;
