// import { Table } from "@mui/material";
import React,{useState, useContext} from "react";
import { useEffect } from "react";
import { Context } from "./../../../contexts/Store";
import styled from "styled-components";
import blueTick from "../../../assets/icons/bluetick.svg";
import pendingIcon from "../../../assets/icons/pending.svg";
import returnIcon from "../../../assets/icons/status-due-icon.svg"
import evaluated from "../../../assets/icons/evaluated.svg";
import underEvaluation from "../../../assets/icons/underevaluation.svg";
import Filter from "../../../assets/icons/filter.svg";
import downArrow from "../../../assets/icons/downarrow.svg";
import Search from "../../../assets/icons/search_icon.svg";
import { baseConfig } from "./../../../axiosConfig";
// import Select from 'react-select';

import emptyPic from "../../../assets/icons/live-class-empty-icon.svg";
import EmptyPage from "../../includes/emptyPages/EmptyPage";
import Pagination from "../../includes/Pagination";
import Loader from "../../includes/loader/Loader";

export default function AssignmentStatus() {

    // Search box
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = () => {
        // onSearch(searchTerm);
    }; 


    const [moduleData, setModuleData] = useState([])
    // const [doubledModuleData, setDoubledModuleData] = useState([]);

    const [isLoading, setLoading] = useState(true);
    const { state } = useContext(Context);

    const getModuleData = () => {
        setLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/students/student-assignment-status`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                console.log(response.data, "resp")

                if (StatusCode === 6000) {
                    console.log(data,"hello")
                    setModuleData(data);
                    // setDoubledModuleData([...data, ...data, ...data]);
                    setTimeout(() => {
                        setLoading(false);
                    }, 200);
                } else {
                    setTimeout(() => {
                        setLoading(false);
                        alert("It is taking longer than usual");
                    }, 2000);
                }
            })
            .catch((error) => {});
    };
    useEffect(() => {
        getModuleData();
    }, []);

    return(
        <MainContainer>
            <>
                <SectionTitleContainer>
                    <SectionTitle>Assignments Status</SectionTitle>
                </SectionTitleContainer>
                    
                    
                                
                {!isLoading ? (
                    <>
                        {moduleData.length > 0 ? (
                        <>
                    <TableContainer>
                        <TableDiv>
                            <THead>
                                <TRHead>
                                    <TH>SL No</TH>
                                    <TH>Module Name</TH>
                                    <TH>Module Status</TH>
                                    <TH>Assignment Status</TH>
                                    <TH>Date of Submission</TH>
                                    <TH>Mark</TH>
                                </TRHead>
                            </THead>
                                <TBody>
                                    {moduleData.map((data, index) => ( 
                                        <TRBody key={data.id}>
                                    
                                            <TD>{index+1}</TD>
                                            <TD>{data.name}</TD>
                                            <TD>
                                                <Status>
                                                    {
                                                        data.assignment_data.module_status == 'Assigned' ? 
                                                        (<AssignedStatus>
                                                            <img src={blueTick} alt="assigned"/>
                                                            {data.assignment_data.module_status}
                                                        </AssignedStatus>)
                                                        :
                                                        (<PendingStatus>
                                                            <img src={pendingIcon} alt="pending"/>
                                                            {data.assignment_data.module_status}
                                                        </PendingStatus>)
                                                        
                                                    }
                                                </Status>
                                            </TD>
                                            <TD>
                                                <AssignmentsStatus>
                                                    {
                                                        data.assignment_data.assignment_status == 'Assigned' ?
                                                        (<Assigned>
                                                            <img src={blueTick} alt="assigned"/>
                                                            {data.assignment_data.assignment_status}
                                                        </Assigned>)
                                                        :data.assignment_data.assignment_status == 'Evaluated' ?
                                                        (<Evaluated>
                                                            <img src={evaluated} alt="evaluated"/>
                                                            {data.assignment_data.assignment_status}
                                                        </Evaluated>)
                                                        : data.assignment_data.assignment_status == 'Under Evaluation' ?
                                                        (<UnderEvaluation>
                                                            <img src={underEvaluation} alt="assigned"/>
                                                            {data.assignment_data.assignment_status}
                                                        </UnderEvaluation>)
                                                        : data.assignment_data.assignment_status == 'Submitted' ?
                                                        (<Submitted>
                                                            <img src={blueTick} alt="submitted"/>
                                                            {data.assignment_data.assignment_status}
                                                        </Submitted>)
                                                        : data.assignment_data.assignment_status == "Returned" ?
                                                        (<ReturnStatus>
                                                            <img src={returnIcon} alt="Returned"/>
                                                            {data.assignment_data.assignment_status}
                                                        </ReturnStatus>)
                                                        :data.assignment_data.assignment_status == "Pending" ?
                                                        (<PendingStatus>
                                                            <img src={pendingIcon} alt="pending"/>
                                                            {data.assignment_data.assignment_status}
                                                        </PendingStatus>)
                                                        :""
                                                    }
                                                </AssignmentsStatus>
                                            </TD>
                                            <TD>{data.assignment_data.submitted_date}</TD>
                                            <TD>{data.assignment_data.student_mark}</TD>
                                        </TRBody>
                                    ))}
                                </TBody>
                        </TableDiv>
                        </TableContainer>
                        </>
                        ) : (
                            <EmptyContainer>
                                <EmptyPage
                                    img={emptyPic}
                                    message={
                                        moduleData === "Upcoming"
                                            ? "You have no assignemnets"
                                            : "You have no completed assignments"
                                    }
                                />
                            </EmptyContainer>
                            )
                        }
                    </>
                ) : (
                    <Loader />
                )}
                    
                
                </>
        </MainContainer>

    );
 }
    

const MainContainer = styled.div`
    background: #ffffff;
    padding: 20px 34px;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 10px;
    // overflow-y:scroll;
    height: calc(100vh - 110px);
    overflow:hidden;
`;
const TableContainer = styled.div`
// position:fixed;
// width:75%;

`;
const SectionTitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-top: 20px;
    padding-right: 5px;
`;
const SectionTitle = styled.h3`
    font-size: 22px;
    font-weight: 500;
    color: #000000;
`;
const SectionDetailsContainer = styled.div`
    margin: 20px 0 30px 0;
    display: flex;
    justify-content: space-between;
`;
const SearchContainer = styled.div`
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 10px;
    height: 52px;
    width: 350px;
    cursor: pointer;
    display: flex;
    align-items: center;
    input{
        padding: 10px;
        font-size: 18px;
        cursor: pointer;
    }
`;
const Input = styled.input`
    
`;
const SectionDetailLast = styled.div`
    display: flex;
`;
const MonthContainer = styled.div`
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 10px;
    height: 52px;
    width: 156px;
    padding: 10px 15px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`;
const FilterContainer = styled.div`
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 10px;
    height: 52px;
    width: 114px;
    margin-left: 20px;
    padding: 10px 15px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor:pointer;
`;

const Status = styled.div`
    // border: 1px solid #e5e5e5;
    border:none;
    box-sizing: border-box;
    border-radius: 30px;
    height: 34px;
    // width: 120px;
    font-size: 15px;
    font-weight: bold;
    display: flex;
    align-items: center;
`;
const AssignedStatus = styled(Status)`
    color: #917afe;
    padding: 12px;
    border: 1px solid #e5e5e5;
    img{
        margin-right: 10px;
        }
`;
const PendingStatus = styled(Status)`
    color: #fda851;
    background-color: #fcf6f3;
    border: none;
    padding: 16px;
    img{
        margin-right: 10px;
        }
`;
const ReturnStatus = styled(Status)`
    color: #ce2323;
    background-color: #ffff;
    border: 1px solid #e5e5e5;
    padding: 16px;
    img{
        margin-right: 10px;
        }
`;
const AssignmentsStatus = styled.div`
    // border: 1px solid #e5e5e5;
    border:none;
    box-sizing: border-box;
    border-radius: 30px;
    height: 34px;
    // width: max-content;
    font-size: 15px;
    font-weight: bold;
    display: flex;
    align-items: center;
    img{
        margin-right: 10px;
    }
    
`;
const Assigned = styled(AssignmentsStatus)`
    color: #9781fd;
    padding: 12px;
    border: 1px solid #e5e5e5;
`;
const Evaluated = styled(AssignmentsStatus)`
    color: #e7e7e7;
    background-color: #917afe;
    padding: 12px;`;
const UnderEvaluation = styled(AssignmentsStatus)`
    color: #9781fd;
    padding: 10px;
    border: 1px solid #e5e5e5;
`;
const Submitted = styled(AssignmentsStatus)`
    color: #9781fd;
    background-color: #ece9fe;
    padding: 12px;
`;

const EmptyContainer = styled.div`
    height: calc(100vh - 295px);
    overflow-y: scroll;
    /* ::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 0;
    } */
`;
const Blank = styled.div`
    border: none !important;
`;



const TableDiv = styled.table`
	width: 100%;
    border-collapse: collapse;
    // border-radius: 0.5em;
    // border: 1px solid #f4f4f4;
    // overflow: hidden;
    margin-top: 40px;
    // overflow-y: scroll;
`;
const THead = styled.thead`
	display: block;
	width: 100%;
    background-color: #f3f4f7;
    height: 46px;
    text-align: left;
    width: 100%;
    // position:sticky;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
`;
const TRHead = styled.tr`
	display: flex;
	align-items: center;
	// padding: 15px 14px;
    height: 54px;
    font-size: 18px;
    font-weight:600;
`;
const TH = styled.th`
	text-align: left;
	flex-grow: 2;
	width: 11%;
	:first-child {
		width: 50px;
	}
    vertical-align: middle !important;
    padding-left: 20px !important; 
`;
const TBody = styled.tbody`
	display: block;
	width: 100%;
	overflow-y: scroll;
	height: 65vh;
`;

const TRBody = styled.tr`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
    // to adjust height in row
    height: auto;
    padding: 10px 0; 
    border: 0.5px solid #F4F4F4;
    :last-child{
        border-bottom-right-radius: 1em;
        border-bottom-left-radius: 1em;
    }
    
`;

const TD = styled.td`
	/* border-collapse: collapse; */
	/* white-space: nowrap; */
	/* flex-basis: 100%; */
	/* flex-grow: 2; */
	display: block;
	flex-grow: 2;
	font-size: 17px;
	align-items: center;
	display: flex;
	width: 11%;
    padding-left: 20px;
	&:first-child {
		width: 50px;
	}
`;
