import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import PlayIcon from "../../../assets/icons/play-icon-dashboard-white.svg";
import CourseImage from "../../../assets/images/course-image-dashboard.png";
import ProgressBar from "../ProgressBar";

export default function ContinueCourseCard({ data, noCurrentSection }) {
	let history = useHistory();

	return (
		<Container
			onClick={() => history.push(`/program/subject/${data.id}`)}
			noCurrentSection={noCurrentSection}
		>
			<Left
				background_image={
					data?.image !== null ? data.image : CourseImage
				}
			>
				{/* <Icon src={PlayIcon} alt="" /> */}
			</Left>
			<Right>
				{/* <Heading>
                    {data?.name}: {data?.content.topic_name}
                </Heading> */}
				<Title>{data?.name}</Title>
				<ProgressBar
					current={data.progress}
					total={100}
					top="not-required"
					type="programCard"
				/>
			</Right>
		</Container>
	);
}
const Left = styled.div`
	width: 64px;
	height: 64px;
	background-image: url(${(props) => props.background_image});
	background-size: cover;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 8px;
	overflow: hidden;
	transition: 0.3s;
`;
const Right = styled.div`
	width: calc(100% - 80px);
	transition: 0.3s;
`;
const Container = styled.div`
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px;
	border-radius: 16px;
	border: 1px solid #e5e5e5;
	margin-bottom: 12px;
	transition: 0.3s;
	&:last-child {
		margin-bottom: ${(props) => (props.noCurrentSection ? null : 0)};
	}
	/* &:hover {
        background: rgba(0, 0, 0, 0.01);
        transform: translateX(0.05em);
        border: 1px solid rgba(0, 0, 0, 0.18);
    } */
	&:hover {
		transform: ${(props) =>
			props.noCurrentSection
				? "translateY(-2px) scale(1) translateZ(0)"
				: "translateX(2px) translateY(-0.5px) scale(1) translateZ(0)"};

		/* box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05), 0 2px 2px rgba(0, 0, 0, 0.05); */
		border: 1px solid #917afd;

		box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.15);

		/* background: #f8f4ff; */
	}
`;

const Icon = styled.img`
	width: 29px;
	display: block;
`;

const Heading = styled.p`
	font-weight: 400;
	font-size: 13px;
	line-height: 16px;
	color: #9d9d9d;
	text-transform: capitalize;
	white-space: nowrap;
	overflow: hidden !important;
	text-overflow: ellipsis;
`;
const Title = styled.p`
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	text-transform: capitalize;
	color: #010101;
	margin-bottom: 0.5rem;
	white-space: nowrap;
	overflow: hidden !important;
	text-overflow: ellipsis;
`;
