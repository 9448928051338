import React, { lazy, Suspense, useContext, useEffect, useState } from "react";
import { Switch } from "react-router-dom";
import { Context } from "../../contexts/Store";
import { AuthRoute } from "./../routes/AuthRoute";
import { PrivateRoute } from "../routes/PrivateRoute";
import LoginRouter from "./LoginRouter";
import AppRouter from "./AppRouter";

const LazyLoginRouter = lazy(() => import("./LoginRouter"));
const LazyPrivateRoute = lazy(() => import("./AppRouter"));

export default function MainRouter() {
	const [isLoading, setLoading] = useState(true);
	const { dispatch } = useContext(Context);

	async function fetchUserData() {
		// let promise = new Promise((resolve, reject) => {
		let user_details = localStorage.getItem("user_details");
		if (!user_details) {
			localStorage.setItem("user_details", JSON.stringify(user_details));
			user_details = localStorage.getItem("user_details");
		}
		let user_details_stored = JSON.parse(user_details);
		dispatch({
			type: "UPDATE_USER",
			user_details: user_details_stored,
		});
		setTimeout(() => {
			setLoading(false);
		}, 300);

		// });

		// let result = await promise;
	}

	useEffect(() => {
		fetchUserData();
	}, []);

	// setTimeout(() => {
	// 	// resolve("done!");
	// 	setLoading(false);
	// }, 1000);

	return isLoading ? null : (
		<Suspense fallback={<h1>"Loading"</h1>}>
			<Switch>
				<AuthRoute path="/login/" component={LoginRouter} />
				<PrivateRoute path="/" component={AppRouter} />

				{/* <AuthRoute path="/login/" component={LazyLoginRouter} />
				<PrivateRoute path="/" component={LazyPrivateRoute} /> */}
			</Switch>
		</Suspense>
	);
}
