import React from "react";
import styled from "styled-components";

function ExamResult({ data }) {
    return (
        <div>
            <TableDiv>
                <THead>
                    <TRHead>
                        <TH>
                            <TblTitle>Subjects</TblTitle>
                        </TH>
                        <TH>
                            <TblTitle>Date of Exam</TblTitle>
                        </TH>
                        <TH>
                            <TblTitle>Total Mark</TblTitle>
                        </TH>

                        <TH>
                            <TblTitle>Pass Mark</TblTitle>
                        </TH>
                        <TH>
                            <TblTitle>Mark Obtained</TblTitle>
                        </TH>
                        <TH>
                            <TblTitle>Result</TblTitle>
                        </TH>
                    </TRHead>
                </THead>

                <TBody>
                    {data?.subjects?.map((item, index) => (
                        <TR
                            //  id={item.id}
                            key={index}
                        >
                            <TD>
                                <TDText>{item.name}</TDText>
                            </TD>
                            <TD>
                                <TDText>{item.result.date}</TDText>
                            </TD>
                            <TD>
                                <TDText>{data.total_mark}</TDText>
                            </TD>
                            <TD>
                                <TDText>{data.pass_mark}</TDText>
                            </TD>

                            <TD>
                                <TDText>{item.result.total_mark}</TDText>
                            </TD>
                            <TD>
                                <TDText
                                    className={
                                        item.result.is_pass ? "pass" : "fail"
                                    }
                                >
                                    {item.result.is_pass ? "P" : "F"}
                                </TDText>
                            </TD>
                        </TR>
                    ))}
                </TBody>
            </TableDiv>
            <BottomDiv>
                <BottomLeft>
                    <TDText>Overall Grade : </TDText>
                    <TDText
                        className={
                            data.overall_grade === "Failed" ? "fail" : "pass"
                        }
                    >
                        &nbsp;{data.overall_grade}
                    </TDText>
                </BottomLeft>
                <BottomRight>
                    <DownloadButton
                        onClick={() => {
                            window.open(
                                data.result_link,
                                "_blank",
                                "noreferrer"
                            );
                        }}
                    >
                        Download Grade Card
                    </DownloadButton>
                </BottomRight>
            </BottomDiv>
        </div>
    );
}

export default ExamResult;

const TableDiv = styled.table`
    margin-top: 20px;
    /* padding: 20px; */
    background: #ffffff;
    /* box-shadow: 0px 4px 24px rgba(164, 164, 164, 0.25); */
    border-radius: 16px;
    width: 80%;
    /* border: 1px solid #e5e5e5; */
`;
const THead = styled.thead`
    display: block;
    width: 100%;
    background: #f3f3f3;
    border: 1px solid #e5e5e5;
    border-radius: 8px 8px 0 0;
`;
const TRHead = styled.tr`
    display: flex;
    align-items: center;
    padding: 15px 14px;
`;
const TH = styled.th`
    /* border-collapse: collapse; */
    text-align: left;
    color: #fff;
    white-space: nowrap;
    /* flex-basis: 100%; */
    flex-grow: 2;
    width: 11%;
    :first-child {
        width: 20%;
    }
`;
const TblTitle = styled.p`
    font-size: 14px;
    color: #000;
`;
const TBody = styled.tbody`
    display: block;
    width: 100%;
    overflow-y: scroll;
    /* height: 400px; */
    /* &::-webkit-scrollbar {
		width: 8px;
		background: #f2f2f2;
		border-radius: 5px;
	}
	&::-webkit-scrollbar-thumb {
		background: #c1c1c1;
		border-radius: 10px;
		opacity: 0.5;
	} */
`;

const TR = styled.tr`
    width: 100%;
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: space-between;
    padding: 0px 15px;
    border: 1px solid #e5e5e5;
    border-top: none;
    :first-child {
    }
`;

const TD = styled.td`
    display: block;
    color: #fff;
    flex-grow: 2;
    font-size: 14px;
    align-items: center;
    display: flex;

    width: 11%;
    :first-child {
        width: 20%;
    }
`;
const TDText = styled.p`
    font-size: 14px;
    color: #000;
    &.pass {
        font-weight: 600;
        color: #92bf33;
        font-size: 15px;
    }
    &.fail {
        font-weight: 600;
        color: #f61f1f;
        font-size: 15px;
    }
`;
const BottomDiv = styled.div`
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
`;
const BottomLeft = styled.div`
    display: flex;
    align-items: center;
`;
const BottomRight = styled.div``;
const DownloadButton = styled.button`
    padding: 10px 16px;
    background: #917afd;
    border-radius: 6px;
    font-size: 15px;
    color: #fff;
    cursor: pointer;
`;
