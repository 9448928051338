import React from "react";
import styled from "styled-components";

export default function NotificationCard() {
    return (
        <>
            <NotificationCardMainDiv>
                <NoticeContainer>
                    <NoticeText>Notice</NoticeText>
                    <RedDot></RedDot>
                </NoticeContainer>
                <NotificationText>
                    Faucibus arcu sit dignissim laoreet cursus laoreet viverra.
                    Cras leo quis purus leo sollicitudin morbi massa netus eu.
                    congue et.
                </NotificationText>
                <ThirdLine>
                    <LeftPart>
                        <ButtonDiv>
                            <ButtonText>Attend</ButtonText>
                        </ButtonDiv>
                    </LeftPart>
                    <RightPart>
                        <TimeText>10:25 Pm</TimeText>
                    </RightPart>
                </ThirdLine>
            </NotificationCardMainDiv>
        </>
    );
}

const NotificationCardMainDiv = styled.div`
    padding: 20px 0;
    border-bottom: 0.5px solid #c1c1c1;
`;
const NoticeContainer = styled.div`
    display: flex;
    align-items: center;
`;
const NoticeText = styled.span`
    color: #404040;
    font-size: 0.85rem;
    font-weight: 500;
    display: block;
`;
const RedDot = styled.div`
    height: 6px;
    width: 6px;
    background: #fc3e3e;
    border-radius: 50%;
    margin-left: 8px;
`;
const NotificationText = styled.p`
    font-weight: 300;
    font-size: 0.8rem;
    line-height: 20px;
    color: #404040;
    margin-top: 10px;
    /* width: 90%; */
`;
const ThirdLine = styled.div`
    margin-top: 8px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
`;
const LeftPart = styled.div`
    width: 75%;
`;
const ButtonDiv = styled.div`
    width: 100px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #1f0ddb;
    box-sizing: border-box;
    border-radius: 50px;
    cursor: pointer;
`;
const ButtonText = styled.span`
    display: block;
    font-size: 0.7rem;
    font-weight: 500;
    color: #1f0ddb;
    text-align: center;
    text-transform: capitalize;
`;
const RightPart = styled.div``;
const TimeText = styled.span`
    display: block;
    color: #9d9d9d;
    text-align: right;
    font-size: 0.8rem;
    font-weight: 500;
`;
