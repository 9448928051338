import React from "react";
import styled from "styled-components";

//ck
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default function QuestionCard({
	item,
	index,
	selectedViewmore,
	setSelectedViewmore,
}) {
	return (
		<>
			<QuestionDiv>
				<TopSection>
					<QCount>Q.{index + 1}</QCount>
					<Question>{item.question}</Question>
				</TopSection>
				<SubSection>
					<Left>
						<Mark>Marks : {item.mark}</Mark>
						<MinMark>Minimum words : {item.min_words}</MinMark>
						<MinMark>Maximum words : {item.max_words}</MinMark>
					</Left>
					{item.description && (
						<ViewMoreDiv>
							<ViewMore
								onClick={() => {
									if (selectedViewmore) {
										setSelectedViewmore("");
									} else {
										setSelectedViewmore(item.id);
									}
								}}
							>
								{selectedViewmore === item.id
									? "View less..."
									: "View more..."}
							</ViewMore>
						</ViewMoreDiv>
					)}
				</SubSection>
				{selectedViewmore === item.id && item.description && (
					<DescriptionDiv>
						<CkDiv className="ckeditor-custom">
							<CKEditor
								disabled
								config={{
									toolbar: [],
									shouldNotGroupWhenFull: true,
								}}
								editor={ClassicEditor}
								data={item?.description}
								onReady={(editor) => {}}
								onBlur={(event, editor) => {}}
								onFocus={(event, editor) => {}}
								onChange={(event, editor) => {}}
							/>
						</CkDiv>
					</DescriptionDiv>
				)}
				{/* <Hint>
					<HintText></HintText>
					<Text>{item.hints}</Text>
				</Hint> */}
			</QuestionDiv>
		</>
	);
}
const QuestionDiv = styled.div`
	background: #ffffff;
	border-radius: 12px;
	padding: 16px;
	margin-top: 20px;
	:first-child {
		margin-top: 0;
	}
`;

const TopSection = styled.div`
	display: flex;
`;
const QCount = styled.span`
	color: #010101;
	font-size: 18px;
`;
const Question = styled.h4`
	margin-left: 16px;
	color: #010101;
	font-size: 18px;
`;
const SubSection = styled.div`
	display: flex;
	/* margin: 10px 43px auto; */
	align-items: center;
	justify-content: space-between;
`;
const Mark = styled.h4`
	color: #9d9d9d;
	font-size: 14px;
	margin-left: 40px;
`;
const MinMark = styled.h4`
	margin-left: 50px;
	color: #9d9d9d;
	font-size: 14px;
`;

const DescriptionDiv = styled.div`
	margin-top: 10px;
`;
const CkDiv = styled.div`
	&.ckeditor-custom p {
		color: #000;
	}
`;

const ViewMoreDiv = styled.div``;
const ViewMore = styled.h4`
	color: #927af6;
	font-size: 14px;
	cursor: pointer;
`;
const Left = styled.div`
	display: flex;
	align-items: center;
	/* margin: 10px 43px auto; */
`;
const HintText = styled.h4`
	font-size: 16px;
	color: #000;
`;
const Text = styled.div`
	color: #404040;
	font-size: 16px;
`;
