import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
// import Gif from "./../../../assets/lottie/loading-buffering.gif";
// import Gif from "./../../../assets/lottie/loadingg.gif";

function Loader({ background }) {
    return (
        <MainContainer background={background}>
            <>
                <LoaderDiv>
                    <LoadingGif
                        // src={
                        //     require("./../../../assets/lottie/Circles-menu-3.gif")
                        //         .default
                        // }
                        src={
                            require("./../../../assets/lottie/loading-buffering.gif")
                                .default
                        }
                        alt="Loading"
                    />
                </LoaderDiv>
            </>
        </MainContainer>
    );
}

export default Loader;

const MainContainer = styled.div`
    padding: 20px;
    /* background: #e9eff5; */
    background: ${(props) => (props.background ? props.background : null)};
    height: 100%;

    overflow: scroll;
`;
const LoaderDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`;
const LoadingGif = styled.img`
    display: inline-block;
    width: 70px;
    opacity: 0.5;

    @media (max-width: 980px) {
        width: 50px;
    }
`;
