import React, { useState, useContext } from "react";
import styled from "styled-components";

export default function RadioQuestions({
	options,
	selectedOption,
	setSelectedOption,
	setQuestionId,
	previousAnswers,
}) {
	return (
		<MainContainer>
			{options?.question_data?.objective_choices?.map((data, index) => (
				<Container
					key={index}
					onClick={() => {
						setSelectedOption(data?.id);
						setQuestionId(options?.id);
					}}
				>
					<RadioButton
						style={{
							borderColor:
								selectedOption == data?.id
									? "#917afd"
									: "#c1c1c1",
						}}
					>
						{selectedOption == data?.id && <Radio />}
					</RadioButton>
					<QuestionText>{data?.option}</QuestionText>
				</Container>
			))}
		</MainContainer>
	);
}

const MainContainer = styled.div`
	margin-top: 20px;
`;
const Container = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 20px;
	cursor: pointer;
`;
const RadioButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	height: 22px;
	width: 22px;
	border: 2px solid;
	margin-right: 15px;
`;
const Radio = styled.div`
	background: #917afd;
	height: 12px;
	width: 12px;
	border-radius: 50%;
`;
const QuestionText = styled.p`
	color: #000;
	font-size: 16px;
`;
