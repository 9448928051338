import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";

import { Context } from "./../../../contexts/Store";
import { baseConfig } from "./../../../axiosConfig";

// mui
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EmptyPage from "../../includes/emptyPages/EmptyPage";
import emptyPic from "../../../assets/icons/webinar-empty-icon.svg";

export default function Faq() {
    // const [active, setActive] = useState(false);
    const { state } = useContext(Context);
    const [isLoading, setLoading] = useState(true);
    const [faqs, setFaqs] = useState([]);

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const getFaqs = () => {
        setLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/general/faq/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {},
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setFaqs(data);
                    setTimeout(() => {
                        setLoading(false);
                    }, 200);
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {});
    };
    useEffect(() => {
        getFaqs();
    }, []);

    return (
        <MainContainer>
            <Title>Frequently Asked Question's</Title>
            <AccordionContainer>
                {faqs.length > 0 ? (
                    <>
                        {faqs.map((data) => (
                            <Accordion
                                // onChange={(e, expanded) => {
                                //     setActive(expanded);
                                // }}
                                expanded={expanded === `panel${data.id}`}
                                onChange={handleChange(`panel${data.id}`)}
                                // style={{ boxShadow: "none" }}
                                key={data.id}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    style={{ boxShadow: "none" }}
                                >
                                    {/* <Heading>Accordion 1</Heading> */}
                                    <Heading
                                    // className={
                                    //     active
                                    //         ? "accordianSpecial blueColor"
                                    //         : "accordianSpecial"
                                    // }
                                    >
                                        {data.question}
                                    </Heading>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Content>{data.answer}</Content>
                                    {/* <pre
                                        style={{
                                            fontSize: "14px",
                                            width: "100%",
                                        }}
                                    >
                                        {data.answer}
                                    </pre> */}
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </>
                ) : (
                    <EmptyContainer>
                        <EmptyPage img={emptyPic} message={"Nothing here"} />
                    </EmptyContainer>
                )}
            </AccordionContainer>
        </MainContainer>
    );
}

const MainContainer = styled.div`
    background: #ffffff;
    padding: 20px 15px;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 10px;
    height: calc(100vh - 110px);
    overflow-y: scroll;
    /* &::-webkit-scrollbar {
        width: 3px;
    }
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #d6dee1;
        border-radius: 20px;
    } */
`;
const AccordionContainer = styled.div`
    margin-top: 30px;
    width: 100%;
    max-width: 980px;
`;
const Heading = styled.h6`
    font-size: 1.05rem;
    font-weight: 500;
    color: #404040;
`;
const Content = styled.p`
    font-size: 1rem;
    font-weight: 300;
    color: #404040;
`;
const Title = styled.h6`
    font-size: 1.2rem;
    font-weight: 500;
    /* width: 980px; */
`;
const EmptyContainer = styled.div`
    height: calc(100vh - 295px);
    overflow-y: scroll;
`;
// const AccordionContainer = styled.div``;
