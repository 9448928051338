import axios from "axios";


//Demo

// export const baseConfig = axios.create({
//   baseURL: "https://backend-lms.talrop.works/api/v1",
// });
// export const devConfig = axios.create({
//   baseURL: "https://jsonplaceholder.typicode.com/todos",
// });
// export const BASE_URL = "https://backend-lms.talrop.works/api/v1";


// local
// export const baseConfig = axios.create({
// 	baseURL: "http://127.0.0.1:8009/api/v1",
// });
// export const devConfig = axios.create({
// 	baseURL: "https://jsonplaceholder.typicode.com/todos",
// });

// export const BASE_URL = "http://127.0.0.1:8009/api/v1";

//Server

export const baseConfig = axios.create({
    baseURL: "https://backend.learnersuae.com/api/v1",
});
export const BASE_URL = "https://backend.learnersuae.com/api/v1";
export const devConfig = axios.create({
    baseURL: "https://jsonplaceholder.typicode.com/todos",
});


// 	baseURL: "https://api-lms.talrop.works/api/v1",