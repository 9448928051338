import React from "react";
import styled from "styled-components";

export default function Subject() {
    return <MainDiv>Subject</MainDiv>;
}

const MainDiv = styled.div`
    background: #fff;
    padding: 12px;
    border: 1px solid #e5e5e5;
    /* box-sizing: border-box; */
    border-radius: 10px;
    height: fit-content;
    height: calc(100vh - 110px);
`;
