import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import UpcomingLiveCard from "../../screens/classroom/UpcomingLiveCard";

//image
import playIcon from "../../../assets/icons/play-icon-dashboard-white.svg";
import download from "../../../assets/icons/download.svg";
import docFile from "../../../assets/icons/doc-icon.svg";
import defaultLiveImage from "../../../assets/images/background.png";
import VideoModal from "../VideoModal";
import { baseConfig } from "../../../axiosConfig";
import { Context } from "../../../contexts/Store";
import Loader from "../loader/Loader";
import EmptyPage from "../emptyPages/EmptyPage";
import emptyPic from "../../../assets/icons/live-class-empty-icon.svg";

export default function CRLive({ isSingleLoading, data }) {
    const [isSinglePage, setSinglePage] = useState(false);
    const [isUpcoming, setIsUpcoming] = useState(false);

    const [isVideoLoading, setIsVideoLoading] = useState(true);
    const [isVideoModal, setIsVideoModal] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const { state } = useContext(Context);

    const [completedLiveDetails, setCompletedLiveDetails] = useState({});

    // const [liveDetails, setLiveDetails] = useState([]);

    // const getLiveDetails = () => {
    // 	setLoading(true);
    // 	let accessToken = state.user_details.access_token;
    // 	baseConfig
    // 		.get(`/class-room/room/${selectedClassRoom.id}/live-classes/`, {
    // 			headers: {
    // 				Authorization: "Bearer " + accessToken,
    // 			},
    // 		})
    // 		.then((response) => {
    // 			const { StatusCode, data } = response.data;

    // 			if (StatusCode === 6000) {
    // 				setLiveDetails(data);
    // 				setTimeout(() => {
    // 					setLoading(false);
    // 				}, 200);
    // 			} else {
    // 				setTimeout(() => {
    // 					setLoading(false);
    // 					alert("It is taking longer than usual");
    // 				}, 200);
    // 			}
    // 		})

    // 		.catch((error) => {});
    // };

    useEffect(() => {
        setCompletedLiveDetails({});
        setSinglePage(false);
    }, [data]);

    useEffect(() => {
        if (
            data?.live_classes?.upcoming.length == 0 &&
            data?.live_classes?.completed.length > 0
        ) {
            setIsUpcoming(true);
        }
    }, [data]);

    return (
        <>
            {!isSingleLoading ? (
                <>
                    {!isSinglePage ? (
                        <CardContainer>
                            <LivetitleSection>
                                <Livetitle>Live Class</Livetitle>
                            </LivetitleSection>

                            {data?.live_classes?.upcoming.length > 0 ||
                            data?.live_classes?.completed.length > 0 ? (
                                <>
                                    {data?.live_classes?.upcoming.length >
                                        0 && (
                                        <>
                                            <UpcomingTitle>
                                                Upcoming
                                            </UpcomingTitle>
                                            <DivContainer>
                                                {data?.live_classes?.upcoming?.map(
                                                    (item, i) => (
                                                        <UpcomingLiveCard
                                                            item={item}
                                                            key={i}
                                                        />
                                                    )
                                                )}
                                            </DivContainer>
                                        </>
                                    )}

                                    {/* {data?.live_classes?.upcoming.length == 0 &&
                                        data?.live_classes?.completed.length >
                                            0 && (
                                            <SkyBlueCard>
                                                <CardText>
                                                    You don’t have upcoming Live
                                                    classes, watch previous Live
                                                    classes
                                                </CardText>
                                                 <CloseIcon>&#x2715;</CloseIcon> 
                                            </SkyBlueCard>
                                        )} */}

                                    {isUpcoming && (
                                        <SkyBlueCard>
                                            <CardText>
                                                You don’t have upcoming Live
                                                classes, watch previous Live
                                                classes
                                            </CardText>
                                            <CloseIcon
                                                onClick={() => {
                                                    setIsUpcoming(false);
                                                }}
                                            >
                                                &#x2715;
                                            </CloseIcon>
                                        </SkyBlueCard>
                                    )}

                                    <CompleteContainer>
                                        {data?.live_classes?.completed.length >
                                            0 && (
                                            <>
                                                <UpcomingTitle>
                                                    Completed
                                                </UpcomingTitle>
                                                <DivContainer>
                                                    {data?.live_classes?.completed?.map(
                                                        (item, i) => (
                                                            <UpcomingLiveCard
                                                                upcoming={false}
                                                                setSinglePage={
                                                                    setSinglePage
                                                                }
                                                                item={item}
                                                                key={i}
                                                                setCompletedLiveDetails={
                                                                    setCompletedLiveDetails
                                                                }
                                                            />
                                                        )
                                                    )}
                                                </DivContainer>
                                            </>
                                        )}
                                        {/* </>
								) : (
									<EmptyContainer>
										<EmptyPage
											img={emptyPic}
											message={
												// "You have no live or upcoming classes"
												"You have no completed classes"
											}
										/>
									</EmptyContainer>
								)} */}
                                    </CompleteContainer>
                                </>
                            ) : (
                                <EmptyContainer>
                                    <EmptyPage
                                        img={emptyPic}
                                        message={
                                            // "You have no live or upcoming classes"
                                            "You have no live sessions"
                                        }
                                    />
                                </EmptyContainer>
                            )}
                        </CardContainer>
                    ) : (
                        // <CardContainer>haaa</CardContainer>
                        <LivetitleSection>
                            <LiveTitleDiv>
                                <TitleLeft>
                                    <Livetitle>Live Class</Livetitle>
                                    <TextDiv>
                                        <PathText
                                            onClick={() => {
                                                // history.push("/program/");
                                                setSinglePage(false);
                                            }}
                                        >
                                            Live
                                        </PathText>
                                        <Slash>&nbsp;/&nbsp;</Slash>
                                        <PathTextBlack>
                                            {completedLiveDetails.title}
                                        </PathTextBlack>
                                    </TextDiv>
                                </TitleLeft>
                                <TitleRight>
                                    <GoBackButton
                                        onClick={() => {
                                            setSinglePage(false);
                                        }}
                                    >
                                        &#8592; Back to Live
                                    </GoBackButton>
                                </TitleRight>
                            </LiveTitleDiv>
                            <Body>
                                <div>
                                    <VideoImageDiv
                                        backgroundImage={
                                            completedLiveDetails.image
                                                ? completedLiveDetails.image
                                                : defaultLiveImage
                                        }
                                    >
                                        {/* {selectedLiveData.video_link && ( */}
                                        {completedLiveDetails.video_link && (
                                            <PlayIcon
                                                src={playIcon}
                                                alt="icon"
                                                onClick={(e) => {
                                                    // e.preventDefault();
                                                    // handleOpenVideoModal();
                                                    // setCompletedVideoLink(
                                                    //     selectedLiveData.video_link
                                                    // );
                                                    setIsVideoLoading(true);
                                                    setIsVideoModal(true);
                                                }}
                                            />
                                        )}
                                        {/* )} */}
                                    </VideoImageDiv>
                                    <LiveTitleDiv>
                                        <TitleLeft>
                                            <SubjectTitle>
                                                {completedLiveDetails.title}
                                            </SubjectTitle>
                                            {completedLiveDetails.duration && (
                                                <Time>
                                                    {
                                                        completedLiveDetails.duration
                                                    }
                                                </Time>
                                            )}
                                        </TitleLeft>
                                        <TitleRight>
                                            {completedLiveDetails.video_link && (
                                                <PlayButton
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setIsVideoLoading(true);
                                                        setIsVideoModal(true);
                                                    }}
                                                >
                                                    Watch Replay
                                                </PlayButton>
                                            )}
                                            {/* <PlayButton
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setIsVideoLoading(true);
                                                    setIsVideoModal(true);
                                                }}
                                            >
                                                Watch Replay
                                            </PlayButton> */}
                                        </TitleRight>
                                    </LiveTitleDiv>
                                </div>
                                <div>
                                    <ContentBox>
                                        <ContentHeadingBox>
                                            <ContentHead>Material</ContentHead>
                                        </ContentHeadingBox>
                                        <>
                                            {/* <MaterialData>
                                                <MaterialContent>
                                                    <DataLeft>
                                                        <FileIcon
                                                            src={docFile}
                                                            alt="icon"
                                                        />
                                                        <FileName>
                                                            Uploaded File
                                                        </FileName>
                                                    </DataLeft>

                                                    <DataRight>
                                                        <DownloadButton
                                                            href={
                                                                completedLiveDetails.ppt
                                                            }
                                                            target="_blank"
                                                        >
                                                            <EditFileIcon
                                                                src={download}
                                                                alt="icon"
                                                            />
                                                        </DownloadButton>
                                                    </DataRight>
                                                </MaterialContent>
                                            </MaterialData>
                                            <Actions></Actions> */}
                                            {completedLiveDetails?.ppt_files
                                                ?.length > 0 && (
                                                <>
                                                    {completedLiveDetails?.ppt_files.map(
                                                        (item, i) => (
                                                            <MaterialData
                                                                key={item.id}
                                                            >
                                                                <MaterialContent>
                                                                    <DataLeft>
                                                                        {/* <FileIcon
                                                                            src={
                                                                                fileIcon
                                                                            }
                                                                            alt="icon"
                                                                        /> */}
                                                                        <FileName>
                                                                            {/* Uploaded File */}
                                                                            {/* File {i + 1} */}
                                                                            {
                                                                                item.file_name
                                                                            }
                                                                        </FileName>
                                                                    </DataLeft>

                                                                    <DataRight>
                                                                        {/* <EditFileBox
                                                                            // htmlFor="edit-file"
                                                                            onClick={() => {
                                                                                handleDeleteDoc(
                                                                                    item.id
                                                                                );
                                                                            }}
                                                                        >
                                                                           
                                                                            <EditFileIcon
                                                                                src={
                                                                                    deleteIcon
                                                                                }
                                                                                alt="icon"
                                                                            />
                                                                        </EditFileBox> */}

                                                                        <AnchorWrapper
                                                                            href={
                                                                                item.ppt
                                                                            }
                                                                            target="_blank"
                                                                        >
                                                                            <EditFileIcon
                                                                                src={
                                                                                    download
                                                                                }
                                                                                alt="icon"
                                                                            />
                                                                        </AnchorWrapper>
                                                                    </DataRight>
                                                                </MaterialContent>
                                                            </MaterialData>
                                                        )
                                                    )}

                                                    {/* <Actions></Actions> */}
                                                </>
                                            )}
                                        </>
                                    </ContentBox>
                                </div>
                            </Body>
                            {isVideoModal && (
                                <VideoModalBackContainer
                                    onClick={() => {
                                        setIsVideoModal(false);
                                    }}
                                >
                                    <CenterDiv>
                                        <VideoModal
                                            completedVideoLink={
                                                // "https://vimeo.com/186191787"
                                                completedLiveDetails.video_link
                                            }
                                            setIsVideoLoading={
                                                setIsVideoLoading
                                            }
                                            isVideoLoading={isVideoLoading}
                                        />
                                    </CenterDiv>
                                </VideoModalBackContainer>
                            )}
                        </LivetitleSection>
                    )}
                </>
            ) : (
                <Loader />
            )}
        </>
    );
}
const AnchorWrapper = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
`;
const CardContainer = styled.div``;
const LivetitleSection = styled.div`
    margin-bottom: 20px;
`;
const Livetitle = styled.h4`
    color: #010101;
    font-weight: 500;
    font-size: 22px;
    margin-bottom: 15px;
    /* line-height: 29px; */
`;
const UpcomingTitle = styled.h4`
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
`;

const EmptyContainer = styled.div`
    height: calc(100vh - 350px);
    /* height: 40vh; */
    overflow-y: scroll;
`;
const CompleteContainer = styled.div`
    margin-top: 20px;
`;
const DivContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
`;
const LiveTitleDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const TitleLeft = styled.div``;
const TitleRight = styled.div``;
const GoBackButton = styled.div`
    border: 1px solid #917afd;
    border-radius: 8px;
    padding: 4px 10px;
    font-size: 14px;
    color: #917afd;
    cursor: pointer;
`;
const TextDiv = styled.div`
    margin-bottom: 10px;
`;
const PathText = styled.span`
    display: inline-block;
    font-size: 14px;
    color: #9d9d9d;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;
const Slash = styled.span`
    display: inline-block;
    font-size: 14px;
    color: #9d9d9d;
`;
const PathTextBlack = styled.span`
    color: #000;
    display: inline-block;
    font-size: 14px;
`;
const Body = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
`;
const VideoImageDiv = styled.div`
    width: 100%;
    height: 300px;
    background: url(${(props) => props.backgroundImage}), #000000;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    /* vimeo */
    #player .logo {
        display: none !important;
    }
    #media-container .player .vp-sidedock {
        display: none !important;
    }
`;
const PlayIcon = styled.img`
    cursor: pointer;
`;
const SubjectTitle = styled.h3`
    font-weight: 600;
    font-size: 16px;
`;
const ClassDetails = styled.div``;
const PlayButton = styled.button`
    border: 1px solid #917afd;
    border-radius: 8px;
    padding: 0px 10px;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
    background-color: #917afd;
    height: 40px;
`;
const Time = styled(PathText)`
    &:hover {
        text-decoration: none;
    }
`;
const ContentBox = styled.div`
    background-color: #f5f5f5;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 15px;
    &:last-child {
        margin-bottom: 0;
    }
`;
const ContentHeadingBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const ContentHead = styled.h4`
    font-size: 17px;
    font-weight: 600;
`;

const ActionBtnFill = styled.label`
    border: 1px solid #c1c1c1;
    border-radius: 8px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    background-color: #917afd;
    color: #fff;
    box-sizing: border-box;
    font-size: 15px;
    cursor: pointer;
`;

const UploadFileInput = styled.input`
    display: none;
`;
const BtnIcon = styled.img`
    display: block;
    margin-right: 5px;
    width: 16px;
`;

const MaterialData = styled.ul`
    margin-top: 10px;
`;
const MaterialContent = styled.li`
    display: flex;
    justify-content: space-between;
    border: 1px solid #c1c1c1;
    background: #fff;
    border-radius: 6px;
    padding: 7px;
    &:nth-child(n + 2) {
        margin-top: 10px;
    }
`;
const DataLeft = styled.div`
    display: flex;
    align-items: center;
`;

const DataRight = styled.div`
    display: flex;
    align-items: center;
`;

const EditFileBox = styled.label`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 6px;
    cursor: pointer;
`;

const FileName = styled.h5`
    font-size: 16px;
    font-weight: 600;
`;
const EditFileIcon = styled.img`
    width: 20px;
`;
const DownloadButton = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const FileIcon = styled.img`
    display: block;
    margin-right: 10px;
`;
const Actions = styled.div`
    justify-self: end;
    display: flex;
    justify-content: end;
    margin-top: 15px;
`;
const VideoModalBackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    width: 100%;
    height: 100vh;
    z-index: 1002;
    left: 0;
    top: 0px;
    background: rgba(0, 0, 0, 0.3);

    /* cursor: not-allowed; */
`;
const CenterDiv = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const SkyBlueCard = styled.div`
    background: #edf5fe;
    /* Primary/Blue */

    border-left: 4px solid #522f91;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 26px;
`;
const CardText = styled.span`
    display: inline-block;
    color: #404040;
    font-size: 14px;
`;
const CloseIcon = styled.span`
    display: inline-block;
    color: #404040;
    font-size: 14px;
    cursor: pointer;
`;
