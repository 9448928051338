import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import propic from "../../../assets/images/profil.png";

export default function ClassroomCardSingle({ data, classroom_id }) {
	const history = useHistory();

	return (
		<>
			<CardContainer>
				{data.map((item, i) => (
					<ClassCard
						style={{
							borderColor:
								classroom_id == item.id ? "#522f91" : "#fff",
						}}
						onClick={() => {
							// setSelectedClassRoomID(item.id);
							history.push(`?c=${item.id}`);
						}}
					>
						<Content>
							<SubjectTitleWrapper>
								<SubjectTitle>{item.subject_name}</SubjectTitle>
								<SubjectDate>
									{/* {item.name.split("-").slice(1).join("-")} */}
									{item.date}
								</SubjectDate>
							</SubjectTitleWrapper>
							<LineBorder />
							<ProfileSection>
								<ProfileIcon
									src={item.faculty_image}
									alt="Profile"
								/>
								<Name>{item.faculty_name}</Name>
							</ProfileSection>
						</Content>
					</ClassCard>
				))}
			</CardContainer>
		</>
	);
}
const CardContainer = styled.div``;
const ClassCard = styled.div`
	/* width: 340px; */
	/* height: 114px; */
	background: #ffffff;
	cursor: pointer;
	border-radius: 12px;
	margin-top: 14px;
	border: 2px solid #ffffff;
	:first-child {
		margin-top: 0px;
	}
	:hover{
		border-color: #522f91;
	}
`;
const Content = styled.div`
	padding: 15px;
`;
const SubjectTitleWrapper = styled.h3`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const SubjectTitle = styled.h3`
	font-weight: 600;
	font-size: 16px;
	line-height: 26px;
	width: 65%;
`;

const SubjectDate = styled(SubjectTitle)`
	width: auto;
`;

const LineBorder = styled.div`
	border-bottom: 2px solid #f5f5f5;

	margin-top: 6px;
`;

const ProfileSection = styled.div`
	display: flex;
	align-items: center;
	margin-top: 10px;
`;
const ProfileIcon = styled.img`
	width: 32px;
	height: 32px;
	display: block;
	border-radius: 50%;
`;
const Name = styled.h4`
	margin-left: 10px;
	font-weight: 500;
	font-size: 14px;
	color: #000000;
`;
