import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import QuestionInstruction from "./QuestionInstruction";
// import QuestionInstruction from "./QuestionInstruction";

function InstructionModal({ setInstructionModal }) {
	return (
		<BackContainer>
			<Overlay
				onClick={() => {
					setInstructionModal(false);
				}}
			></Overlay>
			<Modal>
				<QuestionsContainer>
					<QuestionInstruction />
				</QuestionsContainer>
			</Modal>
		</BackContainer>
	);
}
export default InstructionModal;

const BackContainer = styled.div`
	position: fixed;
	transition: 0.3s;
	width: 100%;
	height: 100vh;
	z-index: 1000;
	left: 0;
	top: 0px;
	background: rgba(0, 0, 0, 0.4);
`;
const Overlay = styled.div`
	position: fixed;
	left: 0;
	top: 0px;
	width: 100%;
	cursor: pointer;
	height: 100vh;
`;
const Modal = styled.div`
	margin: 0 auto;
	width: 75%;
	height: auto;
	z-index: 101;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	border-radius: 8px;
	overflow: scroll;
	::-webkit-scrollbar {
		display: none;
	}

	::-webkit-scrollbar-track {
		display: none;
	}

	::-webkit-scrollbar-thumb {
		display: none;
	}
	overflow: -moz-scrollbars-none;
	scrollbar-width: none;
`;

const MainContainer = styled.div`
	//   padding: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	// height: 100vh;
	height: 100%;
	width: 100%;
	overflow: scroll;
`;
const QuestionsContainer = styled.div`
	border: 1px solid #e5e5e5;
	border-radius: 8px;
	background: #fff;
	padding: 15px;
`;
const TopSpan = styled.span`
	position: absolute;
	padding: 8px;
	width: 2%;
	top: 0;
	right: 0;
	cursor: pointer;
`;
const Close = styled.img`
	display: block;
	width: 100%;
`;
const TopDiv = styled.div`
	display: flex;
	align-items: center;
	margin: 0 auto;
	justify-content: center;
	border-bottom: 1px solid #e5e5e5;
	padding: 15px;
`;
const SpanContainer = styled.span`
	width: 20px;
	margin-right: 10px;
`;
const Icon = styled.img`
	display: block;
	width: 100%;
`;
const HeadText = styled.h3`
	font-size: 20px;
	font-weight: bold;
	font-family: "inter-medium";
`;
const Mid = styled.div`
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 20px;
`;
const MiddleContainer = styled.div`
	width: 80%;
	margin-top: 40px;
`;
const SubText = styled.h4`
	margin-bottom: 20px;
	font-size: 18px;
	font-weight: bold;
	font-family: "inter-medium";
`;
const UnorderedList = styled.ul`
	list-style-type: disc;
`;
const List = styled.li`
	margin-bottom: 10px;
	font-family: "inter-regular";
	font-size: 16px;
	line-height: 27px;
`;
