import React from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
// import QuestionInstruction from "./QuestionInstruction";

function SuccessModal({ setSuccessModal, isTimeBalance, examCompleted }) {
	const history = useHistory();

	return (
		<BackContainer>
			<Overlay
				onClick={() => {
					if (!isTimeBalance) {
						setSuccessModal(false);
					}
				}}></Overlay>
			<Modal>
				{/* <MainContainer> */}
				<ModalContainer>
					{/* <TopSpan
						onClick={() => {
							if (isTimeBalance) {
								setSuccessModal(false);
							}
						}}
					>
						<Close
							src={
								require("../../../assets/icons/close.png")
									.default
							}
						/>
					</TopSpan> */}
					<MiddleImg>
						<Tick src={require("../../../assets/icons/Checked.png").default} />
					</MiddleImg>
					{/* <MiddleText>
						Your exam has been successfully completed. Please click the "Submit" button to finalize your
						submission.
					</MiddleText> */}
					<MiddleText>Are you sure you want to submit your completed exam?</MiddleText>
					<ButtonContainer>
						{/* <Button1>
              <ButtonText1>Revw</ButtonText1>
            </Button1> */}
						{/* <Button
							onClick={() => {
								setSuccessModal(false);
							}}>
							<ButtonText>Cancel</ButtonText>
						</Button> */}
						{!isTimeBalance && (
							<Button
								onClick={() => {
									setSuccessModal(false);
								}}>
								<ButtonText>Cancel</ButtonText>
							</Button>
						)}
						<Button
							onClick={() => {
								examCompleted();
							}}>
							<ButtonText>Submit</ButtonText>
						</Button>
					</ButtonContainer>
				</ModalContainer>

				{/* </MainContainer> */}
			</Modal>
		</BackContainer>
	);
}
export default SuccessModal;

const BackContainer = styled.div`
	position: fixed;
	transition: 0.3s;
	width: 100%;
	height: 100vh;
	z-index: 1000;
	left: 0;
	top: 0px;
	background: rgba(0, 0, 0, 0.4);
`;
const Overlay = styled.div`
	position: fixed;
	left: 0;
	top: 0px;
	width: 100%;
	cursor: pointer;
	height: 100vh;
	//   background: #000; ;
`;
const Modal = styled.div`
	margin: 0 auto;
	// background: #fff;
	/* width: 25%; */
	width: 37%;
	height: auto;
	z-index: 101;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	border-radius: 8px;
	overflow: scroll;
	::-webkit-scrollbar {
		display: none;
	}

	::-webkit-scrollbar-track {
		display: none;
	}

	::-webkit-scrollbar-thumb {
		display: none;
	}
	scrollbar-width: none;
`;

const ModalContainer = styled.div`
	background: #fff;
	border-radius: 12px;
	position: relative;
	padding: 40px;
`;
const TopSpan = styled.span`
	position: absolute;
	padding: 8px;
	width: 6%;
	top: 0;
	right: 0;
	cursor: pointer;
`;
const Close = styled.img`
	display: block;
	width: 100%;
`;
const MiddleImg = styled.div`
	width: 30%;
	margin: 0 auto;
	// display: flex;
	// justify-content: center;
	// align-items: center;
	padding-bottom: 35px;
`;
const MiddleText = styled.h4`
	text-align: center;
	font-size: 16px;
	padding-bottom: 30px;
`;
const Tick = styled.img`
	display: block;
	width: 100%;
`;
const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
`;
const Button = styled.div`
	background-color: #9178f8;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;
	padding: 0 20px;
	border-radius: 12px;
	border: 1px solid #9178f8;
	margin-right: 10px;
	cursor: pointer;
`;
const Button1 = styled.div`
	background-color: #fff;

	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;
	padding: 0 20px;
	border-radius: 12px;
	border: 1px solid #9178f8;
	margin-right: 10px;
	cursor: pointer;
`;
const ButtonText1 = styled.p`
	font-family: "inter-medium";
	color: #9178f8;
`;
const ButtonText = styled.p`
	font-family: "inter-medium";
`;
