import React, { useState } from "react";
import styled from "styled-components";

//ck
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default function AssignmentQnCard({ data, selectedViewmore, setSelectedViewmore, index }) {
	// const [selectedViewmore, setSelectedViewmore] = useState("");

	return (
		<AssignmentCardDiv>
			<TopDiv>
				<AssignmentTitle>
					<QNo>Q.{index + 1}</QNo>

					{data.question}
				</AssignmentTitle>
			</TopDiv>
			<MiddleDiv>
				<MiddleLeft>
					<MarksText>
						Marks: <Mark>{data.mark}</Mark>
					</MarksText>
					<MarksText>
						Minimum Words: <Mark>{data.min_words}</Mark>
					</MarksText>
					{data.max_words ? (
						<MarksText>
							Maximum Words: <Mark>{data.max_words}</Mark>
						</MarksText>
					) : null}
				</MiddleLeft>
				{data.description && (
					<ViewMoreDiv>
						<ViewMore
							onClick={() => {
								if (selectedViewmore) {
									setSelectedViewmore("");
								} else {
									setSelectedViewmore(data.id);
								}
							}}>
							{selectedViewmore === data.id ? "View less..." : "View more..."}
						</ViewMore>
					</ViewMoreDiv>
				)}
			</MiddleDiv>
			{selectedViewmore === data.id && data.description && (
				<DescriptionDiv>
					<CkDiv className="ckeditor-custom">
						<CKEditor
							// disabled
							config={{
								toolbar: [],
								shouldNotGroupWhenFull: true,
							}}
							editor={ClassicEditor}
							data={data?.description}
							onReady={(editor) => {}}
							onBlur={(event, editor) => {}}
							onFocus={(event, editor) => {}}
							onChange={(event, editor) => {}}
						/>
					</CkDiv>
				</DescriptionDiv>
			)}
			{/* {data.hints && (
                <BottomDiv>
                    <Hint>
                        <HintText>Hint: </HintText>
                        {data.hints}
                    </Hint>
                </BottomDiv>
            )} */}
		</AssignmentCardDiv>
	);
}

const AssignmentCardDiv = styled.div`
	width: 100%;
	padding: 15px;
	background: #ffffff;
	border: 1px solid #e5e5e5;
	box-sizing: border-box;
	border-radius: 10px;
	margin-bottom: 15px;
	&:last-child {
		margin-bottom: 0;
	}
	transition: 0.3s;
	/* &:hover {
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
            rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
        box-shadow: rgba(67, 71, 85, 0.5) 0px 0px 0.25em,
            rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
        box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
    } */
`;
const TopDiv = styled.div`
	margin-bottom: 20px;
	user-select: text;
`;
const QNo = styled.span`
	margin-right: 8px;
	font-size: 1rem;
`;
const AssignmentTitle = styled.h6`
	font-size: 1rem;
	font-weight: 500;
	color: #010101;
	line-height: 1.5em;
	user-select: text !important;
`;
const MiddleDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const MiddleLeft = styled.div`
	display: flex;
	align-items: center;
`;
const ViewMoreDiv = styled.div``;
const ViewMore = styled.h4`
	color: #927af6;
	font-size: 14px;
	cursor: pointer;
`;
const DescriptionDiv = styled.div`
	margin-top: 10px;
`;
const CkDiv = styled.div`
	&.ckeditor-custom p {
		color: #000;
	}
`;
const MarksText = styled.div`
	display: flex;
	align-items: center;
	font-size: 0.85rem;
	color: #9d9d9d;
	margin-right: 88px;
	&:last-child {
		margin-right: 0;
	}
`;
const Mark = styled.span`
	display: inline-block;
	font-size: 0.88rem;
	color: #404040;
	margin-left: 6px;
`;
const BottomDiv = styled.div`
	margin-top: 15px;
`;
const Hint = styled.p`
	color: #9d9d9d;
	font-size: 0.9rem;
`;
const HintText = styled.span`
	color: #404040;
	font-size: 00.9rem;
`;
