import React from "react";
import styled from "styled-components";

export default function ActivityCard({ data }) {
    return (
        <Container>
            <Time>{data.date}</Time>
            <Title>{data.activity}</Title>
        </Container>
    );
}

const Container = styled.div`
    border: 1px solid #e5e5e5;
    border-radius: 16px;
    width: 90%;
    padding: 1rem;
    margin-bottom: 0.8rem;
`;
const Time = styled.p`
    font-weight: 400;
    font-size: 13px;
    color: #9d9d9d;
`;
const Title = styled.p`
    font-weight: 600;
    font-size: 16px;
    color: #404040;
    margin-top: 0.5rem;
`;
