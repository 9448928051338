import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";

import durationIcon from "../../../assets/icons/duration-icon.svg";

import pic1 from "../../../assets/images/webinar-pic1.svg";

export default function WebinarCard({
    item,
    type,
    handleOpenVideoModal,
    setCompletedVideoLink,
    setIsVideoLoading,
}) {
    const history = useHistory();
    return (
        <MainDiv>
            <TopPart key={item.id}>
                <ImageTag
                    src={item.image ? item.image : pic1}
                    alt="images"
                    // onClick={() => {
                    //     type === "Upcoming" &&
                    //         history.push("/webinar/upcoming");
                    // }}
                />
                {item.status === "Live" && (
                    <>
                        <LiveContainer>
                            <Bullet></Bullet>
                            <LiveText>Live</LiveText>
                        </LiveContainer>
                    </>
                )}
            </TopPart>
            <BottomPart>
                <FirstLine>{item.title}</FirstLine>
                <SeconLine>
                    {item.status === "Live" ? (
                        <LeftPart>
                            <DurationIcon src={durationIcon} alt="icon" />
                            <DurationText>
                                {moment(item.start_time, ["h:mm A"]).format(
                                    "hh:mm A"
                                )}
                            </DurationText>
                        </LeftPart>
                    ) : (
                        <LeftPart>
                            {/* <DurationIcon src={durationIcon} alt="icon" /> */}
                            <DurationText>
                                Date: {item.date}
                                {"      "}
                            </DurationText>
                            <DurationText>
                                Time:{"   "}
                                {moment(item.start_time, ["h:mm A"]).format(
                                    "hh:mm A"
                                )}
                            </DurationText>
                        </LeftPart>
                    )}
                    {item.status === "Live" ? (
                        <JoinButton
                            className="wiggle-animation"
                            href={item.zoom_link}
                            target="_blank"
                        >
                            Join
                        </JoinButton>
                    ) : item.status === "Upcoming" ? (
                        // <RemindButton>Remind Me</RemindButton>
                        <></>
                    ) : (
                        item.video_link !== null &&
                        item.video_link !== "null" &&
                        item.video_link !== "" && (
                            <ViewButton
                                onClick={() => {
                                    handleOpenVideoModal();
                                    setCompletedVideoLink(item.video_link);
                                    setIsVideoLoading(true);
                                }}
                            >
                                View
                            </ViewButton>
                        )
                    )}
                </SeconLine>
            </BottomPart>
        </MainDiv>
    );
}

const JoinButton = styled.a`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 9px 18px;
    background: #917afd;
    color: #ffffff;
    border: 1px solid #917afd;
    border-radius: 24px;
    font-size: 0.8rem;
    min-width: 75px;
    cursor: pointer;
    /* transition: 1s; */
    /* &:hover {

    } */
`;
const MainDiv = styled.div`
    background: #fff;
    padding: 12px;
    border: 1px solid #e5e5e5;
    /* box-sizing: border-box; */
    border-radius: 10px;
    user-select: none;

    /* &:hover {
        background: #fdfcff;
        border: none;
    } */
    transition: 0.3s;
    /* cursor: pointer; */
    &:hover {
        /* box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
            rgba(0, 0, 0, 0.05) 0px 4px 6px -2px; */
        /* box-shadow: rgba(67, 71, 85, 0.5) 0px 0px 0.25em,
            rgba(90, 125, 188, 0.05) 0px 0.25em 1em; */
        box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
        transform: translateY(-0.05em);

        ${JoinButton} {
            background-color: #fff;
            color: #917afd;
            /* transform: translateY(-0.05em); */
            /* box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px; */
        }
    }
`;
const TopPart = styled.div`
    position: relative;
    margin-bottom: 10px;
    border-radius: 6px;
    width: 100%;
    height: 140px;
    /* background-size: 100% 100%; */
    /* cursor: pointer; */
`;
const ImageTag = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    object-fit: cover;
`;
const LiveContainer = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    padding: 6px 10px;
    background: #ffffff;
    border-radius: 16px;
    top: 7%;
    left: 4%;
`;
const Bullet = styled.div`
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #f00;
    animation: blinker 1s linear infinite;
`;
const LiveText = styled.h6`
    font-size: 0.8rem;
    margin-left: 8px;
    font-weight: 500;
    letter-spacing: 0.6px;
`;
const BottomPart = styled.div`
    margin-top: 15px;
`;
const FirstLine = styled.h6`
    font-size: 1.05rem;
    color: #000000;
`;
const SeconLine = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
`;
const LeftPart = styled.div`
    display: flex;
    align-items: center;
`;
const DurationIcon = styled.img`
    margin-right: 8px;
`;
const DurationText = styled.span`
    display: inline-block;
    font-size: 0.75rem;
    /* color: #c1c1c1; */
    color: #808080;
    margin-left: 10px;
    &:first-child {
        margin-left: 0;
    }
`;

const RemindButton = styled.button`
    padding: 7px 15px;
    background: #ffffff;
    border: 1px solid #917afd;
    box-sizing: border-box;
    border-radius: 16px;
    color: #917afd;
    font-size: 0.88rem;
    min-width: 75px;
    cursor: pointer;
    transition: 0.5s;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
        rgba(17, 17, 26, 0.1) 0px 0px 8px;
    &:hover {
        /* background-color: #f5f5f5; */
        /* transform: translateY(-0.5em); */
        box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
    }
`;
const ViewButton = styled.button`
    padding: 7px 18px;
    background: #ffffff;
    border: 1px solid #917afd;
    box-sizing: border-box;
    border-radius: 16px;
    color: #917afd;
    font-size: 0.85rem;
    min-width: 65px;
    cursor: pointer;
    transition: 0.5s;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
        rgba(17, 17, 26, 0.1) 0px 0px 8px;
    &:hover {
        /* background-color: #f5f5f5; */
        box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
    }
`;
