import React from "react";
import styled from "styled-components";

import star from "../../../assets/icons/star-icon.svg";

export default function FreeCourseCard({ item }) {
    return (
        <MainDiv>
            <TopPart key={item.id}>
                <ImageTag src={item.image} />
                <RatingDiv>
                    <RatingNum>4.6</RatingNum>
                    <Star src={star} alt="icon" />
                </RatingDiv>
            </TopPart>
            <BottomPart>
                <TechnologyText>Technology</TechnologyText>
                <SubjectTitleText>{item.title}</SubjectTitleText>
                <EnrollNowWrapper>
                    <EnrollNowText>Enroll Now</EnrollNowText>
                    <RightArrowText>&#8702;</RightArrowText>
                </EnrollNowWrapper>
            </BottomPart>
        </MainDiv>
    );
}

const MainDiv = styled.div`
    background: #fff;
    padding: 12px;
    border: 1px solid #e5e5e5;
    /* box-sizing: border-box; */
    user-select: none;
    border-radius: 10px;
    /* &:hover {
        background: #fdfcff;
        border: none;
    } */
`;
const TopPart = styled.div`
    position: relative;
    margin-bottom: 10px;
    border-radius: 6px;
    width: 100%;
    height: 140px;
    /* background-size: 100% 100%; */
    cursor: pointer;
`;
const ImageTag = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    object-fit: cover;
`;
const RatingDiv = styled.div`
    position: absolute;
    bottom: 10%;
    right: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 20px;
    padding: 5px 7px;
`;
const RatingNum = styled.h6`
    color: #000000;
    font-size: 0.72rem;
    margin-right: 5px;
`;
const Star = styled.img``;
const BottomPart = styled.div``;
const TechnologyText = styled.span`
    display: inline-block;
    font-size: 0.8rem;
    color: #9d9d9d;
    margin-bottom: 7px;
    text-transform: capitalize;
`;
const SubjectTitleText = styled.h4`
    font-size: 0.94rem;
    font-weight: 500;
    cursor: pointer;
    text-transform: capitalize;
`;
const EnrollNowWrapper = styled.div`
    margin-top: 22px;
    width: 95px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.5s;
    &:hover {
        /* box-shadow: 0 0.5em 0.5em -0.4em var(--hover); */
        transform: translateY(-0.05em);
    }
`;
const EnrollNowText = styled.h6`
    color: #1f0ddb;
    font-size: 0.85rem;
`;
const RightArrowText = styled.h6`
    color: #1f0ddb;
    font-size: 1.2rem;
    font-weight: 500;
    margin-left: 5px;
`;

// const MainContainer = styled.div``;
