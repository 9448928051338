import React, { useState, useContext } from "react";
import styled from "styled-components";

export default function PracticeMultipleOptions({
    options,
    selectedOption,
    setSelectedOption,
    toggleButton,
    setQuestionId,
    setPracticeType,
}) {
    setPracticeType(options.type);

    return (
        <MainContainer>
            {options?.choices?.map((data, index) => (
                <Container
                    onClick={() => {
                        toggleButton(data?.id);
                        setQuestionId(options?.id);
                    }}
                >
                    <CheckedBox
                        borderColor={
                            selectedOption?.find(
                                (element) => element == data?.id
                            ) == data.id
                                ? "#917afd"
                                : "#c1c1c1"
                        }
                    >
                        {selectedOption?.find(
                            (element) => element == data?.id
                        ) == data.id && (
                            <Checked
                                src={
                                    require("./../../../assets/icons/checked.svg")
                                        .default
                                }
                                alt="icon"
                            />
                        )}
                    </CheckedBox>
                    <QuestionText>{data.option}</QuestionText>
                </Container>
            ))}
        </MainContainer>
    );
}
const MainContainer = styled.div`
    margin-top: 40px;
`;
const Container = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;
`;
const CheckedBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    height: 20px;
    width: 20px;
    border: 2px solid;
    margin-right: 15px;
    border-color: ${(props) =>
        props.borderColor ? props.borderColor : " #c1c1c1"};
`;
const Checked = styled.img`
    // background: #917afd;
    height: 22px;
    width: 22px;
    // border-radius: 50%;
    // height: 100%;
    // width: 100%;
`;
const QuestionText = styled.p`
    color: #000;
    font-size: 16px;
`;
