import React, { useState, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { Context } from "./../../../contexts/Store";
import { baseConfig } from "./../../../axiosConfig";

export default function Login() {
	const [hide, setHide] = useState(true);
	const [username, setUsername] = useState("");
	const [enrolmentid, setEnrolmentid] = useState("");
	const history = useHistory();
	const { state } = useContext(Context);
	const { subjact_id } = useParams();

	const handleLogin = () => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.post(
				"/students/student-exam-attend/",
				{
					username: username,
					enrollment_no: enrolmentid,
					subject_id: subjact_id,
				},
				{
					headers: {
						Authorization: "Bearer " + accessToken,
					},
				}
			)
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					history.push(`/exam-instruction/${data.data}`);
				}
			})
			.catch((error) => {
				alert(error?.response?.data?.data?.message);
			});
	};

	const handleEnter = (event) => {
		if (event.key.toLowerCase() === "enter") {
			const form = event.target.form;
			const index = [...form].indexOf(event.target);
			form.elements[index + 1].focus();
			event.preventDefault();
		}
	};

	return (
		<MainContainer>
			<CardContainer>
				<form>
					<TopContainer>
						<LogoContainer>
							<Logo
								src={
									require("../../../assets/images/logos.png")
										.default
								}
							/>
						</LogoContainer>
						<TextContainer>
							<Heading>PROCEED TO ONLINE EXAM</Heading>
						</TextContainer>
					</TopContainer>
					<BottomContainer>
						<div>
							<UserText>Username</UserText>

							<InputContainer>
								<InputFields
									autoFocus
									style={{ paddingRight: 15 }}
									placeholder="Enter Username"
									onChange={(e) => {
										setUsername(e.target.value);
									}}
									onKeyDown={handleEnter}
									value={username}
								/>
							</InputContainer>
							<UserText>Enrollment No</UserText>

							<InputContainer>
								<InputFields
									style={{ paddingRight: 15 }}
									placeholder="Enter Enrollment No"
									onChange={(e) => {
										setEnrolmentid(e.target.value);
									}}
									onKeyDown={(e) => {
										if (e.key.toLowerCase() === "enter") {
											e.preventDefault();
											if (username === "") {
												alert("Enter username");
											} else if (enrolmentid === "") {
												alert(
													"Enter enrollment number"
												);
											} else {
												handleLogin();
											}
										}
									}}
									value={enrolmentid}
								/>
							</InputContainer>
						</div>
						<Button
							onClick={(e) => {
								e.preventDefault();
								if (username === "") {
									alert("Enter username");
								} else if (enrolmentid === "") {
									alert("Enter enrollment number");
								} else {
									handleLogin();
								}
							}}
						>
							<Buttontext>Enter to Exam</Buttontext>
						</Button>
					</BottomContainer>
				</form>
			</CardContainer>
		</MainContainer>
	);
}

const MainContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
`;
const CardContainer = styled.div`
	// width: 40%;
	align-items: center;
	border: 1px solid #e5e5e5;
	box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
	border-radius: 24px;
	padding: 60px;
	display: flex;
	flex-direction: column;
	// background: red;
	@media (max-width: 768px) {
		padding: 30px;
	}
	@media (max-width: 640px) {
		border: none;
		box-shadow: none;
	}
`;
const TopContainer = styled.div``;
const LogoContainer = styled.div`
	width: 55%;
	margin: 0 auto;
	@media (max-width: 768px) {
		width: 50%;
	}
`;
const Logo = styled.img`
	display: block;
	width: 100%;
`;
const TextContainer = styled.div``;
const Heading = styled.h3`
	font-family: "inter-medium";
	text-align: center;
	margin-top: 56px;
	font-size: 18px;
	color: #917afd;
	@media (max-width: 768px) {
		font-size: 17px;
	}
`;
const BottomContainer = styled.div`
	margin-top: 64px;
`;
const UserText = styled.h5`
	margin-bottom: 8px;
	font-family: "inter-medium";
	font-size: 17px;
	@media (max-width: 768px) {
		font-size: 16px;
	}
`;
const Button = styled.button`
	padding: 30px;
	background: #917afd;
	border-radius: 12px;
	height: 60px;
	width: 100%;
	border: 1px solid #917afd;
	margin-top: 5px;
	margin: 0 auto;
	display: flex;
	align-items: center;
	cursor: pointer;
`;
const Buttontext = styled.h5`
	color: #fff;
	font-size: 17px;
	text-align: center;
	margin: 0 auto;
	font-family: "inter-semibold";
`;

const InputContainer = styled.div`
	border-radius: 12px;
	height: 60px;
	display: flex;
	border: 1px solid #efefef;
	justify-content: space-between;
	align-items: center;
	color: #000;
	background: #efefef;
	font-size: 17px;
	width: -webkit-fill-available;
	width: -moz-available;
	margin-bottom: 15px;
	&:focus-within {
		border-color: #917afd;
		background: #fff;
	}
`;
const InputFields = styled.input`
	width: 100%;
	color: #000;
	font-size: 16px;
	padding-left: 15px;
	font-family: "inter-regular";
	caret-color: #006f91;
	@media (max-width: 768px) {
		font-size: 15px;
	}
`;
