import React, { useState, useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import { Context } from "./../../../contexts/Store";
import { baseConfig } from "./../../../axiosConfig";
import Loader from "../../includes/loader/Loader";
import ProgramCard from "../../includes/programsCommonComponents/ProgramCard";

// import "./Dashboard.css";
import { Link } from "react-router-dom";
import DashboardActivityCard from "./../../includes/dashboardCommonComponents/DashboardActivityCard";
import Calendar from "react-calendar";
import StudentInfoCard from "../../includes/dashboard/StudentInfoCard";
import DashboardTimeline from "../../includes/dashboard/DashboardTimeline";
import StudyStatusCard from "../../includes/dashboard/StudyStatusCard";
import DonutGraph from "../../includes/dashboard/DonutGraph";
import CurrentCourseCard from "../../includes/dashboard/CurrentCourseCard";
import ContinueCourseCard from "../../includes/dashboard/ContinueCourseCard";
import RecommendedCourseCard from "../../includes/dashboard/RecommendedCourseCard";
import ActivityCard from "../../includes/dashboard/ActivityCard";
import welcomeBoxIcon from "../../../assets/images/welcome-box-icon.svg";
import calendarIcon from "../../../assets/icons/calender.svg";

//empty page
import EmptyPage from "../../includes/emptyPages/EmptyPage";
import SmallEmptyPage from "../../includes/emptyPages/SmallEmptyPage";

//empty svg

import NoActivity from "../../../assets/icons/empty-activities.svg";
import NoTimeline from "../../../assets/icons/no-timeline-new.svg";
import NoFreeCourse from "../../../assets/icons/empty-free-courses.svg";
import { TopUpContext } from "../../../contexts/TopUpContext/TopUpStore";

// material ui
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { updateTopUpProgram } from "../../includes/functions/TopUpFunctions";

export default function Dashboard({ student }) {
	const [timeline, setTimeline] = useState([]);
	const [activities, setActivities] = useState([]);
	const [subjects, setSubjects] = useState([]);
	const [dashboardData, setDashboardData] = useState({});
	const [continueSubjects, setContinueSubjects] = useState([]);
	const [currentCourse, setCurrentCourse] = useState({});
	const [isLoading, setLoading] = useState(true);
	const { state } = useContext(Context);
	const history = useHistory();
	const { topUpState, topUpDispatch } = useContext(TopUpContext);

	const [selectedPeriod, setSelectedPeriod] = useState("");

	const dashboardContents = () => {
		// setLoading(true);
		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`/students/student-dashboard/`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;

				if (StatusCode === 6000) {
					if (data) {
						setTimeline(data?.time_line);
						setDashboardData(data);
						setCurrentCourse(data?.first_subject);

						if (data.continue_subjects) {
							setContinueSubjects(data.continue_subjects.slice(0, 3));
						}
						setActivities(data.student_activities.slice(0, 3));
						setTimeout(() => {
							setLoading(false);
						}, 500);
						data.first_subject === null && data.continue_subjects.length <= 0 && getSubjects();
					}
				} else {
					setTimeout(() => {
						// setLoading(false);
						alert("It is taking longer than usual");
					}, 500);
				}
			})
			.catch((error) => {
				// history.push("/error/");
			});
	};

	const getSubjects = () => {
		// setLoading(true);
		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`/students/student-subjects/`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;

				if (StatusCode === 6000) {
					setSubjects(data.data.slice(0, 2));
					setTimeout(() => {
						// setLoading(false);
					}, 200);
				} else {
					setTimeout(() => {
						// setLoading(false);
					}, 200);
				}
			})
			.catch((error) => {});
	};

	useEffect(() => {
		dashboardContents();
		return () => {
			setLoading(false);
		};
	}, []);

	const onBackButtonEvent = (e) => {
		e.preventDefault();
		history.push("/");
	};

	useEffect(() => {
		window.history.pushState(null, null, window.location.pathname);
		window.addEventListener("popstate", onBackButtonEvent);
		return () => {
			window.removeEventListener("popstate", onBackButtonEvent);
		};
	}, []);

	const dropDownStyles = {
		height: 40,
		// width: 300,
		color: "#404040",
		background: "#FBFBFB",
		".MuiOutlinedInput-notchedOutline": {
			border: "1px solid #E5E5E5",
		},
		"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
			border: "1px solid #E5E5E5",
		},
		"&:hover .MuiOutlinedInput-notchedOutline": {
			border: "1px solid #E5E5E5",
		},
		".MuiSvgIcon-root ": {
			fill: "#E5E5E5 !important",
		},
	};

	return (
		<MainContainer>
			{!isLoading ? (
				<>
					<DashboardContentsSection>
						<DashboardContentLeft>
							<SectionTitle>Dashboard</SectionTitle>
							<WelcomeBox>
								<div>
									<WelcomeText>Welcome</WelcomeText>
									<StudentNameText>
										{/* {student?.name?.split(" ")[0]} */}
										{student?.name?.split(" ")[0]}
									</StudentNameText>
								</div>
								<WelcomeMessage>
									Your education is your power. So, Learn, Write, Achieve, and Succeed
								</WelcomeMessage>
								<div style={{ width: "180px" }}></div>
								<IconDiv>
									<WelcomeIcon src={welcomeBoxIcon} alt="image" />
								</IconDiv>
							</WelcomeBox>
							<StudyStatusBox>
								<StudyStatusCard
									title="Learning Progress"
									percentage={dashboardData?.learning_progress}
								/>
								<StudyStatusCard
									title="Assignment Completed"
									percentage={dashboardData?.assignment_progress?.progress}
									isDecimal={true}
									decimal={dashboardData?.assignment_progress?.subjects}
								/>
								{/* <StudyStatusCard
                            title="Exams Completed"
                            percentage={60}
                        /> */}
								<StudyStatusCard title="Paid EMI" percentage={dashboardData?.paid_emi_progress} />
								<StudyStatusCard
									title="Module Completed"
									percentage={dashboardData?.module_completed?.progress}
									isDecimal={true}
									decimal={dashboardData?.module_completed?.subjects}
								/>
							</StudyStatusBox>
							<DashboardMiddle>
								<>
									{currentCourse !== null && (
										<DashboardMiddleLeftBox>
											<DashboardSectionTitleBox>
												<DashboardSectionTitle>Current Topic</DashboardSectionTitle>
											</DashboardSectionTitleBox>
											<DashboardVerticalBox>
												<CurrentCourseCard data={currentCourse} />
											</DashboardVerticalBox>
										</DashboardMiddleLeftBox>
									)}
									{continueSubjects.length > 0 && (
										<DashboardMiddleRightBox
											noCurrentSection={currentCourse === null ? true : false}>
											<DashboardSectionTitleBox>
												<DashboardSectionTitle>
													{currentCourse === null ? "Start Studying" : "Continue Subjects"}
												</DashboardSectionTitle>
												<DashboardSectionLink to="/program/">All Subjects</DashboardSectionLink>
											</DashboardSectionTitleBox>{" "}
											<DashboardVerticalBox
												noCurrentSection={currentCourse === null ? true : false}>
												{continueSubjects.map((continueSubject, i) => (
													<ContinueCourseCard
														key={i}
														data={continueSubject}
														noCurrentSection={currentCourse === null ? true : false}
													/>
												))}
											</DashboardVerticalBox>
										</DashboardMiddleRightBox>
									)}
									{/* {continueSubjects.length <= 0 &&
                                        currentCourse ===
                                            null(
                                                <ViewSubjectsContainer>
                                                    <DashboardSectionTitleBox>
                                                        <DashboardSectionTitle>
                                                            Start Studying
                                                        </DashboardSectionTitle>
                                                        <DashboardSectionLink to="/program/">
                                                            All Subjects
                                                        </DashboardSectionLink>
                                                    </DashboardSectionTitleBox>
                                                    <ViewSubjects>
                                                        {subjects.map(
                                                            (item) => (
                                                                <ProgramCard
                                                                    key={
                                                                        item.id
                                                                    }
                                                                    data={item}
                                                                />
                                                            )
                                                        )}
                                                    </ViewSubjects>
                                                </ViewSubjectsContainer>
                                            )} */}
								</>
							</DashboardMiddle>
							<DashboardBottom>
								<FreeCourseContainer>
									<>
										{dashboardData?.latest_cohort?.status ? (
											<>
												<DashboardSectionTitleBox>
													<DashboardSectionTitle>Latest Cohort </DashboardSectionTitle>
													{/* <DashboardSectionLink>
														View all
													</DashboardSectionLink> */}
												</DashboardSectionTitleBox>
												<ClassRoomContainer>
													<ClassRoomRow>
														<ClassRoomHeading>Module</ClassRoomHeading>
														<ClassRoomText>
															{dashboardData?.latest_cohort?.subject_name}
														</ClassRoomText>
													</ClassRoomRow>
													<ClassRoomRow>
														<ClassRoomHeading>Faculty</ClassRoomHeading>
														<ImageFlexDiv>
															<ClassRoomIcon
																src={dashboardData?.latest_cohort?.faculty_image}
																alt="img"
															/>
															<ClassRoomText margin="0 0 -3px 5px">
																{dashboardData?.latest_cohort?.faculty_name}
															</ClassRoomText>
														</ImageFlexDiv>
													</ClassRoomRow>
													<ClassRoomRow>
														<ClassRoomHeading>Participants</ClassRoomHeading>
														<ParticipantsImageDiv>
															{dashboardData?.latest_cohort?.class_mates?.data.map(
																(item, i) => (
																	<ClassRoomIcon
																		src={item.image}
																		alt="img"
																		key={i}
																		className="participant"
																		style={{
																			zIndex: (i + 1) * 5,
																			marginLeft: (i + 1) * 20,
																		}}
																	/>
																)
															)}
															<ParticipantsCountDiv
																style={{
																	marginLeft:
																		(dashboardData?.latest_cohort?.class_mates?.data
																			?.length +
																			1) *
																		20,
																}}>
																<ParticipantsCount>
																	{dashboardData?.latest_cohort?.class_mates?.count}+
																</ParticipantsCount>
															</ParticipantsCountDiv>
														</ParticipantsImageDiv>
													</ClassRoomRow>
													<ClassRoomBottomDiv>
														<ImageFlexDiv>
															<CalenderIcon src={calendarIcon} alt="icon" />
															<ClassRoomHeading margin="0 0 -3px 5px">
																{dashboardData?.latest_cohort?.date}
															</ClassRoomHeading>
														</ImageFlexDiv>
														<ClassRoomJoinButton
															// onClick={() => {
															// 	history.push(
															// 		`classroom?c=${dashboardData?.latest_cohort?.id}`
															// 	);
															// }}
															to={`classroom?c=${dashboardData?.latest_cohort?.id}`}>
															Join Now
														</ClassRoomJoinButton>
													</ClassRoomBottomDiv>
												</ClassRoomContainer>
											</>
										) : (
											<>
												<DashboardSectionTitleBox>
													<DashboardSectionTitle>
														{/* Recommended Courses */}
														Free Courses
													</DashboardSectionTitle>
													{/* <DashboardSectionLink>
											View all
										</DashboardSectionLink> */}
												</DashboardSectionTitleBox>

												{/* <DashboardHorizontalBox>
										<RecommendedCourseCard />
										<RecommendedCourseCard />
									</DashboardHorizontalBox> */}
												<ActivityEmptyContainer>
													<SmallEmptyPage
														img={NoFreeCourse}
														// message="Pretium nam malesuada adipiscing elementum habit."
														title="No free courses available"
													/>
												</ActivityEmptyContainer>
											</>
										)}
									</>
								</FreeCourseContainer>
								<ActivityContainer>
									<DashboardSectionTitleBox>
										<DashboardSectionTitle>Activity</DashboardSectionTitle>
										{/* <DashboardSectionLink>
                                                View all
                                            </DashboardSectionLink> */}
									</DashboardSectionTitleBox>
									{activities.length > 0 ? (
										<DashboardBottomLeft>
											<DashboardVerticalBox>
												<ActivityBox>
													{activities.map((activity, ind) => (
														<ActivityCard key={ind} data={activity} />
													))}
												</ActivityBox>
											</DashboardVerticalBox>
										</DashboardBottomLeft>
									) : (
										<ActivityEmptyContainer>
											<SmallEmptyPage
												img={NoActivity}
												// message="Pretium nam malesuada adipiscing elementum habit."
												title="Stay Updated: No Activity at the Moment"
											/>
										</ActivityEmptyContainer>
									)}
								</ActivityContainer>
							</DashboardBottom>
						</DashboardContentLeft>
						<DashboardContentRight>
							<StudentInfoCard student={student} />
							<TopUpDiv>
								<TopUpTitle>Program and University</TopUpTitle>
								{topUpState?.top_up_details?.data.map((item, i) => (
									<ProgramDiv key={i}>
										<Top
											className={item.switched_program ? "selected" : ""}
											onClick={() => {
												if (!item.switched_program) {
													updateTopUpProgram(state, topUpDispatch, item.id, null, history);
												}
											}}>
											<RadioDiv>{item.switched_program && <InnerRound />}</RadioDiv>
											<TopUpName>
												{item.course_name} - {item.university_name}
											</TopUpName>
										</Top>

										{item?.periods?.length > 0 && item.switched_program && (
											<DropDownDiv>
												<Box>
													<FormControl fullWidth>
														<Select
															defaultValue=""
															id="demo-simple-select"
															onChange={(e) => {
																updateTopUpProgram(
																	state,
																	topUpDispatch,
																	item.id,
																	e.target.value,
																	history
																);
															}}
															sx={dropDownStyles}
															value={topUpState.top_up_details.selected_period.period}>
															{item.periods.map((data) => (
																<MenuItem key={data.period} value={data.period}>
																	{item.semester_oriented ? `Sem` : `Year`}{" "}
																	{data.period}
																</MenuItem>
															))}
														</Select>
													</FormControl>
												</Box>
											</DropDownDiv>
										)}
									</ProgramDiv>
								))}
							</TopUpDiv>
							<CalenderBox>
								<Calendar />
							</CalenderBox>
							<TimeLineDiv>
								{timeline.length > 0 ? (
									<DashboardTimeline timeline={timeline} />
								) : (
									<TimeLineEmptyContainer>
										<SmallEmptyPage
											img={NoTimeline}
											message="Stay Updated: No Timeline at the Moment."
											title="No Timelines"
										/>
									</TimeLineEmptyContainer>
								)}
							</TimeLineDiv>
						</DashboardContentRight>
					</DashboardContentsSection>
				</>
			) : (
				<Loader />
			)}
		</MainContainer>
	);
}

const MainContainer = styled.div`
	background: #ffffff;
	padding: 20px 15px;
	box-sizing: border-box;
	border: 1px solid #e5e5e5;
	border-radius: 10px;
	height: calc(100vh - 110px);
	overflow-y: scroll;
`;
const SectionTitle = styled.h3`
	font-size: 1.3rem;
	font-weight: 500;
	color: #000000;
	margin-bottom: 20px;
	@media (max-width: 980px) {
		font-size: 1.2rem;
	}
`;
const DashboardContentsSection = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
`;
const DashboardContentLeft = styled.div`
	width: 68%;
	/* border-radius: 6px; */
	padding-right: 10px;
	height: calc(100vh - 150px);
	overflow-y: scroll;
	/* border-right: 1px solid #e5e5e5; */
	&::-webkit-scrollbar {
		width: 0px;
	}
	&::-webkit-scrollbar-track {
		background-color: transparent;
	}
	::-webkit-scrollbar-thumb {
		background-color: #d6dee1;
		border-radius: 20px;
	}
	@media (max-width: 980px) {
		width: calc(100% - 285px);
	}
	@media (max-width: 860px) {
		width: calc(100% - 265px);
	}
	@media (max-width: 800px) {
		width: calc(100% - 250px);
	}
`;
const WelcomeBox = styled.div`
	/* position: relative; */
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: #9a4bcb;
	border-radius: 10px;
	width: 100%;
	height: 100px;
	padding: 20px;
	margin-bottom: 25px;
	box-shadow: 0px 11px 36px -10px rgba(154, 75, 203, 0.17);
	box-sizing: border-box;
	@media (max-width: 980px) {
		padding: 15px;
	}
`;
const StudyStatusBox = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
`;
const WelcomeText = styled.p`
	font-size: 1rem;
	font-weight: 400, regular;
	color: #fff;
	@media (max-width: 980px) {
		font-size: 0.9rem;
	}
`;
const StudentNameText = styled.h3`
	font-size: 1.5rem;
	/* font-weight: 600; */
	font-family: inter-semibold;
	color: #fff;
	@media (max-width: 980px) {
		font-size: 1.3rem;
	}
`;
const DashboardMiddle = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	margin-top: 2.5rem;
	padding-bottom: 5px;
`;
const DashboardMiddleLeftBox = styled.div`
	width: 59%;
`;
const DashboardVerticalBox = styled.div`
	display: ${(props) => (props.noCurrentSection ? "grid" : "block")};
	grid-template-columns: ${(props) => (props.noCurrentSection ? "1fr 1fr 1fr" : null)};
	grid-gap: ${(props) => (props.noCurrentSection ? "10px" : null)};
	/* overflow-x: ${(props) => (props.noCurrentSection ? "scroll" : "auto")}; */
	overflow-x: scroll;
	padding: 3px;

	&::-webkit-scrollbar {
		display: none;
	}
	&::-webkit-scrollbar-thumb {
		display: none;
	}
`;
const DashboardHorizontalBox = styled.div`
	display: flex;
	justify-content: space-between;
`;
const FreeCourseContainer = styled.div`
	width: 39%;
`;
const DashboardSectionTitleBox = styled.div`
	width: 100%;
	margin-bottom: 18px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const ClassRoomContainer = styled.div`
	border: 1px solid #e5e5e5;
	border-radius: 16px;
	padding: 16px;
`;
const ClassRoomRow = styled.div`
	margin-bottom: 10px;
`;
const ClassRoomHeading = styled.h4`
	color: #9d9d9d;
	font-size: 14px;
	/* margin-bottom: 3px; */
	margin: ${(props) => (props.margin ? props.margin : " 0 0 3px 0")};
`;
const ClassRoomText = styled.h4`
	font-size: 14px;
	color: #404040;
	width: 70%;
	margin: ${(props) => (props.margin ? props.margin : 0)};
`;
const ImageFlexDiv = styled.div`
	display: flex;
	align-items: center;
`;

const ParticipantsImageDiv = styled.div`
	width: 100%;
	height: 20px;
	position: relative;
	left: -20px;
	z-index: 1;
`;
const ParticipantsCountDiv = styled.div`
	border: 2px solid #ffffff;
	height: 32px;
	width: 32px;
	border-radius: 50%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 50;
	background: #917afd;
`;
const ParticipantsCount = styled.h6`
	color: #fff;
	text-align: center;
	font-size: 12px;
	margin-top: 5px;
`;
const ClassRoomIcon = styled.img`
	height: 32px;
	width: 32px;
	border-radius: 50%;
	:first-child {
		/* left: 0 !important; */
	}

	&.participant {
		/* position: absolute; */

		border: 2px solid #ffffff;
		height: 32px;
		width: 32px;
		position: absolute;
		top: 0;
		left: 0;
	}
`;
const DashboardSectionTitle = styled.h4`
	font-weight: 500;
	font-size: 18px;
	color: #000000;
`;
const ClassRoomBottomDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const CalenderIcon = styled.img`
	height: 18px;
	width: 18px;
`;
const ClassRoomJoinButton = styled(Link)`
	background: #917afd;
	border-radius: 4px;
	color: #fff;
	font-size: 14px;
	height: 35px;
	padding: 0 8px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const DashboardSectionLink = styled(Link)`
	font-weight: 500;
	font-size: 13px;
	line-height: 16px;
	color: #917afd;
	justify-content: space-between;
	transition: 0.3s;
	&:hover {
		text-decoration: underline;
	}
`;
const DashboardMiddleRightBox = styled.div`
	width: ${(props) => (props.noCurrentSection ? "100%" : "39%")};
`;
const DashboardBottom = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-top: 20px;
`;
const DashboardBottomLeft = styled.div`
	width: 100%;
`;
const ActivityContainer = styled.div`
	width: 59%;
`;
const CurrentActivitySection = styled.div``;
const CurrentActivityText = styled.h4`
	font-size: 1.1rem;
	margin-bottom: 15px;
	@media (max-width: 980px) {
		font-size: 1rem;
	}
`;
const CurrentActivitiesContentsBox = styled.div`
	padding: 16px 15px;
	border: 1px solid #e5e5e5;
	border-radius: 10px;
	box-sizing: border-box;
	scroll-behavior: smooth;
`;
const ActivitySection = styled.div`
	margin-top: 25px;
	&:first-child {
		margin-top: 0;
	}
`;
const ActivitySubTitleText = styled.h4`
	font-size: 1.1rem;
	margin-bottom: 15px;
	@media (max-width: 980px) {
		font-size: 1rem;
	}
`;

const ActivityEmptyContainer = styled.div`
	border: 1px solid #e5e5e5;
	border-radius: 16px;
	/* width: 59%; */
	padding: 15px 0;
`;

const ActivityCardsContainer = styled.div``;
const DashboardContentRight = styled.div`
	width: 31%;
	padding: 16px 15px;
	margin-top: 40px;
	background: #ffffff;
	border: 1px solid #e5e5e5;
	box-sizing: border-box;
	border-radius: 10px;
	height: calc(100vh - 185px);
	overflow-y: scroll;
	@media (max-width: 980px) {
		width: 280px;
	}
	@media (max-width: 860px) {
		width: 260px;
	}
	@media (max-width: 800px) {
		width: 245px;
	}
`;
const TopUpDiv = styled.div`
	margin-bottom: 10px;
`;
const TopUpTitle = styled.h3`
	color: #9d9d9d;
	font-size: 14px;
`;
const ProgramDiv = styled.div`
	margin-top: 5px;
`;
const Top = styled.div`
	display: flex;
	align-items: center;
	padding: 10px;
	border: 1px solid #c1c1c1;
	border-radius: 8px;
	cursor: pointer;

	&.selected {
		border: 2px solid #917afd;
	}
`;
const RadioDiv = styled.div`
	width: 20px;
	height: 20px;
	border-radius: 50%;
	border: 1px solid #9d9d9d;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 10px;
`;
const InnerRound = styled.div`
	width: 12px;
	height: 12px;
	background-color: #917afd;
	border-radius: 50%;
`;
const TopUpName = styled.h4`
	font-size: 14px;
	color: #000;
	margin-bottom: -3px;
`;

const Bottom = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 8px;
`;
const BottomLeft = styled.h5`
	color: #404040;
	font-size: 13px;
`;

const BottomRight = styled.h5`
	color: #917afd;
	font-size: 13px;
	cursor: pointer;
`;
const DropDownDiv = styled.div`
	margin: 10px 0;
`;

const CalenderBox = styled.div`
	border-bottom: 1px solid #e5e5e5;
	padding-bottom: 10px;
	margin-bottom: 20px;
`;
const TimeLineDiv = styled.div`
	/* width: 59%; */
	/* padding: 15px 0; */
	/* margin-top: 15px; */
`;
const TimeLineEmptyContainer = styled.div`
	/* margin-top: 15px; */
`;
const ActivitiesText = styled.h5`
	font-size: 0.9rem;
	font-weight: 600;
	letter-spacing: 0.03rem;
	@media (max-width: 980px) {
		font-size: 0.85rem;
	}
`;
const ActivityBox = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;

	& div {
		position: relative;
	}
	& div::before {
		content: "";
		position: absolute;
		width: 8px;
		height: 8px;
		border-radius: 56px;
		border: 6px solid #92bf33;
		top: calc(50% - 10px);
		left: -9%;
	}
	& div::after {
		content: "";
		position: absolute;
		border: 1px dashed #92bf33;
		height: 100%;
		top: calc(50% + 5px);
		left: calc(-9% + 9px);
	}
	& div:last-child::after {
		display: none;
	}
`;
const ViewSubjectsContainer = styled.div`
	width: 100%;
`;
const ViewSubjects = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 15px;
	width: 100%;

	@media (max-width: 1500px) {
		grid-template-columns: 1fr 1fr;
	}
`;
const IconDiv = styled.div`
	/* position: absolute;
	right: 20px;
	top: -50%; */
`;
const WelcomeIcon = styled.img`
	height: 180px;
	width: 170px;
`;
const WelcomeMessage = styled.h6`
	letter-spacing: 0.2px;
	color: #ffffff;
	/* opacity: 0.9; */
	font-size: 14px;
	width: 300px;
	/* width: 500px; */
`;
