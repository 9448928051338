import React, { useState, useContext } from "react";
import styled from "styled-components";

export default function DescriptionQuestions({ setDescription ,description}) {
	return (
		<MainContainer>
			<Container>
				<TextArea
					placeholder="Write Here..."
					onChange={(e) => {
						setDescription(e.target.value);
					}}
					value={description}
					onPaste={(e) => {
						e.preventDefault();
						return false;
					}}
				/>
			</Container>
		</MainContainer>
	);
}

const MainContainer = styled.div`
	margin-top: 20px;
`;
const Container = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 20px;
`;
const TextArea = styled.textarea`
	border: 1px solid #c1c1c1;
	border-radius: 8px;
	padding: 10px;
	width: 100%;
	resize: vertical;
	color: #000;
	height: 130px;
	font-size: 16px;
	::placeholder {
		font-family: "inter-regular";
		color: #4d595d;
	}
	::-webkit-scrollbar {
		display: none;
	}

	::-webkit-scrollbar-track {
		display: none;
	}

	::-webkit-scrollbar-thumb {
		display: none;
	}
`;
