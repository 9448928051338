import React from "react";
import styled from "styled-components";
import DonutGraph from "./DonutGraph";

export default function StudyStatusCard({
    percentage,
    title,
    isDecimal = false,
    decimal,
}) {
    return (
        <Container>
            <Title>{title}</Title>
            <Graph>
                <DonutGraph
                    percentage={percentage}
                    isDecimal={isDecimal}
                    decimal={decimal}
                />
            </Graph>
        </Container>
    );
}

const Container = styled.div`
    padding: 15px;
    border: 1px solid #e7e7e7;
    box-shadow: 2px 1px 15px rgba(0, 0, 0, 0.25);
    // border: 1px solid #917afd;
    border-radius: 16px;
    width: 24%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.3s;
    /* box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.15); */

    gap: 1%;
    &:hover {
        // background: #f4edf9;
        border: 1px solid #917afd;
    }
`;
const Title = styled.p`
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #404040;
    width: 50%;
`;
const Graph = styled.div`
    width: 65px;
`;
