import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";

import searchIcon from "../../../assets/icons/search.svg";

export default function Search({
    input,
    setInput,
    search,
    setSearch,
    currentPage,
    setCurrentPage,
}) {
    const [searchModal, setSearchModal] = useState(false);
    const inputElement = useRef();

    const focusInput = () => {
        if (input_status) {
            setInput("");
        } else {
            inputElement.current.focus();
        }
    };

    const [input_status, setInputStatus] = useState(false);
    // const [input, setInput] = useState("");

    useEffect(() => {
        if (input === "") {
            setInputStatus(false);
        } else {
            setInputStatus(true);
        }
    }, [input]);

    return (
        <>
            <SearchContainer>
                <SearchInput
                    placeholder="Search for books "
                    type="text"
                    id="search"
                    ref={inputElement}
                    autoComplete="off"
                    onChange={(e) => {
                        if (currentPage != "1") {
                            setCurrentPage("1");
                        }
                        setSearch(e.target.value);
                    }}
                    value={search}
                />
                {/* <PrimaryButton text={"Search"} size={"small"} onClick={focusInput} /> */}
                <Button>
                    <Text>Search</Text>
                    <SIcon src={searchIcon} alt="icon" />
                </Button>
            </SearchContainer>
        </>
    );
}
const SearchContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f9f9f9;
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    border: 1px solid #e7e7e7;
    border-radius: 16px;
    border-width: 1px 1px 1px 1px;
    padding: 0px 9px 0px 12px;
    overflow: hidden;
    &:focus-within {
        background-color: #fff;
        /* border: 2px solid #000; */
    }
`;
const SearchInput = styled.input`
    font-family: "inter-regular";
    font-size: 14px;
    color: #000;
    width: 100%;
    height: 100%;
    margin-left: 12px;
`;
const Button = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #522f91;
    padding: 6px 14px;
    border-radius: 16px;
`;
const Text = styled.span`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #fff;
`;
const SIcon = styled.img`
    margin-left: 8px;
    display: block;
    width: 14px;
    height: 14px;
    object-fit: cover;
    filter: brightness(0) invert(1);
`;
