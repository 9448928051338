import React, { useState } from "react";
import styled from "styled-components";

// Importing Icons
import dropdown from "../../../../assets/icons/dropdown-icon.svg";
import dropdownUp from "../../../../assets/icons/dropdown-up-icon.svg";
import SubTopicCard from "./SubTopicCard";

export default function TopicCard({
	data,
	index,
	selectedTopic,
	setSelectedTopic,
	selectedContent,
	setSelectedContent,
	// setSingleContentId,
	getSingleContent,
}) {
	return (
		<>
			<TopicBox
				onClick={() => {
					selectedTopic === data.id
						? setSelectedTopic(null)
						: setSelectedTopic(data.id);
				}}
			>
				<LeftPart>
					{/* <Left>
                        <CompletedIcon src={topicCompleted} alt="icon" />
                    </Left> */}
					<Right>
						<LessonText>Topic {index + 1}</LessonText>
						<TitleText>{data?.name}</TitleText>
					</Right>
				</LeftPart>
				{data.contents.length > 0 &&
					(selectedTopic === data.id ? (
						<RightPart>
							<DropdownIcon src={dropdownUp} alt="icon" />
						</RightPart>
					) : (
						<RightPart>
							<DropdownIcon src={dropdown} alt="icon" />
						</RightPart>
					))}
			</TopicBox>
			{selectedTopic === data.id && (
				<SubTopicCardWrapper>
					{data.contents.map((subTopic) => (
						<SubTopicCard
							data={subTopic}
							key={subTopic.id}
							selectedContent={selectedContent}
							setSelectedContent={setSelectedContent}
							// setSingleContentId={setSingleContentId}
							getSingleContent={getSingleContent}
						/>
					))}
				</SubTopicCardWrapper>
			)}
		</>
	);
}

const TopicBox = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 5%;
	background: #f4f4f4;
	border: 1px solid #ededed;
	box-sizing: border-box;
	/* cursor: pointer; */
	user-select: none;
`;
const LeftPart = styled.div`
	display: flex;
	align-items: center;
`;
// const Left = styled.div``;
// const CompletedIcon = styled.img`
//     display: block;
//     width: 20px;
//     height: 20px;
//     object-fit: cover;
// `;
const Right = styled.div`
	/* margin-left: 8px; */
`;
const LessonText = styled.div`
	font-size: 0.7rem;
	color: #000;
	opacity: 0.6;
`;
const TitleText = styled.h6`
	font-size: 0.85rem;
	margin-top: 10px;
`;
const RightPart = styled.div`
	/* background: #fff; */
	display: flex;
	justify-content: center;
	align-items: center;
	width: 22px;
	height: 23px;
	border-radius: 50%;
	box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
		rgba(17, 17, 26, 0.1) 0px 0px 8px;
	cursor: pointer;
	&:hover {
		box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
	}
`;
const DropdownIcon = styled.img`
	margin-top: 1.5px;
	display: block;
	width: 80%;
	height: 75%;
	object-fit: cover;
	filter: invert(0.5);
`;
const SubTopicCardWrapper = styled.div`
	transition: 0.5s;
	animation: slideInBottom2 0.3s ease-in-out forwards;
	transform-origin: top center;
`;
