import React, { useState, useContext, useEffect } from "react";
import { Link, useLocation, useParams, useHistory } from "react-router-dom";
import styled from "styled-components";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import ProgressBar from "./../../includes/ProgressBar";
import RadioQuestions from "./RadioQuestions";
import MultipleOptionQuestions from "./MultipleOptionQuestions";
import DescriptionQuestions from "./DescriptionQuestions";
import InstructionModal from "./InstructionModal";
import SuccessModal from "./SuccessModal";
import { Context } from "./../../../contexts/Store";
import { baseConfig } from "./../../../axiosConfig";
import moment from "moment";

export default function Questions() {
	const [instructionModal, setInstructionModal] = useState(false);
	const [successModal, setSuccessModal] = useState(false);
	const { id } = useParams();
	// const location = useLocation();
	// const subjectData = location?.state?.subjectData;
	// const examId = location?.state?.examId;
	const { state } = useContext(Context);
	const [questionNumber, setQuestionNumber] = useState(1);
	const [isLoading, setLoading] = useState(true);
	const [isTimeBalance, setTimeBalance] = useState(false);
	//question radio
	const [selectedRadioButton, setSelectedRadioButton] = useState("");
	const [questionDetails, setQuestionDetails] = useState([]);
	const [questionId, setQuestionId] = useState("");
	const [allDetails, setAllDetails] = useState({});
	const history = useHistory();
	const [previousAnswers, setPreviousAnswers] = useState({});
	const [examLoading, setExamLoading] = useState(true);

	//Multiple Questions
	const [selectedMultipleButton, setSelectedMultipleButton] = useState([]);
	const [previousMultipleAnswer, setPreviousMultipleAnswer] = useState([]);
	const [multipleOptions, setMultipleOptions] = useState([
		{
			id: 1,
			option: "A. Around 1500",
		},
		{
			id: 2,
			option: "B. Around 1800",
		},
		{
			id: 3,
			option: "C. Around 1600",
		},
		{
			id: 4,
			option: "D. None of these",
		},
	]);

	const toggleButton = (data) => {
		selectedMultipleButton?.find((element) => element === data)
			? setSelectedMultipleButton(selectedMultipleButton?.filter((elem) => elem !== data))
			: setSelectedMultipleButton([...selectedMultipleButton, data]);
	};

	//Descriptive Questions
	const [description, setDescription] = useState("");

	//api
	const getQuestions = () => {
		setSuccessModal(false);
		let accessToken = state.user_details.access_token;
		// `exams/questions/${id}/`
		baseConfig
			.get(`students/student-exam-questions/${id}/`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setQuestionDetails(data);
					setTimeout(() => {
						setLoading(false);
					}, 300);
				} else {
				}
			})
			.catch((error) => {});
	};

	//students preveious answers
	const getPreviouseAnswers = () => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`exams/student-attended-question/${id}/${questionNumber}/`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				setPreviousMultipleAnswer([]);
				const { StatusCode, data } = response.data;

				if (StatusCode === 6000) {
					setQuestionId(data.id);
					if (data.question_data.question_type === "single_choice") {
						setSelectedRadioButton(data?.objective_answer[0]);
					} else if (data.question_data.question_type === "multi_choice") {
						if (data.objective_answer !== null) {
							setSelectedMultipleButton(data.objective_answer);
						}
					} else if (data.question_data.question_type === "descriptive_question") {
						if (data.answer !== null) {
							setDescription(data.answer);
						}
					}
				} else {
				}
			})
			.catch((error) => {});
	};

	const getExamDetails = () => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`exams/exam-attend-data/${id}/`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
				params: {
					exam_started: true,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setAllDetails(data);
					setTimeout(() => {
						setExamLoading(false);
					}, 300);
				} else {
				}
			})
			.catch((error) => {});
	};

	const handleSubmitAnswer = (value) => {
		let accessToken = state.user_details.access_token;
		let input = {
			question_id: questionId,
			descriptive_answer: description,
			single_answer: selectedRadioButton ? selectedRadioButton : null,
			multi_answers: selectedMultipleButton ? selectedMultipleButton : null,
		};
		baseConfig
			.post(`/students/question-answer-submit/${id}/`, input, {
				headers: {
					Authorization: "Bearer " + accessToken,
					Accept: "application/json",
					"Content-Type": "application/json",
				},
			})
			.then((response) => {
				const { StatusCode, reference_data } = response.data;
				if (StatusCode === 6000) {
					if (value === "lastQuestion") {
						setSuccessModal(true);
					} else if (value === "prevoiusQuestion") {
						setQuestionNumber(questionNumber - 1);
						setSelectedMultipleButton([]);
						setSelectedRadioButton("");
						setDescription("");
					} else {
						setQuestionNumber(questionNumber + 1);
						setSelectedMultipleButton([]);
						setSelectedRadioButton("");
						setDescription("");
					}
				} else {
				}
			})
			.catch((error) => {});
	};

	//exam completed
	const examCompleted = () => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`students/exam-submit/${id}/`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					history.push("/");
				} else {
				}
			})
			.catch((error) => {});
	};

	useEffect(() => {
		getQuestions();
		getExamDetails();
	}, []);

	useEffect(() => {
		getPreviouseAnswers();
	}, [questionNumber]);

	const children = ({ remainingTime }) => {
		const hours = Math.floor(remainingTime / 3600);
		const minutes = Math.floor((remainingTime % 3600) / 60);
		const seconds = remainingTime % 60;

		var HH = hours < 10 ? "0" + hours : hours;
		var MM = minutes < 10 ? "0" + minutes : minutes;
		var SS = seconds < 10 ? "0" + seconds : seconds;

		if (remainingTime === 0) {
			// setSuccessModal(true);
			setTimeBalance(true);
			return <div className="">Too late...</div>;
		}

		return (
			<TimerPackageDiv>
				<TimeDiv>
					{HH}:{MM}:{SS}
				</TimeDiv>
				<Remaining>Remaining</Remaining>
			</TimerPackageDiv>
		);
	};

	const [finishStatus, setfinishStatus] = useState(false);

	// const onBackButtonEvent = (e) => {
	// 	e.preventDefault();
	// 	if (!finishStatus) {
	// 		if (window.confirm("Do you want to go back ?")) {
	// 			setfinishStatus(true);
	// 			// your logic
	// 			// history.push("/");
	// 			examCompleted();
	// 		} else {
	// 			window.history.pushState(null, null, window.location.pathname);
	// 			setfinishStatus(false);
	// 		}
	// 	}
	// };

	// useEffect(() => {
	// 	window.history.pushState(null, null, window.location.pathname);
	// 	window.addEventListener("popstate", onBackButtonEvent);
	// 	return () => {
	// 		window.removeEventListener("popstate", onBackButtonEvent);
	// 	};
	// }, []);

	return (
		<MainContainer>
			{/* {!isLoading && ( */}
			<Container>
				{!examLoading && (
					<TopMainDiv>
						<TimerDiv>
							<TimeBox>
								<CountDownBox>
									<CountdownCircleTimer
										isPlaying
										duration={allDetails?.total_duration}
										initialRemainingTime={allDetails?.duration}
										colors={[["#9178f8"], ["#ff222d"], ["#ff222d"]]}
										size={130}
										strokeWidth={10}
										trailStrokeWidth={10}
										trailColor={"#e7e7e7"}>
										{children}
									</CountdownCircleTimer>
								</CountDownBox>
								<DetailsBox>
									<DateDiv>
										<Icon src={require("../../../assets/icons/calender.svg").default} alt="icon" />
										<DetailsText>
											{moment(allDetails.subject_data.date).format("DD-MMM-YYYY")}
										</DetailsText>
									</DateDiv>
									<DateDiv>
										<Icon src={require("../../../assets/icons/document.svg").default} alt="icon" />
										<DetailsText>{allDetails.exam_data.total_questions} Questions</DetailsText>
									</DateDiv>
									<DateDiv>
										<Icon src={require("../../../assets/icons/star.svg").default} alt="icon" />
										<DetailsText>{allDetails.exam_data.total_mark} Marks</DetailsText>
									</DateDiv>
									<InfoDiv
										onClick={() => {
											setInstructionModal(true);
										}}>
										<Icon src={require("../../../assets/icons/info.svg").default} alt="icon" />
										<DetailsText>Instructions</DetailsText>
									</InfoDiv>
								</DetailsBox>
							</TimeBox>
						</TimerDiv>
						<HeadingDiv>
							{/* <Logo src={require("../../../assets/images/singhania-logo.png").default} /> */}
							<Logo src={require("../../../assets/images/sunrise-university logo.png").default} />

							<TextDiv>
								<HeadingText>{allDetails.exam_name}</HeadingText>
								<HeadingText fontFamily="Bold">{allDetails.subject_data.subject_name}</HeadingText>
							</TextDiv>
						</HeadingDiv>
						<ProfileDiv>
							<ProfileBox>
								<ProfileImageDiv>
									<ProfileImage src={allDetails.student_data.image} />
								</ProfileImageDiv>
								<div>
									<StudentName>{allDetails.student_data.name}</StudentName>
								</div>
								<EnrollDiv>
									<EnrollTitle>Enrollment No:&nbsp;</EnrollTitle>
									<EnrollNumber>{allDetails.student_data.program_data.enrollment_no}</EnrollNumber>
								</EnrollDiv>
							</ProfileBox>
						</ProfileDiv>
					</TopMainDiv>
				)}
				<BottomContainer>
					{!isLoading && !examLoading && (
						<>
							{/* <QuestionNumberContainer>
								<TopSection>
									<QuestionHeadings>
										All Questions
									</QuestionHeadings>
									<QuestionStatusDiv>
										<QuestionStatus>
											<QuestionNumberDiv>
												<QuestionNumber>
													1
												</QuestionNumber>
											</QuestionNumberDiv>
											<QuestionStatusText>
												Not attended
											</QuestionStatusText>
										</QuestionStatus>
										<QuestionStatus>
											<QuestionNumberDiv>
												<QuestionNumber>
													1
												</QuestionNumber>
											</QuestionNumberDiv>
											<QuestionStatusText>
												Skipped
											</QuestionStatusText>
										</QuestionStatus>
										<QuestionStatus>
											<QuestionNumberDiv>
												<QuestionNumber>
													1
												</QuestionNumber>
											</QuestionNumberDiv>
											<QuestionStatusText>
												attended
											</QuestionStatusText>
										</QuestionStatus>
										<QuestionStatus>
											<QuestionNumberDiv>
												<QuestionNumber>
													1
												</QuestionNumber>
											</QuestionNumberDiv>
											<QuestionStatusText>
												Current Selection
											</QuestionStatusText>
										</QuestionStatus>
									</QuestionStatusDiv>
								</TopSection>
								<BottomSection></BottomSection>
							</QuestionNumberContainer> */}

							<QuestionContainer>
								<div>
									<ProgressDiv>
										<ProgressBar
											current={questionNumber}
											total={allDetails.exam_data.total_questions}
										/>
									</ProgressDiv>
									{questionDetails.map((data, index) => (
										<div key={index}>
											{questionNumber === data.question_no && (
												<QuestionDiv>
													<QuestionText>
														Q.{data.question_no} {data.question_data.question}
													</QuestionText>
													{data?.question_data?.image && (
														<QuestionImage src={data?.question_data?.image} alt="image" />
													)}
													{data?.question_data?.question_type === "single_choice" ? (
														<RadioQuestions
															options={data}
															selectedOption={selectedRadioButton}
															setSelectedOption={setSelectedRadioButton}
															setQuestionId={setQuestionId}
															previousAnswers={previousAnswers}
														/>
													) : data?.question_data?.question_type === "multi_choice" ? (
														<MultipleOptionQuestions
															options={data}
															selectedOption={selectedMultipleButton}
															setSelectedOption={setSelectedMultipleButton}
															toggleButton={toggleButton}
															setQuestionId={setQuestionId}
															previousAnswers={previousAnswers}
														/>
													) : data?.question_data?.question_type ===
													  "descriptive_question" ? (
														<DescriptionQuestions
															setDescription={setDescription}
															description={description}
														/>
													) : null}
												</QuestionDiv>
											)}
										</div>
									))}
								</div>

								<ButtonContainer>
									{questionNumber > 1 && (
										<PreviousButtonDiv
											onClick={() => {
												if (
													selectedMultipleButton?.length > 0 ||
													selectedRadioButton !== "" ||
													description !== ""
												) {
													handleSubmitAnswer("prevoiusQuestion");
												} else {
													setQuestionNumber(questionNumber - 1);
												}
											}}>
											<Previous src={require("./../../../assets/icons/left-arrow.svg").default} />
											<PreviousButtonText>Previous</PreviousButtonText>
										</PreviousButtonDiv>
									)}
									{allDetails?.exam_data?.total_questions !== questionNumber && (
										<NextButtonDiv
											onClick={() => {
												if (
													selectedMultipleButton?.length > 0 ||
													selectedRadioButton !== "" ||
													description !== ""
												) {
													handleSubmitAnswer();
												} else {
													setQuestionNumber(questionNumber + 1);
												}
											}}>
											<NextButtonText>Next</NextButtonText>
											<Next src={require("./../../../assets/icons/right-arrow.svg").default} />
										</NextButtonDiv>
									)}

									{allDetails?.exam_data?.total_questions === questionNumber && (
										<NextButtonDiv
											onClick={() => {
												// setSuccessModal(true);
												handleSubmitAnswer("lastQuestion");
											}}>
											<NextButtonText>Completed</NextButtonText>
											<Next src={require("./../../../assets/icons/submit.svg").default} />
										</NextButtonDiv>
									)}
								</ButtonContainer>
							</QuestionContainer>
						</>
					)}
				</BottomContainer>
			</Container>
			{/* )} */}
			{instructionModal && <InstructionModal setInstructionModal={setInstructionModal} />}
			{successModal && (
				<SuccessModal
					setSuccessModal={setSuccessModal}
					isTimeBalance={isTimeBalance}
					examCompleted={examCompleted}
				/>
			)}
		</MainContainer>
	);
}

const MainContainer = styled.div`
	// display: flex;
	// justify-content: center;
	// align-items: center;
	height: 100vh;
	background: #f9f9f9;
	// padding: 30px;
`;
const Container = styled.div`
	padding: 30px;
	// width: 100%;
`;
const TopMainDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
`;
const TimerDiv = styled.div`
	background: #fff;
	height: 180px;
	width: 23%;
	border-radius: 8px;
	padding: 15px;
	border: 1px solid #e5e5e5;
`;
const TimeBox = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	// padding: 10px;
`;
const CountDownBox = styled.div``;
const DetailsBox = styled.div``;
const DateDiv = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 5px;
`;
const Icon = styled.img``;

const InfoDiv = styled.div`
	display: flex;
	align-items: center;
	border: 1px solid #9178f8;
	height: 40px;
	border-radius: 8px;
	justify-content: center;
	padding: 0 15px;
	cursor: pointer;
`;

const DetailsText = styled.h4`
	font-size: 15px;
	margin-left: 5px;
`;
const HeadingDiv = styled.div`
	background: #fff;
	height: 180px;
	width: 52%;
	border-radius: 8px;
	padding: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	border: 1px solid #e5e5e5;
`;
const Logo = styled.img`
	display: block;
	width: 20%;
`;
const TextDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	/* margin-top: 30px; */
`;
const HeadingText = styled.h4`
	color: #000;
	font-size: 18px;
	margin-bottom: 10px;
	/* font-family: "inter-semibold"; */
	font-family: ${(props) => (props.fontFamily === "Bold" ? "inter-semibold" : "inter-regular")};
`;
const ProfileDiv = styled.div`
	background: #fff;
	height: 180px;
	width: 23%;
	border-radius: 8px;
	padding: 15px;
	border: 1px solid #e5e5e5;
`;
const ProfileBox = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
`;
const ProfileImageDiv = styled.div`
	height: 80px;
	width: 80px;
	margin-bottom: 10px;
`;
const ProfileImage = styled.img`
	height: 100%;
	width: 100%;
	display: inline-block;
	border-radius: 5px;
	box-sizing: border-box;
	box-shadow: 0px 9px 21px rgb(0 0 0 / 11%);
	border-radius: 10px;
`;
const StudentName = styled.h4`
	color: #000;
	font-size: 16px;
	margin-bottom: 10px;
`;
const EnrollDiv = styled.div`
	display: flex;
	align-items: center;
`;
const EnrollTitle = styled.h4`
	color: #999999;
	font-size: 16px;
`;
const EnrollNumber = styled.h4`
	color: #000;
	font-size: 16px;
`;
//  Timer Styles

const TimerPackageDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;
const TimeDiv = styled.div`
	font-size: 20px;
	font-family: inter-semibold;
	width: 80px;
	margin: 0 auto;
`;
const Remaining = styled.h4`
	text-align-items: center;
	font-size: 15px;
`;
//ends

const BottomContainer = styled.div`
	background: #fff;
	height: calc(100vh - 270px);
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	/* padding: 20px; */
	border: 1px solid #e5e5e5;
`;
const QuestionNumberContainer = styled.div`
	width: 24%;
	/* background-color: red; */
	height: 100%;
	border-right: 1px solid #e5e5e5;
	padding: 20px 0;
`;
const TopSection = styled.div``;
const QuestionHeadings = styled.h4`
	font-size: 16px;
	font-family: "inter-semibold";
`;
const QuestionStatusDiv = styled.div`
	display: flex;
	/* flex-wrap: wrap; */
`;
const QuestionStatus = styled.div`
	display: flex;
	align-items: center;
`;
const QuestionNumberDiv = styled.div`
	height: 30px;
	width: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	border: 2px solid;
	border-color: ${(props) => (props.border ? props.border : "#917afd")};
	/* background: #917afd; */
	background: ${(props) => (props.background ? props.background : "#fff")};
	margin-right: 10px;
`;
const QuestionNumber = styled.p`
	font-size: 15px;
	color: ${(props) => (props.color ? props.color : "#000")};
	margin-bottom: -5px;
	/* font-family: "inter-semibold"; */
`;
const QuestionStatusText = styled.p`
	color: #000;
	margin-bottom: -5px;
	font-size: 14px;
`;
const ProgressDiv = styled.div`
	margin-bottom: 25px;
`;
const QuestionContainer = styled.div`
	// background: red;
	height: 100%;
	width: 74%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	/* background-color: blue; */
	padding: 20px;
`;
const QuestionDiv = styled.div`
	/* margin-top: 20px; */
`;
const QuestionText = styled.p`
	color: #000;
	font-size: 16px;
`;
const QuestionImage = styled.img``;
const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;
const PreviousButtonDiv = styled.div`
	background-color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
	padding: 0 15px;
	border-radius: 8px;
	margin-right: 30px;
	border: 1px solid #9178f8;
	cursor: pointer;
`;
const PreviousButtonText = styled.p`
	color: #9178f8;
	font-size: 15px;
	margin-left: 15px;
	margin-bottom: -3px;
`;
const Previous = styled.img`
	color: #fff;
	font-size: 15px;
`;

const NextButtonDiv = styled.div`
	background-color: #9178f8;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
	padding: 0 15px;
	border-radius: 8px;
	border: 1px solid #9178f8;
	margin-right: 30px;
	cursor: pointer;
`;
const NextButtonText = styled.p`
	color: #fff;
	font-size: 15px;
	margin-right: 15px;
`;
const Next = styled.img`
	color: #fff;
	font-size: 15px;
`;
