import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import ProgressBar from "./../../includes/ProgressBar";
import cardBg from "../../../assets/images/program-card-bg.svg";
import defaultSubjectPic from "../../../assets/images/freecourse-item3.svg";

export default function ProgramCard({ data }) {
    let history = useHistory();

    return (
        <MainDiv onClick={() => history.push(`subject/${data.id}`)}>
            <TopPart>
                <ImageTag
                    src={data.image ? data.image : defaultSubjectPic}
                    alt="image"
                />
            </TopPart>
            <BottomPart>
                <SubjectTitleText>{data.name}</SubjectTitleText>
                <ProgressBar
                    current={data.progress}
                    total="100"
                    type="programCard"
                />
            </BottomPart>
        </MainDiv>
    );
}

const MainDiv = styled.div`
    background: #fff;
    padding: 12px;
    border: 1px solid #e5e5e5;
    /* box-sizing: border-box; */
    border-radius: 10px;
    transition: 0.3s;
    cursor: pointer;
    &:hover {
        /* box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
            rgba(0, 0, 0, 0.05) 0px 4px 6px -2px; */
        /* box-shadow: rgba(67, 71, 85, 0.5) 0px 0px 0.25em,
            rgba(90, 125, 188, 0.05) 0px 0.25em 1em; */
        box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
        transform: translateY(-0.05em);
        border: 1px solid #bbbbbb;
    }
`;
const TopPart = styled.div`
    /* background-image: url(${cardBg}); */
    margin-bottom: 10px;
    border-radius: 6px;
    width: 100%;
    height: 160px;
    /* background-size: 100% 100%; */
    cursor: pointer;
`;
const ImageTag = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    object-fit: cover;
`;
const BottomPart = styled.div``;
const SubjectTitleText = styled.h4`
    margin-bottom: 8px;
    cursor: pointer;
    text-transform: capitalize;
`;
// const MainDiv = styled.div``;
// const MainDiv = styled.div``;
// const MainDiv = styled.div``;
