import React from "react";
import styled from "styled-components";
import pic from "../../../assets/images/background.png";
import moment from "moment";

export default function UpcomingLiveCard({
    item,
    upcoming = true,
    setSinglePage,
    setCompletedLiveDetails,
}) {
    return (
        <>
            <MainDiv
                style={{
                    backgroundImage: `url(${item.image ? item.image : pic})`,
                }}
            >
                <ContentBox>
                    <TopPart>
                        {upcoming && item.status === "Live" && (
                            <LiveContainer>
                                <Bullet></Bullet>
                                <LiveText>Live</LiveText>
                            </LiveContainer>
                        )}

                        {upcoming ? (
                            <DurationText>
                                {moment(item.date).format("DD, dddd")},{" "}
                                {moment(item.start_time, "HH:mm:ss").format(
                                    "h:mm a"
                                )}
                            </DurationText>
                        ) : (
                            item.duration && (
                                <DurationText>{item.duration}</DurationText>
                            )
                        )}
                    </TopPart>
                    <BottomPart>
                        <LeftPart>
                            <Title>{item.subject_name}</Title>
                            <SubTitle>{item.title}</SubTitle>
                        </LeftPart>

                        <RightPart>
                            {upcoming ? (
                                <Button target="_blank" href={item.zoom_link}>
                                    Join
                                </Button>
                            ) : (
                                <Button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setSinglePage(true);
                                        setCompletedLiveDetails(item);
                                    }}
                                >
                                    View
                                </Button>
                            )}
                        </RightPart>
                    </BottomPart>
                </ContentBox>
            </MainDiv>
        </>
    );
}
const MainDiv = styled.div`
    height: 230px;
    /* width: 45%; */
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 16px;
    user-select: none;
`;
const ContentBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;
const TopPart = styled.div`
    display: flex;
    align-items: center;
    padding: 20px;
`;
const LiveContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 12px;
    background: #ffffff;
    border-radius: 16px;
    margin-right: 14px;
    height: 31px;
    box-sizing: border-box;
`;
const Bullet = styled.div`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #f00;
    animation: blinker 1s linear infinite;
`;
const LiveText = styled.h6`
    font-size: 0.85rem;
    margin-left: 8px;
    font-weight: 500;
    letter-spacing: 0.6px;
`;
const DurationText = styled.h6`
    letter-spacing: 0.2px;
    font-size: 1rem;
    color: #fff;
    /* text-shadow: 1px 0.2px #fff; */
    text-shadow: 1px 1px 1px #000, 1px 1px 2px black;
`;
const BottomPart = styled.div`
    background: linear-gradient(
        360deg,
        #000000 -27.23%,
        rgba(0, 0, 0, 0) 98.73%
    );
    border-radius: 0px 0px 16px 16px;
    /* height: 120px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
`;
const LeftPart = styled.div``;
const Title = styled.h6`
    font-size: 14px;
    color: #fff;
`;
const SubTitle = styled.h6`
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    line-height: 22px;
    /* margin-top: 8px; */
`;
const RightPart = styled.div``;
const Button = styled.a`
    /* display: inline-block; */
    padding: 2px 25px 0 25px;
    background: #917afd;
    border-radius: 24px;
    min-width: 85px;
    font-size: 0.84rem;
    font-weight: 600;
    color: #fff;
    height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
`;
