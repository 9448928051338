import React, { useContext, useEffect } from "react";
import { baseConfig } from "../../../axiosConfig";
import { Context } from "./../../../contexts/Store";
import { useHistory } from "react-router-dom";
import { TopUpContext } from "../../../contexts/TopUpContext/TopUpStore";

// import React from "react";

export const GetTopUpProgramConst = (state, topUpDispatch) => {
	let accessToken = state.user_details.access_token;
	baseConfig
		.get(`students/program-list/`, {
			headers: {
				Authorization: "Bearer " + accessToken,
			},
		})
		.then((response) => {
			const { StatusCode, data } = response.data;
			if (StatusCode === 6000) {
				if (data) {
					let foundObject = data.find(
						(obj) => obj.switched_program === true
					);

					let selectedPeriod = foundObject.periods.find(
						(item) => item.switched === true
					);

					let top_up_details = {
						data: data,
						selected_period: selectedPeriod ? selectedPeriod : {},
						selected_program: foundObject ? foundObject : {},
					};

					topUpDispatch({
						type: "UPDATE_TOPUP",
						top_up_details: top_up_details,
					});
				}
			} else {
			}
		})
		.catch((error) => {
			if (error?.response?.status == 500) {
				alert("Server error,Contact LMS");
			}
		});
};

export const updateTopUpProgram = (
	state,
	topUpDispatch,
	program_id,
	period,
	history
) => {
	let accessToken = state.user_details.access_token;

	let formData = new FormData();
	formData.append("program_id", program_id);
	if (period) {
		formData.append("period", period);
	}

	baseConfig
		.post(`students/switch-program/`, formData, {
			headers: {
				Authorization: "Bearer " + accessToken,
			},
		})
		.then((response) => {
			const { StatusCode, data } = response.data;
			if (StatusCode === 6000) {
				GetTopUpProgramConst(state, topUpDispatch);
				window.location.reload();
			} else {
			}
		})
		.catch((error) => {
			if (error?.response?.status == 500) {
				alert("Server error,Contact LMS");
			}
		});
};

// function UpdatedTopUpFunctions(OriginalComponent) {
// 	function NewComponent() {
// 		const { topUpState, topUpDispatch } = useContext(TopUpContext);
// 		const { state } = useContext(Context);
// 		let accessToken = state.user_details.access_token;

// 		useEffect(() => {
// 			baseConfig
// 				.get(`students/program-list/`, {
// 					headers: {
// 						Authorization: "Bearer " + accessToken,
// 					},
// 				})
// 				.then((response) => {
// 					const { StatusCode, data } = response.data;
// 					if (StatusCode === 6000) {
// 						if (data) {
// 							let foundObject = data.find(
// 								(obj) => obj.switched_program === true
// 							);

// 							let selectedPeriod = foundObject.periods.find(
// 								(item) => item.switched === true
// 							);

// 							let top_up_details = {
// 								data: data,
// 								selected_period: selectedPeriod
// 									? selectedPeriod
// 									: {},
// 								selected_program: foundObject
// 									? foundObject
// 									: {},
// 							};

// 							topUpDispatch({
// 								type: "UPDATE_TOPUP",
// 								top_up_details: top_up_details,
// 							});

// 						}
// 					} else {
// 					}
// 				})
// 				.catch((error) => {
// 					if (error?.response?.status == 500) {
// 						alert("Server error,Contact LMS");
// 					}
// 				});
// 		}, []);
// 		return <OriginalComponent />;
// 	}

// 	return NewComponent;
// }

// export default UpdatedTopUpFunctions;
