import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import book4 from "../../../assets/images/Sherlock.png";
import read from "../../../assets/icons/menu_book.svg";
import down from "../../../assets/icons/download_blue.svg";
import add from "../../../assets/icons/star.svg";
import close from "../../../assets/icons/close.png";
import arrow from "../../../assets/icons/arrow_.svg";
import loadingIcon from "../../../assets/lottie/loading-buffering.gif";

import Slider from "react-slick";

import SingleBookCard from "./SingleBookCard";

import { baseConfig } from "../../../axiosConfig";
import { Context } from "../../../contexts/Store";
import { useHistory } from "react-router-dom";

export default function BookModal({
    isModal,
    setIsModal,
    isSingleBookLoading,
    selectedBookId,
    selectedBookData,
    setSelectedBookId,
    handleFavourite,
    handleUnFavourite,
    relatedBooks,
    type,
}) {
    const { state } = useContext(Context);
    const history = useHistory();
    const [isLoading, setLoading] = useState(false);

    const [sliderRef, setSliderRef] = useState(null);

    // const sliderSettings = {
    //     autoplay: true,
    //     infinite: true,
    //     speed: 1000,
    //     centerMode: true,
    //     rows: 1,
    //     // removes default buttons
    //     arrows: true,
    //     slidesToShow: 3,
    //     slidesToScroll: 1,
    //     infinite: false,
    // };
    const sliderSettings = {
        autoplay: true,
        infinite: true,
        speed: 1000,
        className: "center",
        centerMode: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        adaptiveHeight: true,
    };
    // const CustomPrevArrow = (props) => {
    //     const { className, onClick } = props;
    //     return (
    //         <div className={className} onClick={onClick}>
    //             {/* Add your custom previous button content here */}
    //             <button style={{ background: "#000" }}>Previous</button>
    //         </div>
    //     );
    // };

    // const CustomNextArrow = (props) => {
    //     const { className, onClick } = props;
    //     return (
    //         <div className={className} onClick={onClick}>
    //             {/* Add your custom next button content here */}
    //             <button>Next</button>
    //         </div>
    //     );
    // };
    // const settings = {
    //     dots: false,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 3,
    //     slidesToScroll: 1,
    //     centerMode: true,
    //     centerPadding: "60px", // set the specific gap between items here
    //     prevArrow: <CustomPrevArrow />, // use a custom component for the previous button
    //     nextArrow: <CustomNextArrow />, // use a custom component for the next button
    // };

    return (
        <>
            <MainDiv className={isModal && "active"}>
                {!isSingleBookLoading ? (
                    <>
                        <CloseMe
                            onClick={() => {
                                setIsModal(false);
                            }}
                            src={close}
                            alt="Icon"
                        />
                        <BreefDiv>
                            <LeftContent>
                                <HeadTitle>{selectedBookData?.title}</HeadTitle>
                                <SubTitle>
                                    <By>by</By> {selectedBookData?.author}
                                </SubTitle>
                                <AboutTitle>About this book</AboutTitle>
                                <Explanation>
                                    {" "}
                                    {selectedBookData?.description}
                                </Explanation>
                            </LeftContent>
                            <RightContent>
                                {/* <BContainer> */}
                                {/* <BookContainer>
                                <BookImage
                                    src={selectedBookData?.resized_cover_photo}
                                    alt="Image"
                                />
                            </BookContainer> */}
                                <ImageContainer>
                                    <Image
                                        src={selectedBookData.cover_photo}
                                        alt="icon"
                                    />
                                </ImageContainer>
                                {/* </BContainer> */}
                                <ReadButton
                                    onClick={() =>
                                        history.push({
                                            pathname: `/library/readpdf/${selectedBookData.id}`,
                                        })
                                    }
                                >
                                    <RIcon src={read} /> Read now
                                </ReadButton>
                                <DownloadButton
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        window.open(
                                            selectedBookData.content_file,
                                            "_blank",
                                            "noreferrer"
                                        );
                                    }}
                                >
                                    <DIcon src={down} />
                                    Download
                                </DownloadButton>
                                <AddFav
                                    onClick={() => {
                                        handleFavourite(selectedBookData.id);
                                    }}
                                >
                                    {!selectedBookData.is_favourite && (
                                        <AddIcon src={add} />
                                    )}
                                    {selectedBookData.is_favourite
                                        ? `Remove from favourites`
                                        : `Add to favourites`}
                                </AddFav>
                            </RightContent>
                        </BreefDiv>

                        {relatedBooks.length > 0 && (
                            <RelatedSection>
                                <RelatedTitle>Related Books</RelatedTitle>

                                <SliderDiv>
                                    {relatedBooks.length > 3 && (
                                        <BackArrow
                                            onClick={sliderRef?.slickPrev}
                                        >
                                            <img
                                                style={{
                                                    transform: "rotate(180deg)",
                                                    width: "30px",
                                                }}
                                                src={
                                                    require("./../../../assets/icons/library-slick-arrow.svg")
                                                        .default
                                                }
                                                alt="Arrow"
                                            />
                                        </BackArrow>
                                    )}
                                    <Slider
                                        ref={setSliderRef}
                                        {...sliderSettings}
                                    >
                                        {relatedBooks.map((item,i) => (
                                            <div
                                                style={{ marginRight: "20px", }}
                                                key={i}
                                            >
                                                <SingleBookCard
                                                    type="inside-modal"
                                                    data={item}
                                                    key={item.id}
                                                    isModal={isModal}
                                                    setIsModal={setIsModal}
                                                    setSelectedBookId={
                                                        setSelectedBookId
                                                    }
                                                    selectedBookData={
                                                        selectedBookData?.content_file
                                                    }
                                                    handleFavourite={
                                                        handleFavourite
                                                    }
                                                />
                                            </div>
                                        ))}
                                    </Slider>
                                    {relatedBooks.length > 3 && (
                                        <NextArrow
                                            onClick={sliderRef?.slickNext}
                                        >
                                            <img
                                                style={{
                                                    width: "30px",
                                                }}
                                                src={
                                                    require("./../../../assets/icons/library-slick-arrow.svg")
                                                        .default
                                                }
                                                alt="Arrow"
                                            />
                                        </NextArrow>
                                    )}
                                </SliderDiv>
                            </RelatedSection>
                        )}
                    </>
                ) : (
                    <BooksLoaderContainer>
                        <LoaderIcon src={loadingIcon} alt="icon" />
                    </BooksLoaderContainer>
                )}
            </MainDiv>

            <Overlay
                className={isModal && "active"}
                onClick={() => {
                    setIsModal(false);
                }}
            />
        </>
    );
}

const MainDiv = styled.div`
    bottom: -900px;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: 1001;
    min-height: 80vh;
    padding: 40px 0px;
    border-radius: 24px 24px 0px 0px;
    background: #ffffff;
    max-height: 90vh;
    overflow-y: scroll;
    transition: all 0.5s ease 0s;
    &.active {
        bottom: 0;
    }
    box-sizing: border-box;
`;
const VerticalLine = styled.div``;
const Overlay = styled.div`
    bottom: 0;
    left: 0px;
    z-index: 100;
    position: fixed;
    min-height: 100vh;
    width: 100%;
    cursor: pointer;
    opacity: 0.5;
    background: #161616;
    display: none;
    &.active {
        display: block;
    }
`;
const CloseMe = styled.img`
    cursor: pointer;
    display: block;
    object-fit: contain;
    width: 14px;
    height: 14px;
    position: absolute;
    top: 23px;
    right: 30px;
`;
const BreefDiv = styled.div`
    /* padding: 64px 400px; */
    margin: 0px 15%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 1px solid #f5f5f5;
    padding-bottom: 40px;
`;
const LeftContent = styled.div`
    width: calc(100% - 250px);
    padding-right: 65px;
    height: 400px;
    overflow-y: scroll;
    /* border-right: 1px solid #f5f5f5; */
`;
const HeadTitle = styled.h3`
    color: #010101;
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    text-transform: capitalize;
`;
const SubTitle = styled.h4`
    display: flex;
    align-items: center;
    color: #404040;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    text-transform: capitalize;
    /* margin-top: 4px; */
`;
const By = styled.span`
    font-weight: 400;
    font-size: 16px;
    color: #404040;
    line-height: 32px;
    margin-right: 4px;
    text-transform: lowercase;
`;
const AboutTitle = styled.span`
    display: inline-block;
    margin-top: 24px;
    font-weight: 600;
    font-size: 18px;
    color: #404040;
    margin-bottom: 6px;
`;
const Explanation = styled.pre`
    /* margin-top: 16px;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #404040; */
    font-family: "inter-regular";
    white-space: pre-wrap;
    color: #404040;
    font-size: 14px;
    font-weight: 300;
    padding-right: 10px;
`;
const RightContent = styled.div`
    width: 250px;
    height: 400px;
    overflow-y: scroll;
    border-left: 1px solid #f5f5f5;
    padding-left: 30px;
`;
const BContainer = styled.div`
    background: rgba(145, 122, 253, 0.16);
    border-radius: 16px;
`;
const ImageContainer = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(145, 122, 253, 0.16);
    border-radius: 16px;
`;
const Image = styled.img`
    display: block;
    /* width: max-content; */
    width: 100%;
    max-height: 100%;
    height: auto;
    /* object-fit: fill; */
`;
const BookContainer = styled.div`
    margin: 0 au;
    margin: 0 48px 0;
    width: 151.88px;
    height: 231.52px;
`;
const BookImage = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const ReadButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    /* display: inline-block; */
    background: #917afd;
    padding: 10px 20px;
    border-radius: 6px;
    width: 100%;
    height: 44px;
    box-sizing: border-box;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    cursor: pointer;
    margin-top: 24px;
`;
const RIcon = styled.img`
    margin-right: 10px;
    width: 20px;
    height: 18px;
    display: block;
    object-fit: contain;
    filter: brightness(0) invert(1);
`;
const DIcon = styled.img`
    margin-right: 10px;
    width: 20px;
    height: 18px;
    display: block;
    object-fit: contain;
`;
const AddIcon = styled.img`
    margin-right: 8px;
    width: 20px;
    height: 19px;
    display: block;
    object-fit: contain;
`;

const DownloadButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 44px;
    box-sizing: border-box;
    padding: 10px 20px;
    border-radius: 6px;
    color: #917afd;
    border: 1px solid #917afd;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    margin-top: 6px;
    cursor: pointer;
`;
const AddFav = styled.button`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 44px;
    box-sizing: border-box;
    padding: 10px 20px;
    border-radius: 6px;
    border: 1px solid #917afd;
    color: #917afd;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    margin-top: 6px;
`;
const RelatedSection = styled.div`
    padding-top: 40px;
    margin: 0px 15%;
`;
const SliderDiv = styled.div`
    position: relative;
    margin-top: 20px;
    /* .slick-prev {
        left: 10%; 
        z-index: 1;
    }

    .slick-next {
        right: 10%; 
        z-index: 1;
    } */
    .slick-list {
        margin: 0 -10px;
    }
    .slick-slide {
        margin: 0 10px;
    }
`;
const IconPrev = styled.div`
    cursor: pointer;
    padding: 8px 13px;
    top: 900px;
    right: 460px;
    position: absolute;
    transform: rotate(180deg);
    border: 1px solid #917afd;
    background: #ffffff;
    border-radius: 30px;
    opacity: 0.6;
    z-index: 1000;
`;
const IconNext = styled.div`
    cursor: pointer;
    padding: 8px 13px;
    position: absolute;
    border: 1px solid #917afd;
    opacity: 0.6;
    background: #ffffff;
    border-radius: 30px;
    top: 900px;
    left: 377px;
    z-index: 1000;
`;
const IPrev = styled.img`
    display: block;
    width: 11px;
    height: 20px;
    object-fit: contain;
`;
const INext = styled.img`
    display: block;
    width: 11px;
    height: 20px;
    object-fit: contain;
`;

const RelatedTitle = styled.h3`
    font-size: 18px;
    font-weight: 600;
    color: #404040;
    line-height: 22px;
`;
const NextArrow = styled.div`
    position: absolute;
    right: -2.5%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 50;
    cursor: pointer;
    /* @media (max-width: 980px) {
        display: none;
    } */
`;
const BackArrow = styled.div`
    position: absolute;
    left: -2.5%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 50;
    cursor: pointer;
    /* @media (max-width: 980px) {
        display: none;
    } */
`;
const BooksLoaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* height: 100%; */
    height: 80vh;
`;
const LoaderIcon = styled.img`
    width: 40px;
    height: 40px;
    display: block;
`;
