import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import OndoingSingleCard from "./OndoingSingleCard";
import OngoingAssignment from "./OngoingAssignment";
import Lefticon from "../../../assets/icons/left-arrow.svg";
import { baseConfig } from "../../../axiosConfig";
import { Context } from "../../../contexts/Store";
import Loader from "../loader/Loader";
import EmptyPage from "../emptyPages/EmptyPage";
import emptyPic from "../../../assets/icons/live-class-empty-icon.svg";

export default function CRAssignment({ data, refreshApi }) {
	const [isSinglePage, setSinglePage] = useState(false);
	const [isLoading, setLoading] = useState(true);
	const [singleAssignment, setSingleAssignment] = useState({});
	const { state } = useContext(Context);
	const [selectedProgramStatus, setSelectedProgramStatus] = useState("ongoing");

	const tabCategories = [
		{
			id: 1,
			title: "Ongoing",
			value: "ongoing",
		},
		{
			id: 2,
			title: "Submitted",
			value: "submitted",
		},
		{
			id: 3,
			title: "Closed",
			value: "closed",
		},
		{
			id: 4,
			title: "Returned",
			value: "Returned",
		},
	];

	const getSingleAssignment = (id) => {
		setLoading(true);
		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`/students/student-assignment/${id}`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;

				if (StatusCode === 6000) {
					setSingleAssignment(data);
					setSinglePage(true);
					setTimeout(() => {
						setLoading(false);
					}, 200);
				} else {
					setTimeout(() => {
						setLoading(false);
						alert("It is taking longer than usual");
					}, 200);
				}
			})

			.catch((error) => {});
	};

	useEffect(() => {
		setSingleAssignment({});
		setSinglePage(false);
	}, [data]);

	return (
		<CardContainer>
			{!isSinglePage ? (
				<LivetitleSection>
					<Livetitle>Assignments</Livetitle>
					<TabSection>
						<ProgramButtonDiv>
							{tabCategories.map((data, i) => (
								<>
									<ProgramButton
										background={selectedProgramStatus === data.value ? "#fff" : "#F5F5F5"}
										boxShadow={
											selectedProgramStatus === data.value
												? "0px 8px 29px rgba(0, 0, 0, 0.05)"
												: "none"
										}
										onClick={() => {
											setSelectedProgramStatus(data.value);
										}}>
										<ButtonName
											color={selectedProgramStatus === data.value ? "#404040" : "#9D9D9D"}>
											{data.title}
										</ButtonName>
									</ProgramButton>
									{i + 1 < tabCategories.length && <Border>|</Border>}
								</>
							))}
						</ProgramButtonDiv>
					</TabSection>
					<OngoingSection>
						{selectedProgramStatus === "ongoing" ? (
							<>
								{data.assignments.ongoing.length > 0 ? (
									<>
										{data.assignments.ongoing.map((item, i) => (
											<OndoingSingleCard
												setSinglePage={setSinglePage}
												key={i}
												item={item}
												getSingleAssignment={getSingleAssignment}
											/>
										))}
									</>
								) : (
									<EmptyPage img={emptyPic} message={"You have no ongoing assignments"} />
								)}
							</>
						) : selectedProgramStatus === "submitted" ? (
							<>
								{data.assignments.submitted.length > 0 ? (
									<>
										{data.assignments.submitted.map((item, i) => (
											<OndoingSingleCard
												setSinglePage={setSinglePage}
												key={i}
												item={item}
												getSingleAssignment={getSingleAssignment}
											/>
										))}
									</>
								) : (
									<EmptyPage img={emptyPic} message={"You have no submitted assignments"} />
								)}
							</>
						) : selectedProgramStatus === "closed" ? (
							<>
								{data.assignments.closed.length > 0 ? (
									<>
										{data.assignments.closed.map((item, i) => (
											<OndoingSingleCard
												setSinglePage={setSinglePage}
												key={i}
												item={item}
												getSingleAssignment={getSingleAssignment}
											/>
										))}
									</>
								) : (
									<EmptyPage img={emptyPic} message={"You have no closed assignments"} />
								)}
							</>
						) :  selectedProgramStatus === "Returned" ? (
							<>
								{data.assignments.returned?.length > 0 ? (
									<>
										{data.assignments.returned.map((item, i) => (
											<OndoingSingleCard
												setSinglePage={setSinglePage}
												key={i}
												item={item}
												getSingleAssignment={getSingleAssignment}
											/>
										))}
									</>
								) : (
									<EmptyPage img={emptyPic} message={"You have no returned assignments"} />
								)}
							</>):""}
					</OngoingSection>
				</LivetitleSection>
			) : !isLoading ? (
				<LivetitleSection>
					<LiveTitleDiv>
						<TitleLeft>
							<Livetitle>Assignments</Livetitle>
							<TextDiv>
								<PathText
									onClick={() => {
										setSinglePage(false);
									}}>
									Assignments
								</PathText>
								<Slash>&nbsp;/&nbsp;</Slash>
								<PathTextBlack>{singleAssignment.subject_name}</PathTextBlack>
							</TextDiv>
						</TitleLeft>{" "}
						<TitleRight>
							{/* <DownloadQuestionButton>
								<Icon src={downloadIcon} alt="Icon" />
								Download
							</DownloadQuestionButton> */}
							<GoBackButton
								onClick={() => {
									setSinglePage(false);
								}}>
								<Icon src={Lefticon} alt="Icon" />
								Back to Assignments
							</GoBackButton>
						</TitleRight>
					</LiveTitleDiv>
					<OngoingAssignment data={singleAssignment} setSinglePage={setSinglePage} refreshApi={refreshApi} />
				</LivetitleSection>
			) : (
				<Loader />
			)}
		</CardContainer>
	);
}
const CardContainer = styled.div``;
const LivetitleSection = styled.div`
	margin-bottom: 20px;
`;
const Livetitle = styled.h4`
	color: #010101;
	font-weight: 500;
	font-size: 22px;
	/* line-height: 29px; */
`;
const TabSection = styled.div`
	width: max-content;
	margin-top: 36px;
`;
const ProgramButtonDiv = styled.div`
	display: flex;
	align-items: center;
	background: #f5f5f5;
	padding: 4px;
	border-radius: 10px;
	height: 44px;
	box-sizing: border-box;
	border: 1px solid #e7e7e7;
`;
const ProgramButton = styled.div`
	background-color: ${(props) => (props.background ? props.background : "#F5F5F5")};
	padding: 8px 8px;
	min-width: 100px;
	border-radius: 8px;
	box-sizing: border-box;
	height: 36px;

	cursor: pointer;
	box-shadow: ${(props) => (props.boxShadow ? props.boxShadow : "none")};
`;
const Border = styled.span`
	font-size: 1rem;
	color: #e7e7e7;
	display: inline-block;
	margin: 0 10px;
`;
const ButtonName = styled.h3`
	color: ${(props) => (props.color ? props.color : "#9D9D9D")};
	font-size: 0.9rem;
	font-weight: 500;
	padding: 0 10px;
	text-align: center;
`;
const OngoingSection = styled.div`
	display: grid;
	gap: 34px;
	grid-template-columns: 1fr 1fr;
	margin-top: 40px;
`;

const EmptyContainer = styled.div`
	height: calc(100vh - 145px);
	overflow-y: scroll;
`;
const LiveTitleDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const TextDiv = styled.div``;
const PathText = styled.span`
	display: inline-block;
	font-size: 14px;
	color: #9d9d9d;
	cursor: pointer;
	&:hover {
		text-decoration: underline;
	}
`;
const Slash = styled.span`
	display: inline-block;
	font-size: 14px;
	color: #9d9d9d;
`;
const PathTextBlack = styled.span`
	color: #000;
	display: inline-block;
	font-size: 14px;
`;
const TitleLeft = styled.div``;
const TitleRight = styled.div``;
const GoBackButton = styled.div`
	display: flex;
	align-items: center;
	border: 1px solid #917afd;
	border-radius: 8px;
	padding: 4px 10px;
	font-size: 14px;
	color: #917afd;
	cursor: pointer;
`;
const Icon = styled.img`
	width: 10px;
	object-fit: contain;
	margin-right: 5px;
	display: block;
	&:first-child {
		width: 15px;
	}
`;

// const DownloadQuestionButton = styled.div`
// 	/* display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	height: 32px;
// 	box-sizing: border-box;
// 	border: 1px solid #c1c1c1;
// 	border-radius: 63px;
// 	padding: 0 12px;
// 	font-size: 13px;
// 	cursor: pointer; */
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	border: 1px solid #917afd;
// 	border-radius: 8px;
// 	padding: 4px 10px;
// 	font-size: 14px;
// 	color: #917afd;
// 	cursor: pointer;
// `;
