import React from "react";
import styled from "styled-components";

export default function StudentInfoCard({ student }) {
	return (
	<StudentInfoSection>
			<StudentImageBox>
				<StudentImage src={student?.image} alt="" />
			</StudentImageBox>
			<StudentInfoBox>
				{/* <StudentName>{student?.name?.split(" ")[0]}</StudentName> */}
				<StudentName>{student?.name}</StudentName>
				{/* {student.program_data > 0 && ( */}
				<>
					<StudentInfo>
						<StudentInfoTitle>Program: </StudentInfoTitle>
						{student?.program_data.course_name}
					</StudentInfo>
					<StudentInfo>
						<StudentInfoTitle>Specialization: </StudentInfoTitle>
						{student?.program_data.specialization_name}
					</StudentInfo>
					<StudentInfo>
                        <StudentInfoTitle>Enrollment No: </StudentInfoTitle>
                        {student?.program_data.enrollment_number}
     				</StudentInfo>

					{/* {student?.program_data[0].year_or_sem && (
							<StudentInfo>
								<StudentInfoTitle>Semester: </StudentInfoTitle>
								{student?.program_data[0].year_or_sem}
							</StudentInfo>
						)} */}
				</>
				{/* )} */}
			</StudentInfoBox>
		</StudentInfoSection>
	);
}

const StudentInfoSection = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	border-bottom: 1px solid #e7e7e7;
	padding-bottom: 1rem;
	margin-bottom: 1rem;
`;
const StudentImageBox = styled.div`
	width: 4.75rem;
	height: 4.75rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 8px;
	overflow: hidden;
	border: 2px solid #fff;
	box-shadow: 0px 9px 21px rgba(0, 0, 0, 0.15);
	margin-right: 1.5rem;
`;
const StudentImage = styled.img`
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
`;
const StudentInfoBox = styled.div`
	& p {
		color: #000;
	}
`;
const StudentName = styled.p``;
const StudentInfo = styled.p`
	font-size: 0.9rem;
`;
const StudentInfoTitle = styled.span`
	/* opacity: 0.4; */
`;
