import React, { useState, useContext, useEffect, useRef } from "react";
import { useLocation, useHistory, useParams, Link } from "react-router-dom";
import styled from "styled-components";
import { baseConfig } from "../../../axiosConfig";
import { Context } from "../../../contexts/Store";

import Loader from "../loader/Loader";
import EmptyPage from "../emptyPages/EmptyPage";
import emptyPic from "../../../assets/icons/live-class-empty-icon.svg";
import folderIcon from "../../../assets/icons/folder.svg";

import search from "../../../assets/icons/search.svg";
import CRModuleFileCard from "./CRModuleFileCard";

export default function CRModulekit() {
	const { state } = useContext(Context);
	const history = useHistory();
	const location = useLocation();
	const search_path = location?.search;
	// const [selectedFolderId, setSelectedFolderId] = useState(null);
	const [folderFiles, setFolderFiles] = useState([]);
	const [folders, setFolders] = useState([]);

	const [isFoldersLoading, setFoldersLoading] = useState(false);
	const [isFilesLoading, setFilesLoading] = useState(false);
	// search
	const [searchQuery, setSearchQuery] = useState("");
	// const handleSearchChange = (event) => {
	// 	setSearchQuery(event.target.value);
	// };
	const containerRef = useRef(null);
	const handleFocus = () => {
		containerRef.current.classList.add("focused");
	};
	const handleBlur = () => {
		containerRef.current.classList.remove("focused");
	};

	// open folder
	const [openedFolderId, setOpenedFolderId] = useState("");
	const [openedFolderName, setOpenedFolderName] = useState("");

	const classroom_id = new URLSearchParams(search_path).get("c");

	const fetchFolders = () => {
		setFoldersLoading(true);
		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`/class-room/room/${classroom_id}/module-kits/`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
				params: {
					search: searchQuery,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;

				if (StatusCode === 6000) {
					setFolders(data || []);
					// setSelectedFolderId(data.length > 0 ? data[0].id : null);
					setTimeout(() => {
						setFoldersLoading(false);
					}, 200);
				} else {
					setTimeout(() => {
						setFoldersLoading(false);
						alert("It is taking longer than usual");
					}, 200);
				}
			})
			.catch((error) => {});
	};

	const getFiles = () => {
		setFilesLoading(true);
		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`/class-room/module-kit/folders/${openedFolderId}/docs/`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
				params: {
					search: searchQuery,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;

				if (StatusCode === 6000) {
					setFolderFiles(data);
					console.log(data, "data is here");
					setTimeout(() => {
						setFilesLoading(false);
					}, 200);
				} else {
					setTimeout(() => {
						setFilesLoading(false);
						alert("It is taking longer than usual");
					}, 200);
				}
			})
			.catch((error) => {});
	};

	useEffect(() => {
		fetchFolders();
		console.log(searchQuery, "searched");
	}, [classroom_id, searchQuery]);

	useEffect(() => {
		if (openedFolderId) {
			console.log(openedFolderId, "open folder id");
			getFiles();
		}
	}, [openedFolderId, searchQuery]);

	const handleFolderClick = (folderId, folderName) => {
		setOpenedFolderId(folderId || "");
		setOpenedFolderName(folderName);
	};

	const handleModuleKitClick = () => {
		if (openedFolderId) {
			setOpenedFolderId(""); // Reset only if there is an open folder
		}
	};

	return (
		<MainContainer>
			<ModuleKitTitleSection>
				<ModuleTitleRight>
					<ModuleKitTitle>Module Kit</ModuleKitTitle>
					{openedFolderId && (
						<TextDiv>
							<PathText onClick={handleModuleKitClick}>Module Kit</PathText>
							<Slash>&nbsp;/&nbsp;</Slash>
							<PathTextBlack>{openedFolderName}</PathTextBlack>
						</TextDiv>
					)}
				</ModuleTitleRight>

				<SearchContainer ref={containerRef}>
					<SearchInput
						// onFocus={handleFocus}
						// onBlur={handleBlur}
						// value={searchQuery}
						// onChange={handleSearchChange}
						// type="text"
						// autoComplete="off"
						// placeholder="Search..."
						onFocus={handleFocus}
						onBlur={handleBlur}
						id="search"
						type="text"
						placeholder="Search"
						onChange={(e) => setSearchQuery(e.target.value)}
						value={searchQuery}
					/>
					<SearchIcon src={search} alt="Search Icon" />
				</SearchContainer>
			</ModuleKitTitleSection>
			<ModuleKitContainer>
				{!openedFolderId ? (
					// Listing Folders
					<>
						{!isFoldersLoading ? (
							folders?.length > 0 ? (
								<ModuleKitLeft
									onDoubleClick={(e) => {
										setOpenedFolderId(folders[0]?.id);
									}}>
									{folders.map((folder) => (
										<FolderCard
											key={folder.id}
											onClick={() => handleFolderClick(folder.id, folder.name)}>
											<FolderIcon>
												<Icon src={folderIcon} alt="Folder Icon" />
											</FolderIcon>
											<ModuleName>{folder.name}</ModuleName>
										</FolderCard>
									))}
								</ModuleKitLeft>
							) : (
								// ) : (
								// 		<EmptyContainer>
								// 			<EmptyPage img={emptyPic} message={"No folders found"} />
								// 		</EmptyContainer>
								// 	)
								// ) : (
								// 	<Loader />
								<EmptyFileContainer>
									{searchQuery ? (
										<EmptyPage img={emptyPic} message={`No folders found for "${searchQuery}"`} />
									) : (
										<EmptyPage img={emptyPic} message={"No folders found"} />
									)}
								</EmptyFileContainer>
							)
						) : (
							<Loader />
						)}
					</>
				) : (
					<>
						{/* listing files */}
						<ModuleFile hasFiles={folderFiles.length > 0}>
							{!isFilesLoading ? (
								folderFiles.length > 0 ? (
									folderFiles.map((file) => <CRModuleFileCard key={file.id} fileData={file} />)
								) : (
									// 	) : (
									// 		<EmptyFileContainer>
									// 			<EmptyPage img={emptyPic} message={"You have no files"} />
									// 		</EmptyFileContainer>
									// 	)
									// ) : (
									// 	<Loader
									// 		background="#fff"
									// 		height="calc(100vh - 505px)"
									// 		innerHeight="calc(100vh - 545px)"
									// 	/>

									<EmptyContainer>
										{searchQuery ? (
											<EmptyPage img={emptyPic} message={`No files found for "${searchQuery}"`} />
										) : (
											<EmptyPage img={emptyPic} message={"No files found"} />
										)}
									</EmptyContainer>
								)
							) : (
								<Loader />
							)}
						</ModuleFile>
					</>
				)}
			</ModuleKitContainer>
		</MainContainer>
	);
}
const MainContainer = styled.div`
	width: 90%;
`;
const ModuleKitTitleSection = styled.div`
	margin-bottom: 20px;
	display: flex;
	justify-content: space-between;
`;
const ModuleKitTitle = styled.h4`
	color: #010101;
	font-weight: 500;
	font-size: 22px;
	margin-bottom: 5px;
`;
const ModuleTitleRight = styled.div``;
const TextDiv = styled.div`
	font-family: "poppinsregular";
`;
const PathText = styled.h2`
	display: inline-block;
	font-size: 14px;
	color: #9d9d9d;
	cursor: pointer;
	&:hover {
		text-decoration: underline;
	}
`;
const Slash = styled.span`
	display: inline-block;
	font-size: 13px;
	color: #9d9d9d;
`;
const PathTextBlack = styled.span`
	color: #000;
	display: inline-block;
	font-size: 14px;
`;

const EmptyContainer = styled.div`
	height: calc(100vh - 350px);
	overflow-y: scroll;
`;
const ModuleKitContainer = styled.div``;
const ModuleKitLeft = styled.div`
	gap: 32px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
`;
const FolderCard = styled.div`
	display: flex;
	padding: 16px;
	/* justify-content: center; */
	align-items: center;
	gap: 10px;
	border-radius: 8px;
	border: 1px solid #e5e5e5;
	background: #f5f5f5;
	transition: all 0.3s ease 0s;
	cursor: pointer;
	&:hover {
		box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.16);
	}

	/* border: 1px solid #e5e5e5;
	background: #f5f5f5; */
	/* &:hover {
		border: 1px solid #e5e5e5;
		background: #f5f5f5;
	} */
`;
const FolderIcon = styled.div``;
const Icon = styled.img`
	width: 60px;
	height: 60px;
	display: block;
`;
const ModuleName = styled.h6`
	font-size: 14px;
	text-transform: capitalize;
	font-family: "poppinsregular";
	color: #404040;
	width: 135px;
`;
const SearchContainer = styled.div`
	position: relative;
	border-radius: 8px;
	width: 30%;
`;

const SearchInput = styled.input`
	padding: 12px;
	width: 100%;
	color: #9d9d9d;
	font-family: "inter-regular";
	font-size: 14px;
	font-style: normal;
	text-transform: capitalize;
	border: 1px solid #e7e7e7 !important;
	border-radius: 8px !important;
	/* transition: 0.3s; */
	::placeholder {
		color: #9d9d9d;
		font-size: 14px;
	}
	input[type="text"] {
		padding: 4px 8px;
		border-radius: 8px;
	}
	input[type="text"]:focus {
		background: #fff;
		outline: none;
	}
	&:hover {
		border: 1px solid #9d9d9d;
		border-radius: 8px;
	}
	&.focused {
		border: solid 1px #9d9d9d;
		border-radius: 8px;
	}
`;

const SearchIcon = styled.img`
	position: absolute;
	right: 16px;
	top: 22px;
	transform: translateY(-50%);
	width: 18px;
	height: 18px;
	cursor: pointer;
	object-fit: cover;
	opacity: 0.5;
`;

const ModuleFile = styled.div`
	/* display: grid; */
	${({ hasFiles }) => (hasFiles ? "display: grid;" : "display: unset;")}
	grid-template-columns: 1fr 1fr 1fr;
	gap: 40px;
`;
const EmptyFileContainer = styled.div`
	height: calc(100vh - 350px);
	/* height: 40vh; */
	overflow-y: scroll;
`;
