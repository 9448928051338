import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import "./Dashboard.css";
import { useHistory } from "react-router-dom";
import Calendar from "react-calendar";
import { Context } from "./../../../contexts/Store";
import { baseConfig } from "./../../../axiosConfig";
import Loader from "../../includes/loader/Loader";
import moment from "moment";

//empty
import EmptyPage from "../../includes/emptyPages/EmptyPage";
import emptyPic from "../../../assets/icons/live-class-empty-icon.svg";

// import 'react-calendar/dist/Calendar.css';

export default function MainDashboard2({ student }) {
	const history = useHistory();
	const [isLoading, setLoading] = useState(true);

	const [value, onChange] = useState(new Date());
	const [isAllData, setAllData] = useState(false);
	const { state } = useContext(Context);
	const [studentProfile, setStudentProfile] = useState({}); //student details
	const [examData, setExamData] = useState({}); //exam data
	const [examResult, setExamResult] = useState({}); //exam data
	const [upCommingExams, setUpCommingExams] = useState([]);
	const [selectedPeriod, setSelectedPeriod] = useState({});
	const home = "HOME PAGE";

	const getSudentsDetais = () => {
		setLoading(true);

		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`students/student-exam-data/`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					if (data.length === 0 || Object.keys(data).length === 0) {
						setLoading(false);
						setAllData(false);
					} else {
						// setStudentProfile(data.profile);
						setExamData(data.exam);
						setUpCommingExams(data.upcoming_exams);
						setExamResult(data.exam_result);
						setAllData(true);
						setLoading(false);

						// setName(data.profile.name.split(" "));
						// setSelectedPeriod(
						//     data.exam_data.find(
						//         (element) => element.current_period === true
						//     )
						// );
					}
				} else {
					setLoading(false);
				}
			})
			.catch((error) => {});
	};

	// useEffect(() => (), []);
	useEffect(() => {
		getSudentsDetais();
		return () => {
			setLoading(false);
		};
	}, []);

	return (
		<FullMainContainer>
			{!isLoading ? (
				<>
					{isAllData ? (
						<MainContainer>
							<MiddleContainer>
								<Welcome>
									<NameContainer>
										<WelcomText>Welcome,</WelcomText>
										<Name>{student.name}</Name>
									</NameContainer>
									<Description>
										Learn, Write, Achieve, and Succeed in your exams. Everything about your exam is
										here.
									</Description>
									<TopImageContainer>
										<LaptopImage
											src={require("./../../../assets/images/laptop.png").default}
											alt="Image"
										/>
									</TopImageContainer>
								</Welcome>
								<Examination>
									<Heading>
										<ExaminationText>Examination</ExaminationText>
									</Heading>

									{/* {examResult?.subjects?.length > 0 ? ( */}
									<ExamMainContainer>
										<SemesterBox>
											{/* <Semester>
										{examData?.map((exam, index) => (
											<SemDiv key={index}>
												<SemList
													shadow={
														exam ===
															selectedPeriod &&
														"0px 8px 29px 0px rgba(0, 0, 0, 0.05)"
													}
													style={{
														background:
															exam ===
																selectedPeriod &&
															"#fff",
														
													}}
													onClick={() => {
														if (
															exam.exam
																.exam_id !==
															null
														) {
															setSelectedPeriod(
																exam
															);
														} else {
															alert(
																"Exam not scheduled yet"
															);
														}
													}}
												>
													{exam.period}
												</SemList>
												{index !==
													examData.length - 1 && (
													<Border>|</Border>
												)}
											</SemDiv>
										))}
									</Semester> */}
										</SemesterBox>
										{/* {selectedPeriod !== undefined &&
									examData !== "" && ( */}
										<ExamDetails>
											<LeftPart>
												<ExaminationHeading>{examData?.name}</ExaminationHeading>
												<Detaillists>
													<Detailslist>
														Starting Date:{" "}
														<Innerdetail>
															{moment(examData?.starting_date).format("DD-MMM-YYYY")}
															&nbsp; &nbsp; &nbsp;
														</Innerdetail>
													</Detailslist>
													<Detailslist>
														Ending Date:{" "}
														<Innerdetail>
															{moment(examData?.end_date).format("DD-MMM-YYYY")}
														</Innerdetail>
													</Detailslist>
												</Detaillists>
												{examData?.is_resheduled && (
													<Detaillists>
														<Detailslist>
															Reschedule:{" "}
															<Innerdetail>
																{examData?.reshedule_start} to {examData?.reshedule_end}
															</Innerdetail>
														</Detailslist>
													</Detaillists>
												)}
											</LeftPart>

											{examData?.status !== "Completed" && (
												<RightPart>
													<BoxNotCompleted>
														<CautionIcon
															src={require("../../../assets/icons/warn.png").default}
															alt="Image"
														/>
														<NotCompletedText>Not Completed</NotCompletedText>
													</BoxNotCompleted>
												</RightPart>
											)}
										</ExamDetails>
										{/* )} */}
										{examData !== "" ? (
											<TimeTable>
												<ExamHeading>EXAMINAITON TIME TABLE</ExamHeading>
												<Table>
													<TableHeading>
														<Title width={"50%"}>Subject</Title>
														<Title width={"50%"}>Date</Title>
													</TableHeading>
													<TableBody>
														<>
															{examData?.subjects?.map((data, i) => (
																<TableRow key={i}>
																	<ExamName>{data.subject_name}</ExamName>

																	<TextBox>
																		<ExamDate>
																			{moment(data.date).format("DD-MMM-YYYY")}
																		</ExamDate>
																		<Status>
																			{/* <ExamCompleted>
																<CompletedIcon
																	src={
																		require("./../../../assets/icons/Component 2.png")
																			.default
																	}
																	alt="Image"
																/>
																<CompletedText>
																	Exam
																	Completed
																</CompletedText>
															</ExamCompleted> */}

																			<AttendExam
																				background={
																					data.status === "Ongoing"
																						? "#917afd"
																						: data.status === "Upcoming"
																						? "#D3CAFB"
																						: data.status === "Completed"
																						? "#F4F9EB"
																						: data.status === "Not Attended"
																						? "#FBE9E9"
																						: "#F4F9EB"
																				}
																				onClick={() => {
																					if (data.status === "Ongoing") {
																						// history.push(
																						// 	"/exam-login/",
																						// 	{
																						// 		state: {
																						// 			examId: selectedPeriod
																						// 				.exam
																						// 				.id,
																						// 			subjectData:
																						// 				data,
																						// 		},
																						// 	}
																						// );

																						history.push(
																							`/exam-login/${data.id}`
																						);
																					}
																				}}>
																				<AttendIcon
																					// src={
																					// 	require("./../../../assets/icons/file.png")
																					// 		.default
																					// }
																					src={
																						data.status === "Completed"
																							? require("./../../../assets/icons/completed.svg")
																									.default
																							: data.status ===
																							  "Not Attended"
																							? require("./../../../assets/icons/not-attended.svg")
																									.default
																							: require("./../../../assets/icons/file.png")
																									.default
																					}
																					alt="Image"
																				/>
																				<AttendText
																					style={{
																						color:
																							data.status === "Completed"
																								? "#92BF33"
																								: data.status ===
																								  "Not Attended"
																								? "#F61F1F"
																								: "#fff",
																					}}>
																					{data.status === "Completed"
																						? "Exam Completed"
																						: data.status === "Not Attended"
																						? "Not Attended"
																						: "Attend Exam"}
																				</AttendText>
																			</AttendExam>
																		</Status>
																	</TextBox>
																</TableRow>
															))}
														</>

														{/* <TableRow>
										<ExamName>Mathematics</ExamName>
										<ExamDate>24/jul/2023</ExamDate>
										<Status>
											<ExamCompleted>
												<CompletedIcon
													src={
														require("./../../../assets/icons/Component 2.png")
															.default
													}
													alt="Image"
												/>
												<CompletedText>
													Exam Completed
												</CompletedText>
											</ExamCompleted>
											<AttendExam
												onClick={() => {
													history("/exam-login/");
												}}
											>
												<AttendIcon
													src={
														require("./../../../assets/icons/file.png")
															.default
													}
													alt="Image"
												/>
												<AttendText to="/exam-login/">
													Attend Exam
												</AttendText>
											</AttendExam>
										</Status>
									</TableRow> */}
													</TableBody>
												</Table>
											</TimeTable>
										) : (
											<TimeTable>
												<ExamHeading>EXAMINAITON TIME TABLE</ExamHeading>
												<ExamEmpty>
													<ImageBox>
														<NotScheduledICon
															src={require("./../../../assets/images/empty.png").default}
															alt="Image"
														/>
														<EmptyText>Exam time table is not scheduled yet!</EmptyText>
													</ImageBox>
												</ExamEmpty>
											</TimeTable>
										)}

										{/* {Object.keys(examResult).length > 0 && (
											<>
												<Result>
													<Heading>
														<ExaminationText>Results</ExaminationText>
													</Heading>
													<Table>
														<ResultTable>
															<ResultTableHead width={"25%"}>Subject</ResultTableHead>
															<ResultTableHead width={"15%"}>Date</ResultTableHead>
															<ResultTableHead width={"15%"}>Total</ResultTableHead>
															<ResultTableHead width={"15%"}>Pass</ResultTableHead>
															<ResultTableHead width={"15%"}>Mark</ResultTableHead>
															<ResultTableHead width={"15%"}>Result</ResultTableHead>
														</ResultTable>
														<TableBody>
															{examResult?.subjects?.map((item, i) => (
																<TableRow key={i}>
																	<ResultTD width="25%">{item?.name}</ResultTD>
																	<ResultTD>{item.result.date}</ResultTD>
																	<ResultTD>{examResult.total_mark}</ResultTD>
																	<ResultTD>{examResult.pass_mark}</ResultTD>
																	<ResultTD>{item?.result?.total_mark}</ResultTD>
																	<ResultTD
																		className={
																			item.result.is_pass ? "pass" : "fail"
																		}>
																		{" "}
																		{item.result.is_pass ? "P" : "F"}
																	</ResultTD>
																</TableRow>
															))}
														</TableBody>
													</Table> */}

										{/* <Cards>
										<Card>
											<CardLeftPart>
												<ExaminationName>
													I first semester BSc
													Computer Science
													Examination, june 2022
												</ExaminationName>
												<Bottom>
													<ResultHeading>
														Result Published on:{" "}
													</ResultHeading>
													<ResultText>
														{" "}
														01 / 08 / 2022
													</ResultText>
												</Bottom>
											</CardLeftPart>
											<CardRightPart>
												<CheckICon
													src={
														require("./../../../assets/icons/star.png")
															.default
													}
													alt="Image"
												/>
												<Check>Check Result</Check>
											</CardRightPart>
										</Card>
									</Cards> */}
										{/* </Result>
												<ResultBottom>
													<ResultLeft>
														<ResultTD width="auto">Overall Grade :</ResultTD>
														<ResultTD
															width="auto"
															className={
																examResult?.overall_grade == "Failed" ? "fail" : "pass"
															}>
															&nbsp; {examResult?.overall_grade}
														</ResultTD>
													</ResultLeft>
													<ResultRight></ResultRight>
												</ResultBottom>
											</>
										)} */}
									</ExamMainContainer>
									{/* ) : ( */}

									{/* )} */}
								</Examination>
							</MiddleContainer>
							<RightContainer>
								<Profile>
									<ProfileImageContainer>
										<ProfileImage src={student?.image} alt="Image" />
									</ProfileImageContainer>
									<ProfileContent>
										<ProfileName>{student?.name}</ProfileName>
										<RollNum>
											Enrollment No: &nbsp;
											<Num>{student?.program_data.enrollment_number}</Num>
										</RollNum>
										<Course>
											Course: &nbsp;
											<CourseName>{student?.program_data?.course_name}</CourseName>
										</Course>
									</ProfileContent>
								</Profile>
								<Upcoming>
									<CalenderBox>
										<Calendar
											onChange={onChange}
											value={value}
											style={{ innerWidth: 20 }}
											className={"react-calendar"}
											// className={["c1", "c2"]}
											// showNavigation={true}
										/>
									</CalenderBox>

									<UpcomingHeading>UPCOMING EXAMINATIONS</UpcomingHeading>
									{upCommingExams?.length > 0 ? (
										<SubjectCards>
											{upCommingExams?.map((list, ind) => (
												<div key={ind}>
													{list.status === "Upcoming" && (
														<SubjectCard>
															<CardContainer>
																<CardLeft>
																	<ExamTitle>{list.subject_name}</ExamTitle>
																	<DateContent>
																		<CalenderIcon
																			src={
																				require("./../../../assets/icons/celender.png")
																					.default
																			}
																			alt="Image"
																		/>
																		<DateText>
																			{moment(list.date).format("DD-MMM-YYYY")}
																		</DateText>
																	</DateContent>
																</CardLeft>
																<CardRight>
																	{/* <AttendButton>
														Attend
													</AttendButton> */}
																</CardRight>
															</CardContainer>
														</SubjectCard>
													)}
												</div>
											))}
										</SubjectCards>
									) : (
										<NoUpComing>
											<UpComingText>No upcoming exams</UpComingText>
										</NoUpComing>
									)}

									{/* <EmptyUpcoming>
						<EmptyContent>No upcoming exams</EmptyContent>
					</EmptyUpcoming> */}
								</Upcoming>
							</RightContainer>
						</MainContainer>
					) : (
						<EmptyContainer>
							<EmptyPage
								img={emptyPic}
								message={
									// "You have no live or upcoming classes"
									"You have no exams"
								}
							/>
						</EmptyContainer>
					)}
				</>
			) : (
				<Loader />
			)}
		</FullMainContainer>
	);
}
const FullMainContainer = styled.div`
	background: #f9f9f9;
	/* padding: 25px 8px 25px 20px; */
	/* border: 1px solid #e5e5e5; */
	box-sizing: border-box;
	border-radius: 10px;
	height: calc(100vh - 110px);
	overflow-y: scroll;
`;
const MainContainer = styled.div`
	display: flex;
	justify-content: space-between;
	background: #f9f9f9;
	/* min-height: 100vh; */
	height: calc(100vh - 110px);
	/* border: 1px solid #000; */
`;
const MiddleContainer = styled.div`
	width: 66%;
	height: calc(100vh - 110px);
	overflow-y: scroll;
	/* border: 1px solid #f00; */
`;
const Welcome = styled.div`
	display: flex;
	align-items: center;
	// justify-content: space-between;
	background-color: #9a4bcb;
	position: relative;
	padding: 20px;
	color: #fff;
	border-radius: 8px;
	box-shadow: 0px 12px 17px 0px #9a4bcb40;
	justify-content: space-between;
	margin-top: 20px;
`;
const NameContainer = styled.div`
	// margin-right: 100px;
	// width: 20%;
	@media (max-width: 1400px) {
		margin-right: 10px;
	}
`;
const WelcomText = styled.h6`
	/* margin-bottom: 10px; */
	font-size: 15px;
`;
const Name = styled.h3`
	font-size: 24px;
	font-weight: 600;
	font-family: "inter-regular";
	text-transform: capitalize;
`;
const Description = styled.p`
	width: 45%;
	font-size: 14px;
	color: rgba(255, 255, 255, 0.5);
	margin-right: 126px;
	@media (max-width: 1400px) {
		margin-right: 0;
		text-align: right;
	}
`;
const TopImageContainer = styled.div`
	// width: 20%;
	position: absolute;
	right: 5px;
	@media (max-width: 1400px) {
		display: none;
	}
`;
const LaptopImage = styled.img`
	width: 100%;
	display: block;
	height: 140px;
	width: 150px;
	@media (max-width: 1400px) {
		/* width: 150px;
		height: 150px; */
	}
`;
const Examination = styled.div`
	margin-top: 30px;
`;
const Heading = styled.div`
	margin-bottom: 10px;
`;
const ExaminationText = styled.h4`
	font-size: 20px;
	font-weight: 600;
`;
const ExamMainContainer = styled.div`
	border: 1px solid #9999996b;
	border-radius: 8px;
	background: #fff;
`;

const SemesterBox = styled.div`
	background-color: #fff;
	border-radius: 8px 8px 0 0;
	padding: 10px;
	display: flex;
`;
const Semester = styled.div`
	display: flex;
	padding: 8px 11px;
	background: #f5f5f5;
	width: max-content;
	border-radius: 8px;
	align-items: center;
	flex-wrap: wrap;
`;
const SemDiv = styled.div`
	margin-top: 5px;
`;
const SemList = styled.button`
	font-size: 13px;
	color: #999;
	padding: 10px 12px;
	border-radius: 8px;
	font-family: "inter-medium";
	cursor: pointer;
	background: ${(props) => (props.background ? props.background : null)};
	box-shadow: ${(props) => (props.shadow ? props.shadow : props.shadow)};
	/* box-shadow:
	0px 8px 29px 0px rgba(0, 0, 0, 0.05);
	color: #404040; */
	:hover {
		background: rgb(11 63 122 / 8%);
	}
	/* &:focus {
		background: #fff;
		box-shadow: 0px 8px 29px 0px rgba(0, 0, 0, 0.05);
		color: #404040;
	} */
	//   &.active {
	// 	background: #fff;
	//     border-color: #218AC6;
	//   }
`;
const Border = styled.span`
	font-size: 14px;
	color: #999;
	display: inline-block;
	margin: 0 10px;
`;

const ExamDetails = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px 10px;
`;
const LeftPart = styled.div``;
const ExaminationHeading = styled.p`
	color: #522f91;
	font-size: 14px;
	margin-bottom: 10px;
`;
const Detaillists = styled.ul`
	display: flex;
	justify-content: space-between;
	margin-top: 5px;
`;
const Detailslist = styled.li`
	font-size: 14px;
	color: #999;
`;

const Innerdetail = styled.span`
	color: #000;
	font-size: 13px;
	font-family: "inter-medium";
`;
const RightPart = styled.div``;
const BoxNotCompleted = styled.div`
	display: flex;
	align-items: center;
	background-color: #fff;
	padding: 7px;
	border-radius: 15px;
`;

const CautionIcon = styled.img`
	width: 20px;
	display: block;
	margin-right: 5px;
`;
const NotCompletedText = styled.h6`
	font-size: 13px;
	color: #92bd7d;
`;
const TimeTable = styled.div`
	background-color: #fff;
	padding: 10px 10px;
	border-radius: 0 0 10px 10px;
`;
const ExamHeading = styled.h5`
	color: #999;
	font-size: 13px;
	font-weight: 600;
	padding-bottom: 10px;
`;
const Table = styled.div`
	width: 100%;
	border: 1px solid #9999994a;
	border-radius: 8px;
`;
const TableHeading = styled.div`
	display: flex;
	justify-content: space-between;
	background: #f5f5f5;
	border-radius: 8px 8px 0 0;
	padding: 15px 10px;
`;
const Title = styled.h5`
	width: 30%;
	/* padding: 15px 0; */
	font-size: 13px;
	font-weight: 600;
	width: ${(props) => (props.width ? props.width : null)};
`;
const TableBody = styled.div``;
const TableRow = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 10px;
	align-items: center;
	border-bottom: 1px solid #99999940;
`;
const TextBox = styled.div`
	display: flex;
	justify-content: space-between;
	width: 50%;
	align-items: center;
`;
const ExamName = styled.h6`
	font-size: 14px;
	width: 45%;
`;
const ExamDate = styled(ExamName)``;
const Status = styled.div`
	/* width: 30%; */
`;
const ExamCompleted = styled.div`
	text-align: center;
	display: flex;
	align-items: center;
	background-color: #92bf3321;
	padding: 10px 14px;
	border-radius: 5px;
	/* max-width: max-content; */
	margin: 0 0 0 auto;
`;
const CompletedIcon = styled.img`
	width: 16px;
	display: block;
	margin-right: 5px;
`;
const CompletedText = styled.h6`
	font-size: 14px;
	color: #a5ca56;
`;
const AttendExam = styled(ExamCompleted)`
	/* background-color: #917afd; */
	cursor: pointer;
	background: ${(props) => (props.background ? props.background : "#917afd")};
`;
const AttendIcon = styled(CompletedIcon)``;
const AttendText = styled.p`
	color: #fff;
	font-size: 14px;
	margin-bottom: -3px;
`;
const ExamEmpty = styled.div`
	background: #f3f3f3;
	padding: 25px;
	text-align: center;
	border-radius: 8px;
	margin-top: 8px;
`;

const ImageBox = styled.div``;
const NotScheduledICon = styled.img`
	display: block;
	width: 80px;
	margin: 0 auto 10px;
`;
const EmptyText = styled.p`
	color: #999;
	font-size: 13px;
`;
const Result = styled.div`
	padding: 15px 10px 10px;
`;
const ResultTitle = styled.h4`
	margin-bottom: 10px;
	color: #404040;
	font-size: 14px;
	/* font-weight: 600; */
`;
const ResultTable = styled(TableHeading)``;
const ResultTableHead = styled(Title)``;
const ResultTD = styled(ExamName)`
	font-size: 14px;
	color: #000;
	/* width: 15%; */
	width: ${(props) => (props.width ? props.width : "15%")};

	&.pass {
		color: #92bf33;
	}
	&.fail {
		color: red;
	}
`;
const ResultBottom = styled.div`
	padding: 10px;
`;
const ResultLeft = styled.div`
	display: flex;
	align-items: center;
`;
const ResultRight = styled.div``;

const Cards = styled.div`
	background-color: #fff;
	padding: 10px;
	border-radius: 10px;
`;
const Card = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #0000002b;
	padding: 6px 0px;
`;
const CardLeftPart = styled.div`
	width: 70%;
	display: flex;
	flex-direction: column;
	align-items: self-start;
	padding: 0;
`;
const ExaminationName = styled.p`
	color: #522f91;
	font-size: 14px;
`;

const Bottom = styled.div`
	display: flex;
	align-items: center;
	margin-top: 4px;
`;
const ResultHeading = styled.span`
	color: #999;
	font-size: 14px;
`;
const ResultText = styled.span`
	color: #000;
	font-size: 14px;
`;

const CardRightPart = styled.div`
	display: flex;
	align-items: center;
	padding: 10px 12px;
	border-radius: 5px;
	max-width: max-content;
	margin: 0 0 0 auto;
	background: #92bf33;
	cursor: pointer;
`;
const CheckICon = styled.img`
	width: 12px;
	display: block;
	margin-right: 5px;
`;
const Check = styled.h6`
	color: #fff;
	font-size: 14px;
`;
const RightContainer = styled.div`
	width: 30%;
	height: calc(100vh - 110px);
	overflow-y: scroll;
	/* border: 1px solid #f00; */
`;
const Profile = styled.div`
	text-align: center;
	background: #fff7f1;
	padding: 15px;
	border-radius: 12px;
	border: 2px solid #d3a589;
`;
const ProfileImageContainer = styled.div``;
const ProfileImage = styled.img`
	width: 150px;
	height: 150px;
	display: block;
	border-radius: 15px;
	margin: 0 auto;
	object-fit: cover;
	border: 4px solid #fff;
	box-shadow: 0px 9px 21px 0px #00000026;
`;
const ProfileContent = styled.div``;
const ProfileName = styled.h4`
	font-family: "inter-medium";
	font-size: 16px;
	margin-top: 20px;
`;
const RollNum = styled.p`
	margin-top: 15px;
	font-size: 14px;
	color: #999;
`;
const Num = styled.span`
	display: inline-block;
	color: #000;
`;
const Course = styled(RollNum)`
	margin-top: 0px;
`;
const CourseName = styled(Num)``;
const Upcoming = styled.div`
	border-radius: 7px;
	background: #fff;
	border: 1px solid #88888840;
	margin-top: 10px;
`;
const UpcomingHeading = styled.h4`
	text-align: center;
	padding: 15px 5px;
	border-bottom: 1px solid rgb(165 165 165 / 39%);
	border-top: 1px solid rgb(165 165 165 / 39%);
	font-size: 14px;
	font-weight: 600;
`;
const SubjectCards = styled.div`
	padding: 15px 10px 10px;
`;
const SubjectCard = styled.div`
	padding: 12px;
	border: 1px solid #99999926;
	border-radius: 7px;
	margin-bottom: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	:last-child {
		margin-bottom: 0;
	}
`;
const CardContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
`;
const CardLeft = styled.div``;
const ExamTitle = styled.h5`
	font-weight: 600;
	margin-bottom: 6px;
`;
const DateText = styled.p`
	color: #404040;
	margin-bottom: -5px;
`;
const CardRight = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const DateContent = styled.div`
	display: flex;
	align-items: center;
`;
const AttendButton = styled.button`
	background: #d3cafb;
	border-radius: 4px;
	padding: 10px 12px;
	color: #fff;
	font-size: 13px;
	// font-weight: 600;
	cursor: pointer;
	font-family: "inter-regular";
`;
const CalenderIcon = styled.img`
	width: 14px;
	display: block;
	margin-right: 6px;
`;
const NoUpComing = styled.div`
	height: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const UpComingText = styled.p`
	font-size: 16px;
	color: #b3b3b3;
`;
const CalenderBox = styled.div``;

const EmptyContainer = styled.div`
	height: calc(100vh - 150px);
	/* height: 40vh; */
	overflow-y: scroll;
`;
