import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import screenfull from "screenfull";

import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

// import "react-pdf/dist/esm/Page/TextLayer.css";
// import { Document, Page } from "react-pdf/build/entry.noworker";
import backIcon from "../../../assets/icons/arrow - Left.svg";
import arrow from "../../../assets/icons/right-arrow.svg";
import downloadIcon from "../../../assets/icons/icons8-download-32.png";
import zoomInIcon from "../../../assets/icons/zoom-in.png";
import zoomOutIcon from "../../../assets/icons/zoom-out.png";
import fullscreenIcon from "../../../assets/icons/fullscreen.png";
import exitFullscreenIcon from "../../../assets/icons/exit-fullscreen.png";
import { Context } from "../../../contexts/Store";
import { useHistory, useParams } from "react-router-dom";
import { baseConfig } from "../../../axiosConfig";

export default function PdfComponent() {
    const { state } = useContext(Context);
    const { id } = useParams();
    const history = useHistory();
    const [isLoading, setLoading] = useState(false);
    const [bookData, setBookData] = useState("");
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [zoomValue, setZoomValue] = useState(1.0);
    const [inputtedPageNumber, setInputtedPageNumber] = useState(0);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };
    let roundedZoomValue = Math.round(zoomValue * 10) / 10;
    const incrementZoomValue = () => {
        if (roundedZoomValue !== 2.0) {
            setZoomValue(zoomValue + 0.1);
        }
    };
    const decrementZoomValue = () => {
        if (roundedZoomValue !== 0.5) {
            setZoomValue(zoomValue - 0.1);
        }
    };

    const handlePageChange = (e) => {
        setInputtedPageNumber(parseInt(e.target.value));
    };

    useEffect(() => {
        const element = document.getElementById("target");
        document.getElementById("button").addEventListener("click", () => {
            if (screenfull?.isEnabled) {
                screenfull?.toggle(element);
            }
        });
    }, [screenfull]);

    // pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
    // pdfjs.GlobalWorkerOptions.workerSrc = "pdf.worker.min.js";

    const getBookDetails = () => {
        setLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/library/book/${id}`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;

                if (StatusCode === 6000) {
                    setBookData(data);
                    setTimeout(() => {
                        setLoading(false);
                    }, 200);
                } else {
                    setTimeout(() => {
                        setLoading(false);
                        alert("It is taking longer than usual");
                    }, 200);
                }
            })

            .catch((error) => {});
    };

    const handleKeyDown = (event) => {
        if (event.keyCode === 37) {
            // left arrow key
            // handle left arrow key press
            if (pageNumber !== 1) {
                setPageNumber(pageNumber - 1);
            }
        } else if (event.keyCode === 39) {
            // right arrow key
            // handle right arrow key press
            if (pageNumber !== numPages) {
                setPageNumber(pageNumber + 1);
            }
        }
    };

    useEffect(() => {
        getBookDetails();
    }, [id]);

    return (
        // <SectionContainer>
        <MainContainer
            onKeyDown={handleKeyDown}
            className="pdf-lms"
            id="target"
        >
            <Div>
                <TopLine>
                    <ClickMeBack onClick={() => history.push(`/library/`)}>
                        <BIcon src={backIcon} alt="Image" />
                        <Back>Back</Back>
                    </ClickMeBack>

                    <ButtonWrapper>
                        {pageNumber !== 1 && (
                            <NextButton
                                onClick={() => {
                                    setPageNumber(pageNumber - 1);
                                }}
                            >
                                <PrevIcon src={arrow} alt="icon" />
                            </NextButton>
                        )}
                        <TextDiv>
                            Page {pageNumber} / {numPages}
                        </TextDiv>
                        {pageNumber !== numPages && (
                            <NextButton
                                onClick={() => {
                                    setPageNumber(pageNumber + 1);
                                }}
                            >
                                <Icon src={arrow} alt="icon" />
                            </NextButton>
                        )}
                    </ButtonWrapper>
                    <ZoomSection>
                        <ZoomIconContainer
                            minValueReached={
                                roundedZoomValue === 0.5 ? "true" : "false"
                            }
                            onClick={() => decrementZoomValue()}
                        >
                            <ZoomIcon src={zoomOutIcon} alt="icons" />
                        </ZoomIconContainer>
                        <ZoomIconContainer
                            minValueReached={
                                roundedZoomValue === 2.0 ? "true" : "false"
                            }
                            onClick={() => incrementZoomValue()}
                        >
                            <ZoomIcon src={zoomInIcon} alt="icons" />
                        </ZoomIconContainer>
                        <ZoomIconContainer id="button">
                            {!screenfull.isFullscreen ? (
                                <FSIcon src={fullscreenIcon} alt="icons" />
                            ) : (
                                <FSIcon src={exitFullscreenIcon} alt="icons" />
                            )}
                        </ZoomIconContainer>
                    </ZoomSection>
                </TopLine>
            </Div>
            <BottomPart>
                {numPages > 1 && (
                    <GoToSection
                        onSubmit={(e) => {
                            e.preventDefault();
                            handlePageChange(e);
                            if (
                                inputtedPageNumber > 0 &&
                                inputtedPageNumber <= numPages
                            ) {
                                setPageNumber(inputtedPageNumber);
                            } else {
                                alert("Entered page number does not exist.");
                            }
                            setInputtedPageNumber("");
                        }}
                    >
                        <GoToText>Go to page:</GoToText>
                        <PageInputField
                            type="number"
                            value={
                                inputtedPageNumber
                                // ? inputtedPageNumber
                                // : pageNumber
                            }
                            // onInput={(e) => setPageNumber(parseInt(e.target.value))}
                            // onInput={(e) =>
                            //     setInputtedPageNumber(parseInt(e.target.value))
                            // }
                            onChange={(e) => handlePageChange(e)}
                            // onInput={(e) => setPageNumber(parseInt(e.target.value))}
                        />
                        {inputtedPageNumber > 0 &&
                            inputtedPageNumber <= numPages && (
                                <GoButton
                                    onClick={() => {
                                        setPageNumber(inputtedPageNumber);
                                        setInputtedPageNumber("");
                                    }}
                                >
                                    Go
                                </GoButton>
                            )}
                    </GoToSection>
                )}
            </BottomPart>
            <Document
                file={bookData.content_file}
                onLoadSuccess={onDocumentLoadSuccess}
                // error={haa()}
            >
                <Page
                    pageNumber={pageNumber}
                    renderMode="svg"
                    scale={zoomValue}
                />
            </Document>
            {/* <BottomPart>
                <ButtonWrapper>
                    {pageNumber !== 1 && (
                        <NextButton
                            onClick={() => {
                                setPageNumber(pageNumber - 1);
                            }}
                        >
                            <PrevIcon src={arrow} alt="icon" />
                        </NextButton>
                    )}
                    <TextDiv>
                        Page {pageNumber} / {numPages}
                    </TextDiv>
                    {pageNumber !== numPages && (
                        <NextButton
                            onClick={() => {
                                setPageNumber(pageNumber + 1);
                            }}
                        >
                            <Icon src={arrow} alt="icon" />
                        </NextButton>
                    )}
                </ButtonWrapper>
                {numPages > 1 && (
                    <GoToSection
                        onSubmit={(e) => {
                            e.preventDefault();
                            handlePageChange(e);
                            if (
                                inputtedPageNumber > 0 &&
                                inputtedPageNumber <= numPages
                            ) {
                                setPageNumber(inputtedPageNumber);
                            } else {
                                alert("Entered page number does not exist.");
                            }
                            setInputtedPageNumber(null);
                        }}
                    >
                        <GoToText>Go to page:</GoToText>
                        <PageInputField
                            type="number"
                            // onInput={(e) => setPageNumber(parseInt(e.target.value))}
                            // onInput={(e) =>
                            //     setInputtedPageNumber(parseInt(e.target.value))
                            // }
                            onChange={(e) => handlePageChange(e)}
                            // onInput={(e) => setPageNumber(parseInt(e.target.value))}
                        />
                        {inputtedPageNumber > 0 &&
                            inputtedPageNumber <= numPages && (
                                <GoButton
                                    onClick={() => {
                                        setPageNumber(inputtedPageNumber);
                                        setInputtedPageNumber(null);
                                    }}
                                >
                                    Go
                                </GoButton>
                            )}
                    </GoToSection>
                )}

                <ZoomSection>
                    <ZoomIconContainer
                        minValueReached={
                            roundedZoomValue === 0.5 ? "true" : "false"
                        }
                        onClick={() => decrementZoomValue()}
                    >
                        <ZoomIcon src={zoomOutIcon} alt="icons" />
                    </ZoomIconContainer>
                    <ZoomIconContainer
                        minValueReached={
                            roundedZoomValue === 2.0 ? "true" : "false"
                        }
                        onClick={() => incrementZoomValue()}
                    >
                        <ZoomIcon src={zoomInIcon} alt="icons" />
                    </ZoomIconContainer>
                    <ZoomIconContainer id="button">
                        {!screenfull.isFullscreen ? (
                            <FSIcon src={fullscreenIcon} alt="icons" />
                        ) : (
                            <FSIcon src={exitFullscreenIcon} alt="icons" />
                        )}
                    </ZoomIconContainer>
                </ZoomSection>

                <DownloadButton
                    href={data.content.document_content}
                    target="_blank"
                >
                    <DownloadIcon src={downloadIcon} alt="icon" />
                    <DownloadText>Download</DownloadText>
                </DownloadButton>
            </BottomPart> */}
        </MainContainer>
        // </SectionContainer>
    );
}
const ClickMeBack = styled.button`
    cursor: pointer;
    display: inline-block;
    border: 1px solid #917afd;
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding: 8px 15px;
`;
const Back = styled.h4`
    margin-left: 10px;
    color: #917afd;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
`;
const BIcon = styled.img`
    display: block;
    object-fit: contain;
    width: 15px;
    height: 18px;
`;
const SectionContainer = styled.div`
    background: #ffffff;
    padding: 20px 34px;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 10px;
    height: calc(100vh - 110px);
    /* overflow-y: scroll; */
`;
const MainContainer = styled.div`
    /* overflow-y: scroll;
    background: #fff;
    padding: 10px 0; */
    position: relative;
    background: #ffffff;
    padding: 20px 34px;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 10px;
    height: calc(100vh - 110px);
    overflow-y: scroll;
`;
const BottomPart = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;
    margin-top: 10px;
    margin-bottom: 8px;
`;
const EmptyDiv = styled.div``;
const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 100%; */
`;
const NextButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 15px;
    width: 20px;
    height: 20px;

    cursor: pointer;
`;
const Icon = styled.img`
    display: block;
    width: 80%;
    height: 80%;
    filter: invert(0.5);
`;
const PrevIcon = styled.img`
    transform: rotate(180deg);
    display: block;
    width: 80%;
    height: 80%;
    filter: invert(0.5);
`;
const TextDiv = styled.div`
    font-size: 1rem;
`;
const DownloadButton = styled.a`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background: #fff;
    border: 1px solid #917afd;
    border-radius: 8px;
    cursor: pointer;
`;
const DownloadIcon = styled.img`
    width: 18px;
    height: 15px;
    object-fit: cover;
    margin-right: 8px;
`;
const DownloadText = styled.h6`
    font-size: 0.9rem;
`;
const Div = styled.div`
    /* width: 600px; */
`;
const TopLine = styled.div`
    width: 100%;
    position: sticky;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 0px 20px; */
    margin-bottom: 15px;
    /* position: absolute; */
    top: 0;
    /* display: none; */
    height: 40px;
`;
const ZoomSection = styled.div`
    display: flex;
    align-items: center;
`;
// const LoadingText = styled.h6`
//     font-size: 1rem;
// `;
const ZoomIconContainer = styled.div`
    background: #fff;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: ${(props) => (props.minValueReached === "true" ? 0.1 : 1.0)};
    cursor: pointer;
`;
const ZoomIcon = styled.img`
    width: 60%;
    height: 60%;
    display: block;
`;
const FSIcon = styled.img`
    width: 45%;
    height: 45%;
    display: block;
`;
const NextTopicButton = styled.button`
    background: #15bf81;
    display: inline-flex;
    align-items: center;
    border-radius: 6px;
    padding: 6px 14px;
    cursor: pointer;
`;
const NextTopicText = styled.p`
    margin-right: 5px;
    font-size: 0.9rem;
`;
const GoToSection = styled.form`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    margin-right: 2.7%;
`;
const GoToText = styled.h6`
    font-size: 0.95rem;
    margin-right: 8px;
`;
const PageInputField = styled.input`
    display: inline-block;
    text-align: center;
    width: 40px;
    height: 30px;
    /* background: #fff; */
    border: 1px solid #d8d6d6;
    border-radius: 8px;
`;
const GoButton = styled.div`
    margin-left: 10px;
    width: 60px;
    height: 30px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #a794fd;
    color: #fff;
    cursor: pointer;
`;
const DescriptionText = styled.p`
    color: #9d9d9d;
    font-size: 0.9rem;
    margin-bottom: 10px;
`;
