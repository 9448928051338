import React from "react";
import ExamLogin from "./../../components/screens/attendExam/ExamLogin";
import {
	Redirect,
	Route,
	Switch,
	BrowserRouter as Router,
} from "react-router-dom";
import Index from "../../components/screens/dashboard/Index";
import Questions from "../../components/screens/attendExam/Questions";
import InstructionMainPage from "../../components/screens/attendExam/InstructionMainPage";
import Login from "../../components/screens/login/Login";
import "../../assets/css/style.css";
import MainDashboard2 from "../../components/screens/dashboard/MainDashboard2";

function AppRouter() {
	return (
		<Switch>
			<Route path="/exam-login/:subjact_id" component={ExamLogin} />
			<Route
				path="/exam-instruction/:id"
				component={InstructionMainPage}
			/>
			<Route path="/exam-questions/:id" component={Questions} />
			<Route path="/" component={Index} />

			{/* <Route path="/login/" component={Login} /> */}
		</Switch>
	);
}

export default AppRouter;
