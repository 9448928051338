import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import back from "../../../assets/icons/arrow - Left.svg"
import pdf from "../../../assets/images/content.png"

import zoomInIcon from "../../../assets/icons/zoom-in.png";
import zoomOutIcon from "../../../assets/icons/zoom-out.png";

import pdficon from "../../../assets/icons/note_icon.svg"
import readicon from "../../../assets/icons/read_book.svg"
import arrow from "../../../assets/icons/right-arrow.svg";




import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

import spdf from "../../../assets/documents/sample.pdf"
import { useHistory, useLocation, useParams } from 'react-router-dom';
import screenfull from 'screenfull';
import { baseConfig } from '../../../axiosConfig';
import { Context } from '../../../contexts/Store';


export default function ReadPdf() {

    let history = useHistory();


    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [zoomValue, setZoomValue] = useState(1.0);
    const [inputtedPageNumber, setInputtedPageNumber] = useState(0);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };
    let roundedZoomValue = Math.round(zoomValue * 10) / 10;
    const incrementZoomValue = () => {
        if (roundedZoomValue !== 2.0) {
            setZoomValue(zoomValue + 0.1);
        }
    };
    const decrementZoomValue = () => {
        if (roundedZoomValue !== 0.5) {
            setZoomValue(zoomValue - 0.1);
        }
    };

    const handlePageChange = (e) => {
        setInputtedPageNumber(parseInt(e.target.value));
    };


    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

    // useEffect(() => {
    //     const element = document.getElementById("target");
    //     document.getElementById("button").addEventListener("click", () => {
    //         if (screenfull?.isEnabled) {
    //             screenfull?.toggle(element);
    //         }
    //     });
    // }, []);
    const { state } = useContext(Context);
	const [isLoading, setLoading] = useState(false);
    const [singleData, setSingleData] = useState({});
	const [clickedId, setClickedId] = useState("");



    const {id} = useParams()

	const getSingle = () => {
		setLoading(true);
		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`/library/book/${id}`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {

				const { StatusCode, data } = response.data;


				if (StatusCode === 6000) {
					;
					setSingleData(data)
					setTimeout(() => {
						setLoading(false);
					}, 200);
				} else {
					setTimeout(() => {
						setLoading(false);
						alert("It is taking longer than usual");
					}, 200);
				}
			})

			.catch((error) => { });
	};

	useEffect(() => {
		getSingle()
	}, [clickedId]);

    return (
        <>
            <MainContainer className="pdf-lms" >
                <Div>
                    <ZoomSection>

                        <ClickMeBack
                            onClick={() => history.push(`/library/`)}
                        >
                            <BIcon
                                src={back} alt="Image" />
                            <Back>Back</Back>
                        </ClickMeBack>
                        <Zoomoption>
                            <ZoomIconContainer
                                minValueReached={
                                    roundedZoomValue === 2.0 ? "true" : "false"
                                }
                                onClick={() => incrementZoomValue()}
                            >
                                <ZoomIcon src={zoomInIcon} alt="icons" />
                            </ZoomIconContainer>
                            <ZoomSize><PCount>100%</PCount></ZoomSize>
                            <ZoomIconContainer
                                minValueReached={
                                    roundedZoomValue === 0.5 ? "true" : "false"
                                }
                                onClick={() => decrementZoomValue()}
                            >
                                <ZoomIcon src={zoomOutIcon} alt="icons" />
                            </ZoomIconContainer>
                        </Zoomoption>
                    </ZoomSection>
                    <ButtonWrapper>
                        {pageNumber !== 1 && (
                            <NextButton
                                onClick={() => {
                                    setPageNumber(pageNumber - 1);
                                }}
                            >
                                <PrevIcon src={arrow} alt="icon" />
                            </NextButton>
                        )}
                        <TextDiv>
                            Page {pageNumber} / {numPages}
                        </TextDiv>
                        {pageNumber !== numPages && (
                            <NextButton
                                onClick={() => {
                                    setPageNumber(pageNumber + 1);
                                }}
                            >
                                <Icon src={arrow} alt="icon" />
                            </NextButton>
                        )}
                    </ButtonWrapper>
                </Div>

                <Document
                    file={singleData?.content_file}
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    <Page
                        pageNumber={pageNumber}
                        renderMode="svg"
                        scale={zoomValue}
                    />
                </Document>

                <BottomSection>

                </BottomSection>
            </MainContainer>
        </>
    )
}
const MainContainer = styled.div`
    background: #F5F5F5;
	padding: 20px 34px;
	border: 1px solid #e5e5e5;
	box-sizing: border-box;
	border-radius: 10px;
	height: calc(100vh - 110px);
	overflow-y: scroll;
`;
const Div = styled.div`
    
`;


const BottomSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const ClickMeBack = styled.button`
    cursor: pointer;
    display: inline-block;
    border: 1px solid #917AFD;
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding: 10px 15px;

`;
const Back = styled.h4`
    margin-left: 11px;
    color: #917AFD;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
`;
const BIcon = styled.img`
    display: block;
    object-fit: contain;
    width: 15px;
    height: 18px;
`;
const Pdf = styled.div`
    width: 507.6px;
    height: 776.08px;
`;
const Picon = styled.div`
    object-fit: contain;
    display: block;
    width: 100%;
    height: 100%;
`;
const PageCount = styled.div`
    display: block;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border-radius: 4px;
    padding: 15px 25px;
`;
const PageText = styled.h4`
    font-size: 1rem;
`;
const ViewOption = styled.div`
    background: #FFFFFF;
    border: 1px solid #E7E7E7;
    border-radius: 16px;
`;
const ZoomSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 15px;
`;
const ZoomIconContainer = styled.div`
    margin-left: 8px;
    border: 1px solid #E7E7E7;
    border-radius: 8px;
    background: #fff;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: ${(props) => (props.minValueReached === "true" ? 0.1 : 1.0)};
    cursor: pointer;
`;
const ZoomIcon = styled.img`
    width: 60%;
    height: 60%;
    display: block;
`;
const ReadContainer = styled.div`
    margin-left: 8px;
    border: 1px solid #E7E7E7;
    border-radius: 8px;
    background: #fff;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: ${(props) => (props.minValueReached === "true" ? 0.1 : 1.0)};
    cursor: pointer;
`;
const Ricon = styled.img`
  width: 60%;
    height: 60%;
    display: block;
`;
const ReadIcon = styled.img`
    width: 60%;
    height: 60%;
    display: block;
`;
const ReadDiv = styled.div`
     margin-left: 12px;
    border: 1px solid #E7E7E7;
    border-radius: 8px;
    background: #fff;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: ${(props) => (props.minValueReached === "true" ? 0.1 : 1.0)};
    cursor: pointer;
`;
const ZoomSize = styled.div`
    margin-left: 8px;
    border: 1px solid #E7E7E7;
    border-radius: 8px;
    padding: 10px 12px;

`;
const PCount = styled.div`
    font-size: 1em;

`;
const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    /* width: 100%; */
`;
const NextButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 15px;
    width: 20px;
    height: 20px;
    cursor: pointer;
`;
const PrevIcon = styled.img`
    transform: rotate(180deg);
    display: block;
    width: 80%;
    height: 80%;
    filter: invert(0.5);
`;
const TextDiv = styled.div`
    font-size: 1rem;
`;
const Icon = styled.img`
    display: block;
    width: 80%;
    height: 80%;
    filter: invert(0.5);
`;
const Zoomoption = styled.div`
    display: flex;
    align-items: center;
`;