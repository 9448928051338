import React, { useState, useContext, useEffect } from "react";
import { Context } from "./../../../contexts/Store";
import { baseConfig } from "./../../../axiosConfig";
import Loader from "../../includes/loader/Loader";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";

import styled from "styled-components";
import AssignmentCard from "../../includes/assignmentCommonComponents/AssignmentCard";
import chatIcon from "../../../assets/icons/chat-icon.svg";
import EmptyPage from "../../includes/emptyPages/EmptyPage";

import emptyPic from "../../../assets/icons/live-class-empty-icon.svg";

export default function Assignments() {
	const [selectedAssignmentStatus, setSelectedAssignmentStatus] =
		useState("Ongoing");
	const [isLoading, setLoading] = useState(true);
	const { state } = useContext(Context);

	const [assignments, setAssignments] = useState([]);
	const [samplePdf, setSamplePdf] = useState("");
	const [snackState, setSnackState] = useState({
		open: false,
		vertical: "top",
		horizontal: "center",
	});

	const { vertical, horizontal, open } = snackState;

	const handleClick = (newState) => () => {
		setSnackState({ open: true, ...newState });
	};

	const handleClose = () => {
		setSnackState({ ...snackState, open: false });
	};

	const getAssignments = () => {
		setLoading(true);
		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`/students/student-assignments`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;

				if (StatusCode === 6000) {
					setAssignments(data.data);
					setSamplePdf(data.sample_assignment);
					setTimeout(() => {
						setLoading(false);
					}, 200);
				} else {
					setTimeout(() => {
						setLoading(false);
						alert("It is taking longer than usual");
					}, 2000);
				}
			})
			.catch((error) => {});
	};
	useEffect(() => {
		getAssignments();
	}, []);
	return (
		<MainContainer>
			{!isLoading ? (
				<>
					{assignments.length > 0 ? (
						<>
							<SectionTitleContainer>
								<SectionTitle>Assignments</SectionTitle>
								{/* {selectedAssignmentStatus === "Closed" && (
									<ChatButton>
										<ChatIcon src={chatIcon} alt="icon" />
										<ChatText>Chat Support</ChatText>
									</ChatButton>
								)} */}
								{/* {Object.keys(syllabus).length !== 0 && ( */}
								<DownloadSyllabus
									href={samplePdf}
									target="_blank"
								>
									Download Sample Assignment
								</DownloadSyllabus>
								{/* )} */}
							</SectionTitleContainer>
							<AssignmentsListContainer>
								{assignments.map((assignment) => (
									<AssignmentCard
										type="ongoing"
										data={assignment}
										key={assignment.id}
										handleClick={handleClick}
										handleClose={handleClose}
									/>
								))}
							</AssignmentsListContainer>
						</>
					) : (
						<EmptyContainer>
							<EmptyPage
								img={emptyPic}
								message="Sorry, nothing here"
							/>
						</EmptyContainer>
					)}
				</>
			) : (
				<Loader />
			)}
			<Snackbar
				anchorOrigin={{ vertical, horizontal }}
				open={open}
				onClose={handleClose}
				key={vertical + horizontal}
				message="Error Message"
				style={{ textAlign: "center" }}
			/>
		</MainContainer>
	);
}

const MainContainer = styled.div`
	background: #ffffff;
	padding: 20px 15px;
	border: 1px solid #e5e5e5;
	box-sizing: border-box;
	border-radius: 10px;
	height: calc(100vh - 110px);
	// background-color: ${(props) => props.background ? props.background : "#F5F5F5"};
	// box-shadow: ${(props) => (props.boxShadow ? props.boxShadow : "none")};
`;
const SectionTitleContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: start;
	margin-top: 20px;
	padding-right: 5px;
`;
const SectionTitle = styled.h3`
	font-size: 20px;
	font-weight: 500;
	color: #000000;
	/* margin-bottom: 40px; */
`;

const DownloadSyllabus = styled.a`
	display: inline-block;
	color: #917afd;
	font-size: 0.9rem;
	cursor: pointer;
	&:hover {
		text-decoration: underline;
	}
`;
const ChatButton = styled.div`
	padding: 10px 8px;
	border-radius: 8px;
	background: #15bf81;
	display: flex;
	align-items: center;
	cursor: pointer;
`;
const ChatIcon = styled.img`
	width: 25px;
	height: 20px;
	object-fit: contain;
`;
const ChatText = styled.div`
	margin-left: 8px;
	font-size: 14px;
	font-weight: 500;
	color: #fff;
`;
const SelectDiv = styled.div`
	display: flex;
	margin-top: 5px;
	margin-bottom: 30px;
`;
const DashboardButtonDiv = styled.div`
	display: flex;
	align-items: center;
	background: #f5f5f5;
	padding: 7px;
	border-radius: 10px;
`;
const DashboardButton = styled.div`
	background-color: ${(props) => props.background ? props.background : "#F5F5F5"};
	padding: 9px 6px;
	border-radius: 8px;
	cursor: pointer;
	box-shadow: ${(props) => (props.boxShadow ? props.boxShadow : "none")};
`;
const ButtonName = styled.h3`
	color: ${(props) => (props.color ? props.color : "#9D9D9D")};
	font-size: 0.95rem;
	font-weight: 500;
	padding: 0 10px;
	z-index: 1;
`;
const Border = styled.span`
	font-size: 1rem;
	color: #e7e7e7;
	display: inline-block;
	margin: 0 10px;
`;
const AssignmentsListContainer = styled.div`
	max-height: calc(100vh - 240px);
	box-sizing: border-box;
	overflow-y: scroll;
	padding: 10px 8px 10px 5px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 20px;
	/* padding-top:10px;
    padding-bottom:10px; */
	padding-right: 8px;
	&::-webkit-scrollbar {
		width: 2px;
	}
	&::-webkit-scrollbar-track {
		/* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
		background-color: transparent;
	}
	::-webkit-scrollbar-thumb {
		/* background-color: #9A4BCB; */
		/* outline: 1px solid slategrey; */
		background-color: #d6dee1;
		border-radius: 20px;
	}
	/* border: 1px solid #E5E5E5; */
	@media (max-width: 1180px) {
		grid-template-columns: 1fr;
	}
`;
const EmptyContainer = styled.div`
	height: calc(100vh - 145px);
	overflow-y: scroll;
`;
const SnackText = styled.p``;
