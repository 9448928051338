import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

function AssignmentCards({ item, index }) {
	console.log(item);
	const[feed,setFeed] = useState(0);
	let menuRef = useRef();
	useEffect(()=>{
		let handler = (e)=>{
			if(!menuRef.current.contains(e.target)){
				setFeed(0);
			}
		};
		document.addEventListener('mousedown',handler);
		return()=>{
			document.removeEventListener('mousedown',handler);
		}
	})
	console.log(feed);

	return (
		<Container>
			<Card>
				<CardTop>
					<Left>
						<SlNo>{index}.</SlNo>
						<Question>{item?.title.replace("(Closed)",'')}</Question>
					</Left>
					<Right itemCount={item?.questions_mark?.length + 1} ref={menuRef}>
						{item.questions_mark?.map((question) => (
							<ScoreCard>
								<QuestionNo>
									Question {question.question_no}
								</QuestionNo>
								<Scrore>
									Score : {question.mark}
									{/* Score : {parseInt(question.mark)} */}
								</Scrore>
								<Feedback 
									className={question.feedback ? "click" : ""}
									onClick={()=>setFeed(question.question_no)}>
									Feedback
								</Feedback>
								<PopupArrow 
									src={require("../../../assets/images/popuparrow.svg").default}
									className={feed === question.question_no && question.feedback ? "popup" : ""}
								/>
								<Popup className={feed === question.question_no && question.feedback ? "popup" : ""}>
									<Heading>Feedback</Heading>
									<Content>
										{question.feedback ? question.feedback : "Feedback not available"}
									</Content>
								</Popup>
							</ScoreCard>
						))}
						{/* <ScoreCard>
							<QuestionNo>Question 1</QuestionNo>
							<Scrore>Score : 33</Scrore>
						</ScoreCard>
						<ScoreCard>
							<QuestionNo>Question 1</QuestionNo>
							<Scrore>Score : 33</Scrore>
						</ScoreCard>
						<ScoreCard>
							<QuestionNo>Question 1</QuestionNo>
							<Scrore>Score : 33</Scrore>
						</ScoreCard> */}
						<ScoreCard className="total">
							<QuestionNo className="total">Total</QuestionNo>
							<Scrore className="total">{item.mark}</Scrore>
						</ScoreCard>
					</Right>
				</CardTop>
				{item.submittes_file && (
					<CardBottom>
						<ViewSubmission
							onClick={() => {
								window.open(item.submitted_file);
							}}
						>
							View Submission
						</ViewSubmission>
					</CardBottom>
				)}
			</Card>
		</Container>
	);
}

export default AssignmentCards;

const Container = styled.div``;
const Card = styled.div`
	padding: 12px;
	border: 1px solid #e7e7e7;
	border-radius: 16px;
	margin-bottom: 10px;
`;
const CardTop = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 5px;
`;
const Left = styled.div`
	width: 40%;
	display: flex;
`;
const SlNo = styled.h2`
	color: #010101;
	font-size: 16px;
	margin-right: 10px;
`;
const Question = styled.h4`
	color: #010101;
	font-size: 16px;
	width: 90%;
`;

const Right = styled.div`
	width: 60%;
	/* display: grid; */
	/* grid-template-columns: 1fr 1fr 1fr 1fr 1fr; */
	/* grid-template-columns: repeat(auto-fit, minmax(145px, 1fr)); */
	/* grid-template-columns: repeat(5, 145px); */
	/* grid-template-columns: ${({ itemCount }) =>
		`repeat(${itemCount}, 145px)`}; */
	/* grid-template-columns: ${(props) =>
		props.itemCount
			? `repeat(${props.itemCount}, 145px)`
			: `repeat(5, 145px)`};

    grid-gap: 10px; */
	gap: 10px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;
const ScoreCard = styled.div`
	border: 1px solid #e7e7e7;
	border-radius: 16px;
	padding: 10px;
	width: 145px;
	position: relative;
	&.total {
		background-color: #e7e7e7;
		padding: 21px 10px;
	}
`;
const QuestionNo = styled.h4`
	color: #9d9d9d;
	font-size: 14px;
	text-align: center;
	margin-bottom: 4px;
	&.total {
		color: #010101;
	}
`;
const Scrore = styled.h4`
	color: #010101;
	text-align: center;
	font-size: 14px;
	margin-bottom: 4px;
	&.total {
		font-size: 16px;
	}
`;
const Feedback = styled.div`
	color: #917AFD;
	background: #EDF5FE;
	border-radius: 4px;
	padding: 4px 0px;
	font-size: 14px;
	text-align: center;
	font-family: 'inter-light';
	&.click{
		background:#F5F5F5;
		cursor: pointer;
	}
`;
const PopupArrow =styled.img`
	display: none;
	&.popup{
		width: 25px;
		position: absolute;
		bottom: -14.5px;
		right: 20px;
		display: block;
		z-index: 101;
	}
`;
const Popup = styled.div`
	display: none;
	&.popup{
		position: absolute;
		top: 100px;
		right: 0px;
		width: 250px;
		display: block;
		border: 1px solid #917AFD;
		padding: 8px;
		border-radius: 4px;
		z-index: 100;
		background: #ffffff;
		box-shadow: 0px 14px 24px 0px #0000001A;
		@media all and (max-width: 1198px){
			top: 116.5px;
		}
		@media all and (max-width: 1182px){
			top: 134px;
		}
	}
	
`;
const Heading = styled.h4`
	color: #404040;
	font-size: 16px;
	margin-bottom: 10px;
	font-family: "inter-semibold";
`;
const Content = styled.p`
	color: #404040;
	font-size: 14px;
	font-family: "inter-light";
`;
const CardBottom = styled.div`
	margin-left: 15px;
`;
const ViewSubmission = styled.div`
	color: #917afd;
	background: #edf5fe;
	border-radius: 4px;
	width: max-content;
	padding: 5px 12px;
	font-size: 14px;
	cursor: pointer;
`;
