import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import LiveClassCard from "../../includes/liveClasses/LiveClassCard";
import { Context } from "./../../../contexts/Store";
import { baseConfig } from "./../../../axiosConfig";

import pic1 from "../../../assets/images/live-class-icon1.svg";
import pic2 from "../../../assets/images/live-class-icon2.svg";
import pic3 from "../../../assets/images/live-class-icon3.svg";

import emptyPic from "../../../assets/icons/live-class-empty-icon.svg";
import EmptyPage from "../../includes/emptyPages/EmptyPage";
import Loader from "../../includes/loader/Loader";
import VideoModal from "../../includes/VideoModal";
import Pagination from "../../includes/Pagination";

export default function LiveClasses() {
    const [selectedProgramStatus, setSelectedProgramStatus] =
        useState("Upcoming");
    const [isLoading, setLoading] = useState(true);
    const [liveData, setLiveData] = useState([{ id: 1, title: "ghg" }]);
    const { state } = useContext(Context);
    const [completedVideoLink, setCompletedVideoLink] = useState("");
    const [paginationData, setPaginationData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);

    const getLiveClasses = () => {
        setLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/students/student-live-classes/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    status: selectedProgramStatus,
                    page: currentPage,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;

                if (StatusCode === 6000) {
                    setLiveData(data.data);
                    setPaginationData(data.paginator);
                    setTimeout(() => {
                        setLoading(false);
                    }, 200);
                } else {
                    setTimeout(() => {
                        setLoading(false);
                        alert("It is taking longer than usual");
                    }, 2000);
                }
            })

            .catch((error) => {});
    };
    useEffect(() => {
        getLiveClasses();
    }, [selectedProgramStatus]);

    // For Modal
    const [isVideoModal, setIsVideoModal] = useState(false);
    const [isVideoLoading, setIsVideoLoading] = useState(false);

    const handleOpenVideoModal = () => {
        setIsVideoModal(true);
    };
    const handleCloseVideoModal = () => {
        setIsVideoModal(false);
    };

    return (
        <MainContainer>
            <>
                <SectionTitle>Live Classes</SectionTitle>
                <SelectDiv>
                    <ProgramButtonDiv>
                        {/* <ProgramButton
                            background={
                                selectedProgramStatus === "Live"
                                    ? "#fff"
                                    : "#F5F5F5"
                            }
                            boxShadow={
                                selectedProgramStatus === "Live"
                                    ? "box-shadow: 0px 8px 29px rgba(0, 0, 0, 0.05)"
                                    : "none"
                            }
                            // style={{ marginRight: "10px" }}
                            onClick={(e) => {
                                e.preventDefault();
                                setSelectedProgramStatus("Live");
                            }}
                        >
                            <ButtonName
                                color={
                                    selectedProgramStatus === "Live"
                                        ? "#404040"
                                        : "#9D9D9D"
                                }
                            >
                                Live
                            </ButtonName>
                        </ProgramButton>
                        <Border>|</Border> */}

                        <ProgramButton
                            background={
                                selectedProgramStatus === "Upcoming"
                                    ? "#fff"
                                    : "#F5F5F5"
                            }
                            boxShadow={
                                selectedProgramStatus === "Upcoming"
                                    ? " 0px 6px 20px rgba(0, 0, 0, 0.16);"
                                    : "none"
                            }
                            // style={{ marginRight: "10px" }}
                            onClick={() => {
                                setSelectedProgramStatus("Upcoming");
                            }}
                        >
                            <ButtonName
                                color={
                                    selectedProgramStatus === "Upcoming"
                                        ? "#404040"
                                        : "#9D9D9D"
                                }
                            >
                                Upcoming
                            </ButtonName>
                        </ProgramButton>
                        <Border>|</Border>

                        <ProgramButton
                            background={
                                selectedProgramStatus === "Completed"
                                    ? "#fff"
                                    : "#F5F5F5"
                            }
                            boxShadow={
                                selectedProgramStatus === "Completed"
                                    ? " 0px 8px 29px rgba(0, 0, 0, 0.05)"
                                    : "none"
                            }
                            onClick={(e) => {
                                e.preventDefault();
                                setSelectedProgramStatus("Completed");
                            }}
                        >
                            <ButtonName
                                color={
                                    selectedProgramStatus === "Completed"
                                        ? "#404040"
                                        : "#9D9D9D"
                                }
                            >
                                Completed
                            </ButtonName>
                        </ProgramButton>
                    </ProgramButtonDiv>
                </SelectDiv>
                {!isLoading ? (
                    <>
                        {liveData.length > 0 ? (
                            <DetailedContainer>
                                <ContentSection>
                                    {liveData.map((item) => (
                                        <LiveClassCard
                                            data={item}
                                            key={item.id}
                                            type={selectedProgramStatus}
                                            isVideoModal={isVideoModal}
                                            setIsVideoModal={setIsVideoModal}
                                            handleOpenVideoModal={
                                                handleOpenVideoModal
                                            }
                                            handleCloseVideoModal={
                                                handleCloseVideoModal
                                            }
                                            setCompletedVideoLink={
                                                setCompletedVideoLink
                                            }
                                            setIsVideoLoading={
                                                setIsVideoLoading
                                            }
                                        />
                                    ))}
                                </ContentSection>
                                <Pagination
                                    paginationData={paginationData}
                                    setCurrentPage={setCurrentPage}
                                    currentPage={currentPage}
                                />
                            </DetailedContainer>
                        ) : (
                            <EmptyContainer>
                                <EmptyPage
                                    img={emptyPic}
                                    message={
                                        selectedProgramStatus === "Upcoming"
                                            ? "You have no live or upcoming classes"
                                            : "You have no completed classes"
                                    }
                                />
                            </EmptyContainer>
                        )}
                    </>
                ) : (
                    <Loader />
                )}
                {isVideoModal === true && (
                    <VideoModalBackContainer
                        onClick={() => {
                            setIsVideoModal(false);
                        }}
                    >
                        {/* {!isVideoLoading ? ( */}
                        <CenderDiv>
                            <VideoModal
                                isVideoModal={isVideoModal}
                                setIsVideoModal={setIsVideoModal}
                                handleOpenVideoModal={handleOpenVideoModal}
                                handleCloseVideoModal={handleCloseVideoModal}
                                completedVideoLink={completedVideoLink}
                                setIsVideoLoading={setIsVideoLoading}
                                isVideoLoading={isVideoLoading}
                            />
                        </CenderDiv>
                        {/* ) : ( */}
                        {/* <Loader /> */}
                        {/* )} */}
                    </VideoModalBackContainer>
                )}
            </>
        </MainContainer>
    );
}

const MainContainer = styled.div`
    background: #fff;
    padding: 25px 20px;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 10px;
    height: calc(100vh - 110px);
    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 0px !important;
        display: none;
    }
    &::-webkit-scrollbar {
        width: 0 !important;
        display: none;
    }
    /* &::-webkit-scrollbar {
        width: 3px;
    }
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #d6dee1;
        border-radius: 20px;
    } */
    /* ::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 0;
    } */
`;
const SectionTitle = styled.h3`
    font-size: 1.25rem;
    font-weight: 500;
    color: #000000;
`;
const SelectDiv = styled.div`
    display: flex;
    margin-top: 30px;
`;
const ProgramButtonDiv = styled.div`
    display: flex;
    align-items: center;
    background: #f5f5f5;
    padding: 5px;
    border-radius: 10px;
`;
const ProgramButton = styled.div`
    background-color: ${(props) =>
        props.background ? props.background : "#F5F5F5"};
    padding: 8px 8px;
    width: 120px;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: ${(props) => (props.boxShadow ? props.boxShadow : "none")};
`;
const Border = styled.span`
    font-size: 1rem;
    color: #e7e7e7;
    display: inline-block;
    margin: 0 10px;
`;
const ButtonName = styled.h3`
    color: ${(props) => (props.color ? props.color : "#9D9D9D")};
    font-size: 0.95rem;
    font-weight: 500;
    padding: 0 10px;
    text-align: center;
`;
const DetailedContainer = styled.div`
    margin-top: 10px;
    height: calc(100vh - 270px);
    /* min-height: calc(100vh - 295px); */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: scroll;
    padding: 10px 0;
    ::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 0;
    }
`;
const ContentSection = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    /* border: 1px solid #e5e5e5; */
    grid-gap: 20px;
    /* &::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 0px;
        display: none;
    }
    &::-webkit-scrollbar {
        width: 0 !important;
        display: none;
    } */
    @media (max-width: 1500px) {
        grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 880px) {
        grid-template-columns: 1fr;
    }
`;
const EmptyContainer = styled.div`
    height: calc(100vh - 295px);
    overflow-y: scroll;
    /* ::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 0;
    } */
`;
const VideoModalBackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    width: 100%;
    height: 100vh;
    z-index: 1002;
    left: 0;
    top: 0px;
    background: rgba(0, 0, 0, 0.3);

    /* cursor: not-allowed; */
`;
const CenderDiv = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

// const ContentSection = styled.div``;
