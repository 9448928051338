import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import money from "../../../assets/icons/money .png";
import Payment from "../../../assets/icons/payment-now-icon.svg";
import coursefee from "../../../assets/icons/course-fee-icon.svg";
import paidfee from "../../../assets/icons/payment-now.png";
import paidbill from "../../../assets/icons/paid-bill.png";
import mail from "../../../assets/images/Mail image.png";
import doller from "../../../assets/images/Money image.png";
import bill from "../../../assets/images/Bill Image.png";
import moment from "moment";
import StatusButton from "../../includes/statusButton/StatusButton";

import { baseConfig } from "../../../axiosConfig";
import { Context } from "../../../contexts/Store";
import Loader from "../../includes/loader/Loader";

export default function Payments() {
	const [isLoading, setLoading] = useState(true);
	const { state } = useContext(Context);
	const [paymentData, setPaymentData] = useState({});

	const getPayments = (id) => {
		setLoading(true);
		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`/students/student-payment-data/`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;

				if (StatusCode === 6000) {
					setPaymentData(data);
					setTimeout(() => {
						setLoading(false);
					}, 200);
				} else {
					setTimeout(() => {
						setLoading(false);
						alert("It is taking longer than usual");
					}, 200);
				}
			})

			.catch((error) => {});
	};

	useEffect(() => {
		getPayments();
	}, []);

	return (
		<MainContainer>
			{isLoading ? (
				<Loader />
			) : (
				<>
					{paymentData?.due_emis?.length > 0 && (
						<>
							<Title>Payments</Title>

							<PaymentDiv>
								<SubContainer>
									<TopSection>
										<MoneyIcon src={money} alt="Icon" />
										<FeeTitle>Total Due </FeeTitle>
									</TopSection>
									<DetaileSection>
										<PlanDiv>
											<FeePlanText>Fee Plan</FeePlanText>
										</PlanDiv>
										<PlanDiv>
											<FeePlanText>Due Date</FeePlanText>
										</PlanDiv>
										<PlanDiv>
											<FeePlanText>
												Due Amount
											</FeePlanText>
										</PlanDiv>
										<PlanDiv>
											<FeePlanText>Status</FeePlanText>
										</PlanDiv>
									</DetaileSection>
									{paymentData?.due_emis.map((item, i) => (
										<DetaileSection
											style={{ height: "40px" }}
										>
											<PlanDiv>
												<PlanOption>
													{item.instalment}
												</PlanOption>
											</PlanDiv>
											<PlanDiv>
												<PlanOption>
													{item.due_date}
												</PlanOption>
											</PlanDiv>
											<PlanDiv>
												<PlanOption>
													{item.total_amount}
												</PlanOption>
											</PlanDiv>
											<PlanDiv>
												<StatusButton
													status={item.status}
													text={item.status}
												/>
											</PlanDiv>
										</DetaileSection>
									))}
								</SubContainer>
							</PaymentDiv>
						</>
					)}
					{paymentData?.payment_data.map((data, i) => (
						<div key={i}>
							<Title>{data?.course}</Title>

							<CardContainer>
								<AmountCard>
									<CardSection>
										<CourseCardSub>
											<CourseLeft>
												<CIcon
													src={coursefee}
													alt="icon"
												/>
												<CourseFeeText>
													TOTAL COURSE FEE
												</CourseFeeText>
												<CAmountFeeText>
													{data?.total_course_fee.toLocaleString(
														"en-US"
													)}{" "}
													<AmtType>AED</AmtType>{" "}
												</CAmountFeeText>
											</CourseLeft>
											<CourseRight>
												<CourseAmountIcon
													src={mail}
													alt="Image"
												/>
											</CourseRight>
										</CourseCardSub>
									</CardSection>

									<PaidSection>
										<PaidCardSub>
											<PaidLeft>
												<PaidIcon
													src={paidfee}
													alt="icon"
												/>
												<PaidFeeText>
													FEES PAID
												</PaidFeeText>
												<PAmountFeeText>
													{data?.total_paid.toLocaleString(
														"en-US"
													)}{" "}
													<PAmtType>AED</PAmtType>{" "}
												</PAmountFeeText>
											</PaidLeft>
											<PaidRight>
												<PaidAmountIcon
													src={doller}
													alt="Image"
												/>
											</PaidRight>
										</PaidCardSub>
									</PaidSection>

									<BSection>
										<BCardSub>
											<BLeft>
												<BalanceIcon
													src={paidbill}
													alt="icon"
												/>
												<BFeeText>
													OUTSTANDING FEE
												</BFeeText>
												<BAmountFeeText>
													{data?.total_due.toLocaleString(
														"en-US"
													)}{" "}
													<BAmtType>AED</BAmtType>{" "}
												</BAmountFeeText>
											</BLeft>
											<BRight>
												<BAmountIcon
													src={bill}
													alt="Image"
												/>
											</BRight>
										</BCardSub>
									</BSection>
								</AmountCard>
							</CardContainer>
							<TableSection>
								<TableTitle>Fee Structure</TableTitle>

								<TableDiv>
									<THead>
										<TRHead>
											<TH>
												<TblTitle>No</TblTitle>
											</TH>
											<TH>
												<TblTitle>EMI amt</TblTitle>
											</TH>
											{/* <TH>
												<TblTitle>Payment</TblTitle>
											</TH> */}
											<TH>
												<TblTitle>Due date</TblTitle>
											</TH>

											<TH>
												<TblTitle>EMI Paid</TblTitle>
											</TH>
											<TH>
												<TblTitle>DOP</TblTitle>
											</TH>
											<TH>
												<TblTitle>MOP</TblTitle>
											</TH>
											<TH>
												<TblTitle>Balance amt</TblTitle>
											</TH>
											<TH>
												<TblTitle>Status</TblTitle>
											</TH>
											{/* <TH>
										<TblTitle></TblTitle>
									</TH> */}
										</TRHead>
									</THead>

									<TBody>
										{data.emis.map((item, index) => (
											<TR id={item.id} key={index}>
												<TD>
													<TDText>
														EMI {index + 1}
													</TDText>
												</TD>
												<TD>
													<TDText>
														{item.total_amount}
													</TDText>
												</TD>
												<TD>
													<TDText>
														{item.due_date
															? moment(
																	item.due_date
															  ).format(
																	"DD-MMM-YYYY"
															  )
															: null}
													</TDText>
												</TD>
												{/* <TD>
													<TDText>
														{item.total_amount}
													</TDText>
												</TD> */}
												<TD>
													<TDText>
														{item.paid_amount}
													</TDText>
												</TD>
												<TD>
													<TDText>
														{item.dop
															? moment(
																	item.dop
															  ).format(
																	"DD-MMM-YYYY"
															  )
															: null}
													</TDText>
												</TD>
												<TD>
													<TDText>{item.mop}</TDText>
												</TD>
												<TD>
													<TDText>
														{item.balance_amount}
													</TDText>
												</TD>
												<TD>
													<StatusButton
														status={item.status}
														text={item.status}
													/>
												</TD>
												{/* <TD>
												<TButton>
												<TButtonIcon src={Payment} /> <TPaymentNow>Pay Now</TPaymentNow>
											</TButton>
											</TD> */}
											</TR>
										))}
									</TBody>
								</TableDiv>
							</TableSection>
						</div>
					))}
				</>
			)}
		</MainContainer>
	);
}

const MainContainer = styled.div`
	background: #ffffff;
	padding: 20px 34px;
	border: 1px solid #e5e5e5;
	box-sizing: border-box;
	border-radius: 10px;
	height: calc(100vh - 110px);
	overflow-y: scroll;
`;
const Title = styled.div`
	font-weight: 500;
	font-size: 24px;
	line-height: 20px;
	color: #000;
	/* margin-top: 20px; */
`;
const PaymentDiv = styled.div`
	margin: 20px 0 30px;
	background: #ffffff;
	border: 1px solid #e5e5e5;
	border-radius: 16px;
`;
const SubContainer = styled.div`
	padding: 30px;
`;

const TopSection = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 30px;
`;

const MoneyIcon = styled.img`
	display: block;
	width: 84px;
	height: 84px;
	object-fit: contain;
`;
const FeeTitle = styled.h4`
	margin-left: 34px;
	font-weight: 400;
	font-size: 24px;
	line-height: 20px;
`;
const DetaileSection = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	/* display: flex; */
	align-items: center;
	/* justify-content: space-between; */
	/* margin-top: 30px; */
`;
const PlanDiv = styled.div``;
const FeePlanText = styled.h4`
	color: #9d9d9d;
	font-weight: 400;
	font-size: 18px;

	/* line-height: 20px; */
`;
const PlanOption = styled.h3`
	color: #010101;
	font-weight: 500;
	font-size: 18px;
	/* margin-top: 10px; */
	text-transform: capitalize;
`;
const DateDiv = styled.div``;
const DueDate = styled.h4`
	color: #9d9d9d;
	font-weight: 500;
	font-size: 20px;
	line-height: 20px;
`;
const DateText = styled.h3`
	color: #010101;
	font-weight: 400;
	font-size: 20px;
	line-height: 20px;
	/* margin-top: 10px; */
`;
const DueAmount = styled.div``;
const DueText = styled.h4`
	color: #9d9d9d;
	font-weight: 500;
	font-size: 20px;
	line-height: 20px;
`;
const AmountText = styled.h4`
	color: #010101;
	font-weight: 500;
	font-size: 20px;
	line-height: 20px;
	/* margin-top: 10px; */
`;
const PaymentButton = styled.button`
	display: block;
	display: flex;
	align-items: center;
	padding: 10px 15px;
	background: #010101;
	border-radius: 8px;
	cursor: pointer;
`;
const BIcon = styled.img`
	display: block;
	height: 12px;
	width: 16px;
	object-fit: contain;
`;
const PaymentNow = styled.span`
	margin-left: 12px;
	color: #fff;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
`;
const CardContainer = styled.div`
	margin-top: 24px;
`;

const AmountCard = styled.div`
	display: grid;
	gap: 20px;
	grid-template-columns: 1fr 1fr 1fr;
`;
const CardSection = styled.div`
	background: linear-gradient(99.79deg, #917afd 0.79%, #917afd 98.49%);
	box-shadow: 0px 4px 4px rgba(184, 184, 184, 0.25);
	border-radius: 8px;
`;
const CourseCardSub = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 32px;
`;
const CourseLeft = styled.div``;
const CIcon = styled.img`
	display: block;
	width: 35px;
	height: 35px;
	object-fit: contain;
`;
const CourseFeeText = styled.h4`
	margin-top: 24px;
	color: #e7e7e7;
	font-weight: 600;
	font-size: 14px;
	line-height: 21px;
`;
const CAmountFeeText = styled.h4`
	margin-top: 8px;
	color: #ffffff;
	font-weight: 600;
	font-size: 24px;
	line-height: 36px;
`;
const AmtType = styled.span`
	font-size: 12px;
	color: #ffffff;
`;
const CourseAmountIcon = styled.img`
	display: block;
	object-fit: contain;
	width: 155px;
	height: 155px;
`;
const CourseRight = styled.div``;

const PaidSection = styled.div`
	background: #fd962f;
	box-shadow: 0px 4px 4px rgba(184, 184, 184, 0.25);
	border-radius: 8px;
`;
const PaidCardSub = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 32px;
`;
const PaidLeft = styled.div``;
const PaidIcon = styled.img`
	display: block;
	width: 35px;
	height: 35px;
	object-fit: contain;
`;
const PaidFeeText = styled.h4`
	margin-top: 24px;
	color: #e7e7e7;
	font-weight: 600;
	font-size: 14px;
	line-height: 21px;
`;
const PAmountFeeText = styled.h4`
	margin-top: 8px;
	color: #ffffff;
	font-weight: 600;
	font-size: 24px;
	line-height: 36px;
`;
const PAmtType = styled.span`
	font-size: 12px;
	color: #ffffff;
`;
const PaidAmountIcon = styled.img`
	display: block;
	object-fit: contain;
	width: 155px;
	height: 155px;
`;
const PaidRight = styled.div``;

const BSection = styled.div`
	background: #54b862;
	box-shadow: 0px 4px 4px rgba(184, 184, 184, 0.25);
	border-radius: 8px;
`;
const BCardSub = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 32px;
`;
const BLeft = styled.div``;
const BalanceIcon = styled.img`
	display: block;
	width: 35px;
	height: 35px;
	object-fit: contain;
`;
const BFeeText = styled.h4`
	margin-top: 24px;
	color: #e7e7e7;
	font-weight: 600;
	font-size: 14px;
	line-height: 21px;
`;
const BAmountFeeText = styled.h4`
	margin-top: 8px;
	color: #ffffff;
	font-weight: 600;
	font-size: 24px;
	line-height: 36px;
`;
const BAmtType = styled.span`
	font-size: 12px;
	color: #ffffff;
`;
const BAmountIcon = styled.img`
	display: block;
	object-fit: contain;
	width: 155px;
	height: 155px;
`;
const BRight = styled.div``;

const TableSection = styled.div`
	margin-top: 46px;
`;
const TableTitle = styled.h4`
	color: #404040;
	font-weight: 500;
	font-size: 20px;
	line-height: 20px;
`;
const TableDiv = styled.table`
	margin-top: 20px;
	padding: 20px;
	background: #ffffff;
	box-shadow: 0px 4px 24px rgba(164, 164, 164, 0.25);
	border-radius: 16px;
	width: 100%;
`;
const THead = styled.thead`
	display: block;
	width: 100%;
	background: #f3f3f3;
	border: 1px solid #e5e5e5;
	border-radius: 8px;
`;
const TRHead = styled.tr`
	display: flex;
	align-items: center;
	padding: 15px 14px;
`;
const TH = styled.th`
	/* border-collapse: collapse; */
	text-align: left;
	color: #fff;
	white-space: nowrap;
	/* flex-basis: 100%; */
	flex-grow: 2;
	width: 11%;
	:first-child {
		width: 50px;
	}
`;
const TblTitle = styled.p`
	font-size: 14px;
	color: #000;
`;
const TBody = styled.tbody`
	display: block;
	width: 100%;
	overflow-y: scroll;
	height: 400px;
	&::-webkit-scrollbar {
		width: 8px;
		background: #f2f2f2;
		border-radius: 5px;
	}
	&::-webkit-scrollbar-thumb {
		background: #c1c1c1;
		border-radius: 10px;
		opacity: 0.5;
	}
`;

const TR = styled.tr`
	width: 100%;
	display: flex;
	height: 50px;
	align-items: center;
	/* justify-content: center; */
	justify-content: space-between;
	padding: 0px 20px;
	/* margin-top: 7px; */
`;

const TD = styled.td`
	/* border-collapse: collapse; */
	/* white-space: nowrap; */
	/* flex-basis: 100%; */
	/* flex-grow: 2; */
	display: block;
	color: #fff;
	flex-grow: 2;
	font-size: 14px;
	align-items: center;
	display: flex;

	width: 11%;
	&:first-child {
		width: 50px;
	}
`;
const TDText = styled.p`
	font-size: 14px;
	color: #000;
`;
const TButton = styled.button`
	display: block;
	display: flex;
	align-items: center;
	padding: 8px 10px;
	background: #010101;
	border-radius: 8px;
	cursor: pointer;
`;
const TButtonIcon = styled.img`
	display: block;
	height: 12px;
	width: 13px;
	object-fit: contain;
`;
const TPaymentNow = styled.span`
	margin-left: 6px;
	color: #fff;
	font-weight: 500;
	font-size: 13px;
	line-height: 20px;
`;
