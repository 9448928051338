import React from "react";
import "./assets/css/style.css";
import Store from "./contexts/Store";
import MainRouter from "./routing/routers/MainRouter";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TopUpStore from "./contexts/TopUpContext/TopUpStore";

function App() {
	return (
		<Store>
			<TopUpStore>
				<MainRouter />
			</TopUpStore>
		</Store>
	);
}

export default App;
