import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const DonutGraph = ({ percentage, isDecimal = false, decimal }) => {
    return (
        // <CircularProgressbar
        //     value={percentage}
        //     text={`${percentage}%`}
        //     styles={buildStyles({
        //         // Rotation of path and trail, in number of turns (0-1)
        //         rotation: 0.25,

        //         // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
        //         strokeLinecap: "butt",

        //         // Text size
        //         textSize: "22px",

        //         // How long animation takes to go from one percentage to another, in seconds
        //         pathTransitionDuration: 0.5,
        //         // Colors
        //         pathColor: `#917AFD`,
        //         textColor: "#000",
        //     })}
        // />
        <CircularProgressbar
            value={Math.round(percentage)}
            text={
                isDecimal ? (
                    <tspan>{decimal}</tspan>
                ) : (
                    <tspan>{Math.round(percentage)}%</tspan>
                )
            }
            styles={buildStyles({
                strokeLinecap: "round",
                // strokeWidth: 20,
                textSize: "20px",
                pathTransitionDuration: 0.5,
                pathColor: `#917AFD`,
                textColor: "#000",
                trailColor: "#f5f5f5",
            })}
        />
    );
};

export default DonutGraph;
