import React, { Fragment, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import AssignmentCards from "./AssignmentCards";
import ExamResult from "./ExamResult";
import { baseConfig } from "../../../axiosConfig";
import { Context } from "../../../contexts/Store";
import { useLocation, useHistory } from "react-router-dom";
import Loader from "../../includes/loader/Loader";
import emptyPic from "../../../assets/icons/live-class-empty-icon.svg";
import EmptyPage from "../../includes/emptyPages/EmptyPage";
import { TopUpContext } from "../../../contexts/TopUpContext/TopUpStore";

function Result({ universityModel }) {
	const tab = [
		{
			id: 2,
			value: "assignment",
			text: "Assignments",
		},
		// {
		// 	id: 1,
		// 	value: "exam",
		// 	text: "Exam",
		// },
	];

	const sem = [
		{
			id: 1,
			value: "sem-1",
			text: "Sem 1",
		},
		{
			id: 2,
			value: "sem-2",
			text: "Sem 2",
		},
		{
			id: 3,
			value: "sem-3",
			text: "Sem 3",
		},
		{
			id: 4,
			value: "sem-4",
			text: "Sem 4",
		},
		{
			id: 5,
			value: "sem-5",
			text: "Sem 5",
		},
		{
			id: 6,
			value: "sem-6",
			text: "Sem 6",
		},
	];

	const location = useLocation();
	const history = useHistory();
	const params = new URLSearchParams(location.search);
	const [isLoading, setLoading] = useState(true);

	const sectionParam = params.get("section");
	const periodParam = params.get("period");

	const [selectedTab, setSelectedTab] = useState(sectionParam);
	const [selectedSem, setSelectedSem] = useState(Number(periodParam));
	const [resultData, setResultData] = useState([]);
	const { state } = useContext(Context);
	const { topUpState } = useContext(TopUpContext);

	const resultApi = () => {
		setLoading(true);
		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`/students/student-results/`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
				params: {
					period: periodParam,
					section: sectionParam,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;

				if (StatusCode === 6000) {
					setResultData(data);
					setTimeout(() => {
						setLoading(false);
					}, 200);
				} else {
					setTimeout(() => {
						setLoading(false);
						alert("It is taking longer than usual");
					}, 200);
				}
			})

			.catch((error) => {});
	};

	useEffect(() => {
		resultApi();
		return () => {
			setLoading(false);
		};
	}, [periodParam, sectionParam]);

	return (
		<>
			<MainContainer>
				<SectionTitle>Results</SectionTitle>

				<TabView>
					<SelectDiv>
						<ProgramButtonDiv>
							{tab.map((item, i) => (
								<Fragment key={i}>
									{universityModel !== "examination model" && item.value === "exam" ? null : (
										<>
											<ProgramButton
												background={sectionParam === item.value ? "#fff" : "#F5F5F5"}
												boxShadow={
													sectionParam === item.value
														? "0px 6px 20px rgba(0, 0, 0, 0.1)"
														: "none"
												}
												// style={{ marginRight: "10px" }}
												onClick={() => {
													// setSelectedTab(item.value);
													history.push(
														`/results?section=${item.value}&period=${periodParam}`
													);
												}}>
												<ButtonName color={sectionParam === item.value ? "#404040" : "#9D9D9D"}>
													{item.text}
												</ButtonName>
											</ProgramButton>
											{/* {i + 1 !== tab.length &&  (
												// universityModel !==
												// 	"examination model" &&
												// item.value === "exam" &&
												<Border>|</Border>
											)} */}
										</>
									)}
								</Fragment>
							))}
						</ProgramButtonDiv>
					</SelectDiv>

					<SelectDiv>
						{topUpState?.top_up_details?.selected_program?.periods?.length > 0 && (
							<ProgramButtonDiv>
								{topUpState?.top_up_details?.selected_program?.periods?.map((item, i) => (
									<Fragment key={i}>
										<ProgramButton
											background={Number(periodParam) === item.period ? "#fff" : "#F5F5F5"}
											boxShadow={
												Number(periodParam) === item.period
													? " 0px 8px 29px rgba(0, 0, 0, 0.1)"
													: "none"
											}
											// style={{ marginRight: "10px" }}
											onClick={() => {
												// setSelectedSem(item.id);
												history.push(`/results?section=${sectionParam}&period=${item.period}`);
											}}>
											<ButtonName color={Number(periodParam) === item.id ? "#404040" : "#9D9D9D"}>
												{topUpState?.top_up_details?.selected_program?.semester_oriented
													? `Sem`
													: `Year`}{" "}
												{item.period}
											</ButtonName>
										</ProgramButton>
										{i + 1 !== sem.length && <Border>|</Border>}
									</Fragment>
								))}
							</ProgramButtonDiv>
						)}
					</SelectDiv>
				</TabView>

				{!isLoading ? (
					<>
						{/* {sectionParam === "exam" ? (
							<>
								{Object.keys(resultData).length > 0 ? (
									<ExamResult data={resultData} />
								) : (
									<EmptyContainer>
										<EmptyPage img={emptyPic} message="Sorry, nothing here" />
									</EmptyContainer>
								)}
							</>
						) : resultData?.data?.length > 0 ? ( */}
						{resultData?.data?.length > 0 ? (
							<>
								{resultData.data.map((item, i) => (
									<AssignmentCards key={i} item={item} index={i + 1} />
								))}
								<DownloadButtonContainer>
									<DownloadButton
										onClick={() => {
											window.open(resultData.result_link, "_blank", "noreferrer");
										}}>
										Download Grade Card
									</DownloadButton>
								</DownloadButtonContainer>
							</>
						) : (
							<EmptyContainer>
								<EmptyPage img={emptyPic} message="Sorry, nothing here" />
							</EmptyContainer>
						)}
					</>
				) : (
					<Loader />
				)}
			</MainContainer>
		</>
	);
}

export default Result;

const MainContainer = styled.div`
	background: #fff;
	padding: 25px 20px;
	border: 1px solid #e5e5e5;
	box-sizing: border-box;
	border-radius: 10px;
	height: calc(100vh - 110px);
	overflow-y: scroll;
	&::-webkit-slider-thumb {
		-webkit-appearance: none;
		width: 0px !important;
		display: none;
	}
	&::-webkit-scrollbar {
		width: 0 !important;
		display: none;
	}
`;
const SectionTitle = styled.h3`
	font-size: 1.25rem;
	font-weight: 500;
	color: #000000;
`;
const TabView = styled.div`
	display: flex;
	margin-top: 30px;
	margin-bottom: 20px;
	gap: 60px;
`;
const SelectDiv = styled.div`
	display: flex;
`;
const ProgramButtonDiv = styled.div`
	display: flex;
	align-items: center;
	background: #f5f5f5;
	padding: 5px;
	border-radius: 10px;
`;
const ProgramButton = styled.div`
	background-color: ${(props) => (props.background ? props.background : "#F5F5F5")};
	padding: 8px 8px;
	/* width: 120px; */
	border-radius: 8px;
	cursor: pointer;
	box-shadow: ${(props) => (props.boxShadow ? props.boxShadow : "none")};
`;
const Border = styled.span`
	font-size: 1rem;
	color: #e7e7e7;
	display: inline-block;
	margin: 0 10px;
`;
const ButtonName = styled.h3`
	color: ${(props) => (props.color ? props.color : "#9D9D9D")};
	font-size: 0.95rem;
	font-weight: 500;
	padding: 0 10px;
	text-align: center;
`;

const EmptyContainer = styled.div`
	height: calc(100vh - 280px);
	overflow-y: scroll;
`;
const DownloadButton = styled.button`
	padding: 10px 16px;
	background: #917afd;
	border-radius: 6px;
	font-size: 15px;
	color: #fff;
	cursor: pointer;
`;
const DownloadButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 12px;
`;
