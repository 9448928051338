import React, { createContext, useReducer } from "react";
import TopUpReducer from "./TopUpReducer";

const initialState = {
	// top_up_details: [],
	// selected_period: {},
	top_up_details: {
		data: [],
		selected_period: {},
		selected_program: {},
	},
};

const TopUpStore = ({ children }) => {
	const [topUpState, topUpDispatch] = useReducer(TopUpReducer, initialState);

	return (
		<TopUpContext.Provider value={{ topUpState, topUpDispatch }}>
			{children}
		</TopUpContext.Provider>
	);
};

export const TopUpContext = createContext(initialState);

export default TopUpStore;
