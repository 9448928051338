import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { baseConfig } from "../../../axiosConfig";
import { Context } from "../../../contexts/Store";
import Loader from "../loader/Loader";
import StudentCard from "./StudentCard";

export default function CRClassmate({ data }) {
    const history = useHistory();
    const location = useLocation();
    const { state } = useContext(Context);
    const [isLoading, setLoading] = useState(true);
    const [classmates, setClassmates] = useState([]);

    const search_path = location?.search;
    const classroom_id = new URLSearchParams(search_path).get("c");

    const getClassmates = () => {
        setLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/students/class-room-mates/${classroom_id}/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    items: 30,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;

                if (StatusCode === 6000) {
                    setClassmates(data);
                    setTimeout(() => {
                        setLoading(false);
                    }, 200);
                } else {
                    setTimeout(() => {
                        setLoading(false);
                        alert("It is taking longer than usual");
                    }, 200);
                }
            })

            .catch((error) => {});
    };

    useEffect(() => {
        getClassmates();
    }, [classroom_id]);

    return (
        <CardContainer>
            <LivetitleSection>
                <Livetitle>Classmates</Livetitle>
            </LivetitleSection>
            {!isLoading ? (
                <StudentsTableContainer>
                    <StudentsTable>
                        {/* {students.length > 0 ? ( */}
                        <>
                            <TableHeader>
                                <THContainer>
                                    <THF>No</THF>
                                    <THC></THC>
                                    <THL>Classmates</THL>
                                </THContainer>
                                {classmates.length > 10 && (
                                    <THContainer>
                                        <THF>No</THF>
                                        <THC></THC>
                                        <THL>Classmates</THL>
                                    </THContainer>
                                )}
                                {classmates.length > 20 && (
                                    <THContainer>
                                        <THF>No</THF>
                                        <THC></THC>
                                        <THL>Classmates</THL>
                                    </THContainer>
                                )}
                            </TableHeader>
                            <TableBody>
                                <StudentsContainer>
                                    {/* {classmates.slice(0, 30).map((student, index) => (
                                <StudentCard student={student} index={index} />
                            ))} */}
                                    {classmates.map((student, index) => (
                                        <StudentCard
                                            key={student.id}
                                            student={student}
                                            index={index}
                                            // handleOpenDeleteModal={
                                            // 	handleOpenDeleteModal
                                            // }
                                            // setDeletedStudentID={
                                            // 	setDeletedStudentID
                                            // }
                                        />
                                    ))}
                                </StudentsContainer>
                            </TableBody>
                            <PaginationContainer>
                                <div></div>
                                {/* <Pagination
									paginationData={paginationData}
									setCurrentPage={setCurrentPage}
									currentPage={currentPage}
								/> */}
                            </PaginationContainer>
                        </>
                        {/* ) : ( */}
                        {/* // <NoDataFound />
                        null
					)} */}
                    </StudentsTable>
                </StudentsTableContainer>
            ) : (
                <Loader />
            )}
        </CardContainer>
    );
}
const CardContainer = styled.div``;
const LivetitleSection = styled.div`
    margin-bottom: 20px;
`;
const Livetitle = styled.h4`
    color: #010101;
    font-weight: 500;
    font-size: 22px;
    /* line-height: 29px; */
`;

const StudentsTableContainer = styled.div`
    margin-top: 20px;
`;
const StudentsTable = styled.div`
    border: 1px solid #e7e7e7;
    border-radius: 8px;
    /* padding: 10px; */
`;
const TableHeader = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 10px;
    border-bottom: 1px solid #f4f4f4;
`;
const THContainer = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #aaaaaa;
    padding: 6px 0;
`;
const THF = styled.div`
    width: 30px;
`;
const THC = styled.div`
    width: 40px;
`;
const THL = styled.div`
    width: calc(100% - 75px);
`;

const TableBody = styled.div``;
const StudentsContainer = styled.div`
    display: grid;
    grid-template-rows: repeat(10, 1fr);
    grid-template-columns: repeat(3, 1fr);
    /* gap: 16px; */
    grid-auto-flow: column;
`;
// const StudentContainer = styled.div``;

const PaginationContainer = styled.div`
    margin-bottom: 10px;
`;
