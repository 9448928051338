const TopUpReducer = (state, action) => {
	switch (action.type) {
		case "UPDATE_TOPUP":
			// const top_up_details = {
			// 	...state.top_up_details,
			// 	...action.top_up_details,
			// };

			// localStorage.setItem(
			// 	"top_up_details",
			// 	JSON.stringify(top_up_details)
			// );

			const top_up_details = {
				...state.top_up_details,
				...action.top_up_details,
			};
			return {
				...state,
				top_up_details: top_up_details,
			};
		default:
			return state;
	}
};

export default TopUpReducer;
