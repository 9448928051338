import React, { useRef, useState } from "react";
import styled from "styled-components";

export default function StudentCard({ student, index }) {
	return (
		<StudentDiv>
			<TDF>{index + 1}</TDF>
			<TDC>
				<ProfileImage src={student.image} alt="Icon" />
			</TDC>
			<TDL>
				<StudentName>{student.student_name}</StudentName>
			</TDL>
		</StudentDiv>
	);
}
const StudentDiv = styled.div`
	display: flex;
	align-items: center;
	padding: 10px;
	font-size: 14px;
	font-weight: 500;
	transition: 0.3ms ease-in-out;
	border-bottom: 1px solid #f4f4f4;
	/* background: ${(props) => (props.selected ? "#f5f5f5" : "#fff")}; */

	&:hover {
		background: #f5f5f5;
	}
`;
const TDF = styled.div`
	width: 30px;
	font-weight: 500;
	${StudentDiv}:hover & {
		font-weight: 600;
	}
`;
const TDC = styled.div`
	width: 40px;
`;
const TDL = styled.div`
	width: calc(100% - 75px);
	margin-left: 2px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-weight: 500;
`;
const ProfileImage = styled.img`
	width: 30px;
	height: 30px;
	border-radius: 50%;
	display: block;
	object-fit: cover;
`;
const StudentName = styled.div`
	text-transform: capitalize;
	font-weight: 600;
	font-size: 14px;
	${StudentDiv}:hover & {
		font-weight: 600;
	}
`;
