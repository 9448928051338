import React from "react";
import styled from "styled-components";
import ArrowIcon from "../../../assets/icons/timeline-arrow.png";
import moment from "moment";

export default function DashboardTimeline({ timeline }) {
	return (
		<TimelineSection>
			<TimelineHeader>
				<HeaderTitle>Timeline</HeaderTitle>
				{/* <HeaderButton>View all</HeaderButton> */}
			</TimelineHeader>
			<TimelineContent>
				{timeline.map((item, i) => (
					<TimelineGroup key={i}>
						{/* <TimelineGroupTitleBox>
                            <TimelineGroupTitle>{item.date}</TimelineGroupTitle>
                            <TimelineGroupLine />
                        </TimelineGroupTitleBox> */}
						<TimelineGroupContent>
							<TimelineItem category={item.category}>
								<TimelineItemLeftBox>
									<TimelineItemBadge category={item.category}>
										{item.category}
									</TimelineItemBadge>
									<TimelineItemTitle>
										{item.title}{" "}
									</TimelineItemTitle>
									<TimelineItemContent>
										<TimelineDot />{" "}
										{moment(item.date).format("DD-MM-YYYY")}
										{item.time ? ` / ${item.time}` : null}
									</TimelineItemContent>
								</TimelineItemLeftBox>
								{/* {timeline.is_button_needed && (
                                    <TimelineItemRightBox>
                                        <TimelineIcon src={ArrowIcon} />
                                    </TimelineItemRightBox>
                                )} */}
							</TimelineItem>
						</TimelineGroupContent>
					</TimelineGroup>
				))}
			</TimelineContent>
		</TimelineSection>
	);
}

const TimelineSection = styled.div`
	/* padding: 10px; */
`;
const TimelineHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	/* margin: 1.8rem 0 1.2rem; */
	margin-bottom: 7px;
`;
const HeaderTitle = styled.p`
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	color: #000;
`;
const HeaderButton = styled.span`
	font-weight: 500;
	font-size: 13px;
	line-height: 16px;
	text-transform: capitalize;
	color: #917afd;
`;
const TimelineContent = styled.div``;
const TimelineGroup = styled.div`
	margin-bottom: 10px;
`;
const TimelineGroupTitleBox = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 0.8rem;
`;
const TimelineGroupTitle = styled.p`
	font-family: "inter-medium";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	letter-spacing: 0.12em;
	text-transform: uppercase;
	color: #000000;
	opacity: 0.3;
	width: 40%;
`;
const TimelineGroupLine = styled.span`
	width: 100%;
	height: 0px;
	border: 1px solid #e7e7e7;
	display: block;
	margin-left: 5px;
`;
const TimelineGroupContent = styled.div``;
const TimelineItem = styled.div`
	background: ${(props) =>
		props.category === "Webinar"
			? "rgba(234, 179, 59, 0.15)"
			: "rgba(255, 107, 83, 0.1)"};
	display: flex;
	padding: 1rem;
	border-radius: 16px;
	align-items: center;
	margin-bottom: 1rem;
	justify-content: space-between;

	:last-child {
		margin-bottom: 0px;
	}
`;
const TimelineItemLeftBox = styled.div``;
const TimelineItemBadge = styled.span`
	color: ${(props) => (props.category === "Webinar" ? "#eab33b" : "#FF6B53")};
	font-size: 13px;
	background: #fff;
	padding: 5px 10px;
	border-radius: 2.3rem;
	line-height: 23px;
`;

const TimelineItemTitle = styled.p`
	font-weight: 600;
	font-size: 16px;
	line-height: 26px;
	text-transform: capitalize;
	color: #404040;
	margin: 8px 0 4px;
`;
const TimelineItemContent = styled.p`
	color: #9d9d9d;
	font-weight: 400;
	font-size: 13px;
	line-height: 16px;
	display: flex;
	align-items: center;
`;
const TimelineDot = styled.span`
	width: 4px;
	height: 4px;
	background: #9d9d9d;
	display: block;
	margin: 0 11px;
	border-radius: 50%;
`;
const TimelineItemRightBox = styled.div`
	width: 41px;
	height: 41px;
	background: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
`;
const TimelineIcon = styled.img``;
