import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Context } from "./../../../contexts/Store";
import { baseConfig } from "./../../../axiosConfig";
import Alert from "@mui/material/Alert";

import styled from "styled-components";
import profileImage from "../../../assets/images/profile-image.svg";
import profileIcon from "../../../assets/icons/user.png";
import hiddenIcon from "../../../assets/icons/hidden.png";
import visibleIcon from "../../../assets/icons/visible.png";

import editIcon from "../../../assets/icons/edit-icon.svg";
import Loader from "../../includes/loader/Loader";
import AlertBox from "../../includes/AlertBox";

export default function Profile({ student, getStudentsDetails }) {
    const [isPasswordEdit, setIsPasswordEdit] = useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [fetchedImage, setFetchedImage] = useState(student?.image);
    const [file, setFile] = useState(null);
    const [passwordInput, setPasswordInput] = useState("");
    const { state } = useContext(Context);
    const [isLoading, setLoading] = useState(true);

    // For alert box
    const [isAlert, setIsAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertStatus, setAlertStatus] = useState("");

    const editPic = () => {
        let accessToken = state.user_details.access_token;
        var formdata = new FormData();
        formdata.append("image", file);

        baseConfig
            .put("/students/student-profile/", formdata, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    // <Alert severity="success">
                    //     This is a success alert — check it out!
                    // </Alert>;
                    setIsAlert(true);
                    setAlertStatus("success");
                    getStudentsDetails();
                    setFile(null);
                } else {
                }
            })
            .catch((error, response) => {});
    };

    const onImageChange = (e) => {
        setFile(e.target.files[0]);

        if (e.target.files && e.target.files[0]) {
            setFetchedImage(URL.createObjectURL(e.target.files[0]));
        }
        // editPic();
    };

    useEffect(() => {
        if (file !== null) {
            editPic();
        }
    }, [file]);

    const editPassword = () => {
        let accessToken = state.user_details.access_token;
        var formdata = new FormData();
        formdata.append("password", passwordInput);

        baseConfig
            .put("/students/student-profile/", formdata, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    alert("Password changed successfully");
                    getStudentsDetails();
                } else {
                }
            })
            .catch((error, response) => {});
    };

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 300);
        return () => {
            setLoading(false);
        };
    }, []);

    const updates = () => {
        if (passwordInput.length < 6) {
            alert("The password must contain at least 6 characters.");
        } else {
            setIsPasswordEdit(false);
            editPassword();
        }
    };

    return (
        <MainContainer>
            {/* <AlertBox
                isAlert={isAlert}
                setIsAlert={setIsAlert}
                alertMessage={alertMessage}
                setAlertMessage={setAlertMessage}
                alertStatus={alertStatus}
                setAlertStatus={setAlertStatus}
            /> */}
            {!isLoading ? (
                <SubContainer>
                    <ProfileText>Profile</ProfileText>
                    <ContentBox>
                        <TopPart>
                            <TopLeft>
                                <ImageContainer>
                                    {fetchedImage !== null ? (
                                        <ProfileImage
                                            src={fetchedImage}
                                            alt="Profile"
                                        />
                                    ) : (
                                        <ProfileImage
                                            src={profileImage}
                                            alt="image"
                                        />
                                    )}

                                    <EditIconContainer htmlFor="image-file">
                                        <EditIcon src={editIcon} alt="icon" />
                                    </EditIconContainer>
                                    <input
                                        type="file"
                                        id="image-file"
                                        accept="image/png, image/gif, image/jpeg"
                                        style={{ display: "none" }}
                                        onChange={onImageChange}
                                        onClick={(e) => {
                                            e.target.value = null;
                                        }}
                                    />
                                </ImageContainer>
                            </TopLeft>
                            <TopRight>
                                <NameText>{student?.name}</NameText>
                                {/* <MailText>{student?.mail}</MailText> */}
                            </TopRight>
                        </TopPart>
                        <BottomPart>
                            <DetailListContainer>
                                <DetailListItem>
                                    <ItemLeft>
                                        <CategoryTitle>Name</CategoryTitle>
                                        <DetailCText>
                                            {student?.name}
                                        </DetailCText>
                                    </ItemLeft>
                                    {/* <ItemRight>
                                        <ButtonDiv>Edit</ButtonDiv>
                                    </ItemRight> */}
                                </DetailListItem>
                                <DetailListItem>
                                    <ItemLeft>
                                        <CategoryTitle>Email</CategoryTitle>
                                        <DetailText>{student?.mail}</DetailText>
                                    </ItemLeft>
                                    {/* <ItemRight>
                                        <ButtonDiv>Edit</ButtonDiv>
                                    </ItemRight> */}
                                </DetailListItem>
                                {/* <DetailListPWItem>
                                    <ItemLeft>
                                        <CategoryTitle>Password</CategoryTitle>
                                        <PasswordContainer>
                                            {isPasswordEdit ? (
                                                <InputContainer>
                                                    <PasswordInput
                                                        minLength={6}
                                                        type={
                                                            isPasswordVisible
                                                                ? "text"
                                                                : "password"
                                                        }
                                                        placeholder="Enter new password"
                                                        onInput={(e) =>
                                                            setPasswordInput(
                                                                e.target.value
                                                            )
                                                        }
                                                        value={passwordInput}
                                                    />
                                                    <PWIconContainer
                                                        onClick={() =>
                                                            setIsPasswordVisible(
                                                                !isPasswordVisible
                                                            )
                                                        }
                                                    >
                                                        {isPasswordVisible ? (
                                                            <PasswordIcon
                                                                src={
                                                                    visibleIcon
                                                                }
                                                                alt="icon"
                                                            />
                                                        ) : (
                                                            <PasswordIcon
                                                                src={hiddenIcon}
                                                                alt="icon"
                                                            />
                                                        )}
                                                    </PWIconContainer>
                                                </InputContainer>
                                            ) : (
                                                <DetailText>
                                                    {"*".repeat(
                                                        student?.name?.length
                                                    )}
                                                </DetailText>
                                            )}
                                        </PasswordContainer>
                                    </ItemLeft>
                                    <ItemRight>
										{isPasswordEdit ? (
											<ButtonsContainer>
												<ButtonDiv
													onClick={(e) => {
														e.preventDefault();
														updates();
													}}
												>
													Submit Password
												</ButtonDiv>
												<CButton
													onClick={() =>
														setIsPasswordEdit(false)
													}
												>
													{" "}
													Cancel
												</CButton>
											</ButtonsContainer>
										) : (
											<ButtonDiv
												onClick={() =>
													setIsPasswordEdit(true)
												}
											>
												Change Password
											</ButtonDiv>
										)}
									</ItemRight>
                                </DetailListPWItem> */}
                            </DetailListContainer>
                        </BottomPart>
                    </ContentBox>
                </SubContainer>
            ) : (
                <Loader />
            )}
        </MainContainer>
    );
}

const MainContainer = styled.div`
    /* background: #FFFFFF; */
    padding: 20px 15px;
    /* border: 1px solid #E5E5E5; */
    box-sizing: border-box;
    border-radius: 10px;
    height: calc(100vh - 110px);
    display: flex;
    justify-content: center;
    align-items: center;
`;
const SubContainer = styled.div`
    width: 60%;
    /* background: #fff;
    border: 1px solid #E5E5E5; */
    max-height: calc(100vh - 175px);
    overflow-y: scroll;
    @media (max-width: 1600px) {
        width: 75%;
    }
    @media (max-width: 920px) {
        width: 100%;
    }
`;
const ProfileText = styled.p`
    font-weight: 600;
    font-size: 1.1rem;
    margin-bottom: 15px;
    color: #000;
    text-transform: capitalize;
`;
const ContentBox = styled.div`
    /* max-height: calc(100vh -50px); */
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    box-sizing: border-box;
`;
const TopPart = styled.div`
    display: flex;
    align-items: flex-start;
    padding: 50px 10%;
    border-bottom: 1px solid #e5e5e5;
    @media (max-width: 1080px) {
        /* justify-content: space-between; */
    }
    @media (max-width: 980px) {
        flex-wrap: wrap;
    }
`;
const TopLeft = styled.div`
    @media (max-width: 980px) {
        width: 90%;
        margin: 0 auto;
    }
`;
const ImageContainer = styled.div`
    width: 150px;
    height: 150px;
    border-radius: 50%;
    position: relative;
    @media (max-width: 1080px) {
        width: 125px;
        height: 125px;
    }
    @media (max-width: 980px) {
        margin: 0 auto;
    }
`;
const ProfileImage = styled.img`
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 50%;
    object-fit: cover;
`;
const EditIcon = styled.img`
    display: block;
    width: 50%;
    height: 50%;
    transition: 1.5s;
    animation: wiggle 2s linear infinite;
`;
const EditIconContainer = styled.label`
    position: absolute;
    top: 10%;
    right: 0%;
    background: #ffffff;
    box-shadow: 1px -1px 3px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 1s;
    @media (max-width: 1080px) {
        width: 27px;
        height: 27px;
    }
    &:hover {
        ${EditIcon} {
            animation: none;
        }
    }
`;

const TopRight = styled.div`
    padding: 40px 0px 0px 5%;
    @media (max-width: 980px) {
        width: 90%;
        margin: 0 auto;
    }
`;
const NameText = styled.h6`
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 15px;
    @media (max-width: 980px) {
        text-align: center;
    }
    text-transform: capitalize;
`;
const MailText = styled.p`
    font-size: 1rem;
    color: #000;
    @media (max-width: 980px) {
        text-align: center;
    }
`;
const BottomPart = styled.div`
    padding: 35px 10%;
`;
const DetailListContainer = styled.div``;
const DetailListItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    &:last-child {
        margin-bottom: 0;
    }
`;
const DetailListPWItem = styled.form`
    display: flex;
    justify-content: space-between;
    align-items: end;
    margin-bottom: 40px;
    &:last-child {
        margin-bottom: 0;
    }
`;
const ItemLeft = styled.div``;
const CategoryTitle = styled.p`
    font-size: 0.8rem;
    color: #9d9d9d;
`;
const DetailCText = styled.p`
    font-size: 1rem;
    font-weight: 500;
    color: #000;
    text-transform: capitalize;
`;
const DetailText = styled.p`
    font-size: 1rem;
    font-weight: 500;
    color: #000;
`;
const ItemRight = styled.div``;
const PasswordContainer = styled.div`
    /* height: 35px; */
`;

const InputContainer = styled.div`
    height: 38px;
    width: 320px;
    border: 1px solid #d8d6d6;
    border-radius: 8px !important;
    box-sizing: border-box !important;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1180px) {
        width: 300px;
    }
    @media (max-width: 1080px) {
        width: 260px;
    }
    @media (max-width: 1020px) {
        width: 240px;
    }
`;
const PasswordInput = styled.input`
    height: 38px;
    width: calc(100% - 22px);
    /* border: 1px solid #d8d6d6; */
    /* border-radius: 8px !important; */
    /* box-sizing: border-box !important; */
    /* padding: 8px; */
    font-size: 0.9rem;
    ::placeholder {
        color: #d8d6d6;
        /* opacity: 1; */
    }

    /* animation: slideInBottom 0.4s ease-in-out forwards; */
`;
const PWIconContainer = styled.div`
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;
const PasswordIcon = styled.img`
    width: 80%;
    height: 80%;
    display: block;
`;

const ButtonDiv = styled.button`
    padding: 9px 13px;
    height: 37px;
    border: 1px solid #917afd;
    border-radius: 10px;
    color: #917afd;
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 105px; */
    cursor: pointer;
    font-size: 0.95rem;
    @media (max-width: 1180px) {
        font-size: 0.95rem;
    }
    @media (max-width: 1020px) {
        font-size: 0.9rem;
    }
`;
const CButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
    height: 37px;
    padding: 9px 13px;
    color: #ffffff;
    font-size: 1rem;
    border: 1px solid #ff0048;
    transition: 0.5s;
    cursor: pointer;
    background: #ff0048;
    border-radius: 8px;
    margin-left: 10px;
    &:hover {
        border: 1px solid #522f91;
        /* box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
        transform: translateY(-0.1em); */
    }
    @media (max-width: 1180px) {
        font-size: 0.95rem;
    }
    @media (max-width: 1020px) {
        font-size: 0.9rem;
    }
`;
const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
`;
