import React from "react";
import styled from "styled-components";

import emIcon from "../../../assets/icons/webinar-empty-icon.svg";

export default function EmptyPage({ img, message, title }) {
	return (
		<MainDiv>
			<Icon src={img} alt="icon" />
			{title && <Title>{title}</Title>}
			{message && <Message>{message}</Message>}
		</MainDiv>
	);
}

const MainDiv = styled.div`
	/* width: 100%;
    height: 100%; */
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
`;
const Icon = styled.img`
	width: 250px;
	height: 250px;
	object-fit: contain;
	display: block;
`;
const Message = styled.p`
	font-size: 1rem;
	color: #c1c1c1;
	font-weight: 500;
	/* margin-top: 45px; */
	margin-top: 10px;

	/* text-transform: capitalize; */
`;
const Title = styled.h3`
	color: #000000;
	font-size: 18px;
`;
// const MainDiv = styled.div``;
// const MainDiv = styled.div``;
