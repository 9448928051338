import React from "react";
import styled from "styled-components";
import CourseImage from "../../../assets/images/course-image-dashboard.png";
import ProgressBar from "../ProgressBar";

export default function RecommendedCourseCard({ percentage }) {
    return (
        <Container>
            <Top>
                <ImageBox>
                    <Image src={CourseImage} alt="" />
                </ImageBox>
            </Top>
            <Bottom>
                <Heading>Biology: Topic 3</Heading>
                <Title>Epidemiology and Coronavirus...</Title>
                <ProgressBar
                    current={40}
                    total={100}
                    top="not-required"
                    type="programCard"
                />
            </Bottom>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0.7rem;
    border-radius: 16px;
    border: 1px solid #e5e5e5;
    width: 49%;
`;
const Top = styled.div`
    width: 100%;
    padding-top: 100%;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
`;
const ImageBox = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`;
const Image = styled.img`
    width: 100%;
    display: block;
    object-fit: cover;
    height: 100%;
`;
const Bottom = styled.div`
    margin-top: 1rem;
    width: 100%;
`;
const Heading = styled.p`
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #9d9d9d;
`;
const Title = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-transform: capitalize;
    color: #010101;
    margin: 0.3rem 0;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
`;
