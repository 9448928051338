import React from "react";
import styled from "styled-components";
import errorIcon from "../../assets/images/404.jpg";

export default function NotFoundPage() {
    return (
        <MainContainer>
            {/* <MainTitle>404</MainTitle> */}
            <ErrorImage src={errorIcon} alt="icon" />
            <Subtitle>Oops, page not found</Subtitle>
        </MainContainer>
    );
}
const MainContainer = styled.div`
    background: #ffffff;
    padding: 20px;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 10px;
    height: calc(100vh - 110px);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
    @media (max-width: 950px) {
        overflow-y: scroll;
    }
`;
const MainTitle = styled.h3`
    font-size: 126px;
`;
const ErrorImage = styled.img`
    /* font-size: 126px; */
    display: block;
    width: 360px;
    object-fit: cover;
`;
const Subtitle = styled.p`
    font-size: 26px;
    margin-top: 10px;
    color: #000;
    opacity: 0.7;
    /* margin-top: -40px; */
`;
