import React, { useState, useEffect, useContext } from "react";
import { Context } from "./../../../contexts/Store";
import { baseConfig } from "./../../../axiosConfig";
import styled from "styled-components";
import EmptyPage from "../../includes/emptyPages/EmptyPage";
import WebinarCard from "../../includes/webinar/WebinarCard";
import Loader from "../../includes/loader/Loader";

import pic1 from "../../../assets/images/webinar-pic1.svg";
import pic2 from "../../../assets/images/webinar-pic2.svg";
import pic3 from "../../../assets/images/webinar-pic3.svg";

import emptyPic from "../../../assets/icons/webinar-empty-icon.svg";
import VideoModal from "../../includes/VideoModal";
import Pagination from "../../includes/Pagination";

export default function Webinar() {
    const [selectedWebinarStatus, setSelectedWebinarStatus] =
        useState("Upcoming");
    const [isLoading, setLoading] = useState(true);
    const { state } = useContext(Context);
    const [webinarData, setWebinarData] = useState([]);
    const [completedVideoLink, setCompletedVideoLink] = useState("");

    const [paginationData, setPaginationData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);

    const getWebinarData = () => {
        setLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/students/student-webinars/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    status: selectedWebinarStatus,
                    page: currentPage,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;

                if (StatusCode === 6000) {
                    setWebinarData(data.data);
                    setPaginationData(data.paginator);
                    setTimeout(() => {
                        setLoading(false);
                    }, 200);
                } else {
                    setTimeout(() => {
                        setLoading(false);
                        alert("It is taking longer than usual");
                    }, 600);
                }
            })
            .catch((error) => {});
    };

    useEffect(() => {
        getWebinarData();
    }, [selectedWebinarStatus, currentPage]);

    // For Modal
    const [isVideoModal, setIsVideoModal] = useState(false);
    const [isVideoLoading, setIsVideoLoading] = useState(false);

    const handleOpenVideoModal = () => {
        setIsVideoModal(true);
    };
    const handleCloseVideoModal = () => {
        setIsVideoModal(false);
    };

    return (
        <MainContainer>
            <div>
                <SectionTitle>Webinars</SectionTitle>
            </div>
            <SelectDiv>
                <DashboardButtonDiv>
                    {/* <DashboardButton
                        background={
                            selectedWebinarStatus === "Live"
                                ? "#fff"
                                : "#F5F5F5"
                        }
                        boxShadow={
                            selectedWebinarStatus === "Live"
                                ? "box-shadow: 0px 8px 29px rgba(0, 0, 0, 0.05)"
                                : "none"
                        }
                        // style={{ marginRight: "10px" }}
                        onClick={(e) => {
                            e.preventDefault();
                            setSelectedWebinarStatus("Live");
                        }}
                    >
                        <ButtonName
                            color={
                                selectedWebinarStatus === "Live"
                                    ? "#404040"
                                    : "#9D9D9D"
                            }
                        >
                            Live Webinar
                        </ButtonName>
                    </DashboardButton> */}
                    {/* <Border>|</Border> */}
                    <DashboardButton
                        background={
                            selectedWebinarStatus === "Upcoming"
                                ? "#fff"
                                : "#F5F5F5"
                        }
                        boxShadow={
                            selectedWebinarStatus === "Upcoming"
                                ? "box-shadow: 0px 8px 29px rgba(0, 0, 0, 0.05)"
                                : "none"
                        }
                        onClick={(e) => {
                            e.preventDefault();
                            setSelectedWebinarStatus("Upcoming");
                        }}
                    >
                        <ButtonName
                            color={
                                selectedWebinarStatus === "Upcoming"
                                    ? "#404040"
                                    : "#9D9D9D"
                            }
                        >
                            Upcoming
                        </ButtonName>
                    </DashboardButton>
                    <Border>|</Border>
                    <DashboardButton
                        background={
                            selectedWebinarStatus === "Completed"
                                ? "#fff"
                                : "#F5F5F5"
                        }
                        boxShadow={
                            selectedWebinarStatus === "Completed"
                                ? "box-shadow: 0px 8px 29px rgba(0, 0, 0, 0.05)"
                                : "none"
                        }
                        onClick={(e) => {
                            e.preventDefault();
                            setSelectedWebinarStatus("Completed");
                        }}
                    >
                        <ButtonName
                            color={
                                selectedWebinarStatus === "Completed"
                                    ? "#404040"
                                    : "#9D9D9D"
                            }
                        >
                            Completed
                        </ButtonName>
                    </DashboardButton>
                </DashboardButtonDiv>
            </SelectDiv>

            {!isLoading ? (
                webinarData.length > 0 ? (
                    <DetailedContainer>
                        <WebinarsListContainer>
                            {webinarData.map((item) => (
                                <WebinarCard
                                    item={item}
                                    type={selectedWebinarStatus}
                                    isVideoModal={isVideoModal}
                                    setIsVideoModal={setIsVideoModal}
                                    handleOpenVideoModal={handleOpenVideoModal}
                                    handleCloseVideoModal={
                                        handleCloseVideoModal
                                    }
                                    setCompletedVideoLink={
                                        setCompletedVideoLink
                                    }
                                    setIsVideoLoading={setIsVideoLoading}
                                />
                            ))}
                        </WebinarsListContainer>
                        <Pagination
                            paginationData={paginationData}
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                        />
                    </DetailedContainer>
                ) : (
                    <EmptyContainer>
                        <EmptyPage
                            img={emptyPic}
                            message={
                                selectedWebinarStatus === "Upcoming"
                                    ? `You have no live or upcoming webinars`
                                    : `You have no completed webinars`
                            }
                        />
                    </EmptyContainer>
                )
            ) : (
                <Loader />
            )}
            {isVideoModal === true && (
                <VideoModalBackContainer
                    onClick={() => {
                        setIsVideoModal(false);
                    }}
                >
                    {/* {!isVideoLoading ? ( */}
                    <CenderDiv>
                        <VideoModal
                            isVideoModal={isVideoModal}
                            setIsVideoModal={setIsVideoModal}
                            handleOpenVideoModal={handleOpenVideoModal}
                            handleCloseVideoModal={handleCloseVideoModal}
                            completedVideoLink={completedVideoLink}
                            setIsVideoLoading={setIsVideoLoading}
                            isVideoLoading={isVideoLoading}
                        />
                    </CenderDiv>
                    {/* ) : ( */}
                    {/* <Loader /> */}
                    {/* )} */}
                </VideoModalBackContainer>
            )}
        </MainContainer>
    );
}

const MainContainer = styled.div`
    background: #fff;
    padding: 25px 20px;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 10px;
    height: calc(100vh - 110px);
    /* &::-webkit-scrollbar {
        width: 3px;
    }
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #d6dee1;
        border-radius: 20px;
    } */
    ::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 0;
        /* border: 1px solid black; */
    }
`;
const SectionTitle = styled.h3`
    font-size: 1.3rem;
    font-weight: 500;
    color: #000000;
    margin-bottom: 40px;
`;
const SelectDiv = styled.div`
    display: flex;
    margin-top: 5px;
    margin-bottom: 30px;
`;
const DashboardButtonDiv = styled.div`
    display: flex;
    align-items: center;
    background: #f5f5f5;
    padding: 7px;
    border-radius: 10px;
`;
const DashboardButton = styled.div`
    background-color: ${(props) =>
        props.background ? props.background : "#F5F5F5"};
    padding: 9px 6px;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: ${(props) => (props.boxShadow ? props.boxShadow : "none")};
`;
const ButtonName = styled.h3`
    color: ${(props) => (props.color ? props.color : "#9D9D9D")};
    font-size: 0.95rem;
    font-weight: 500;
    padding: 0 10px;
    z-index: 1;
`;
const Border = styled.span`
    font-size: 1rem;
    color: #e7e7e7;
    display: inline-block;
    margin: 0 10px;
`;
const DetailedContainer = styled.div`
    margin-top: 10px;
    height: calc(100vh - 295px);
    /* min-height: calc(100vh - 295px); */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: scroll;
    padding: 10px 0;
    ::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 0;
    }
`;
const WebinarsListContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    box-sizing: border-box;
    grid-gap: 15px;

    /* border: 1px solid #e5e5e5; */
    @media (max-width: 1500px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
    /* @media (max-width: 1180px) {
        grid-template-columns: 1fr 1fr;
    } */
    @media (max-width: 980px) {
        grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 700px) {
        grid-template-columns: 1fr;
    }
`;
const EmptyContainer = styled.div`
    height: calc(100vh - 295px);
    overflow-y: scroll;

    /* ::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 0;
    } */
`;
const AlertContainer = styled.div`
    position: absolute;
    top: 50px;
    left: 50%;
    min-width: 500px;
    transform: translate(-50%, -50%);
    margin: 0 auto;
`;
const VideoModalBackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    width: 100%;
    height: 100vh;
    z-index: 1002;
    left: 0;
    top: 0px;
    background: rgba(0, 0, 0, 0.3);

    /* cursor: not-allowed; */
`;
const CenderDiv = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;
