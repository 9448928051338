import React from "react";
import styled from "styled-components";
import cardBg from "../../../assets/images/program-card-bg.svg";
import pic3 from "../../../assets/images/upcoming-pic.svg";
import moment from "moment";

export default function LiveClassCard({
    data,
    handleOpenVideoModal,
    setCompletedVideoLink,
    setIsVideoLoading,
}) {
    return (
        <MainDiv
            style={{
                backgroundImage: `url(${data.image ? data.image : pic3})`,
            }}
            key={data.id}
        >
            <ContentBox>
                <TopPart>
                    {data.status === "Live" && (
                        <LiveContainer>
                            <Bullet></Bullet>
                            <LiveText>Live</LiveText>
                        </LiveContainer>
                    )}
                    {data.status === "Live" ? (
                        <DurationText>
                            Start Time:{" "}
                            {moment(data.start_time, ["h:mm A"]).format(
                                "hh:mm A"
                            )}
                        </DurationText>
                    ) : (
                        <DurationText>
                            Date & Time: {data.date}{" "}
                            {moment(data.start_time, ["h:mm A"]).format(
                                "hh:mm A"
                            )}
                        </DurationText>
                    )}
                </TopPart>
                <BottomPart>
                    <LeftPart>
                        {/* <Title>Mathemathics</Title> */}
                        <SubTitle>{data.title}</SubTitle>
                    </LeftPart>
                    <RightPart>
                        {data.status === "Live" ? (
                            <Button href={data.zoom_link} target="blank">
                                Join
                            </Button>
                        ) : data.status === "Upcoming" ? (
                            <></>
                        ) : (
                            <>
                                {data.video_link !== null &&
                                    data.video_link !== "null" &&
                                    data.video_link !== "" && (
                                        <Button
                                            onClick={(e) => {
                                                e.preventDefault();

                                                handleOpenVideoModal();
                                                setCompletedVideoLink(
                                                    data.video_link
                                                );
                                                setIsVideoLoading(true);
                                            }}
                                        >
                                            View
                                        </Button>
                                    )}
                            </>
                        )}
                    </RightPart>
                </BottomPart>
            </ContentBox>
        </MainDiv>
    );
}

const MainDiv = styled.div`
    height: 400px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 16px;
    user-select: none;
`;

const ContentBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;
const TopPart = styled.div`
    display: flex;
    align-items: center;
    padding: 20px;
`;
const LiveContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 12px;
    background: #ffffff;
    border-radius: 16px;
`;
const Bullet = styled.div`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #f00;
    animation: blinker 1s linear infinite;
`;
const LiveText = styled.h6`
    font-size: 0.9rem;
    margin-left: 8px;
    font-weight: 500;
    letter-spacing: 0.6px;
`;
const DurationText = styled.h6`
    letter-spacing: 0.2px;
    font-size: 1rem;
    color: #fff;
    margin-left: 15px;
    /* text-shadow: 1px 0.2px #fff; */
    text-shadow: 1px 1px 1px #000, 1px 1px 2px black;
`;
const BottomPart = styled.div`
    background: linear-gradient(
        360deg,
        #000000 17.23%,
        rgba(0, 0, 0, 0) 98.73%
    );
    border-radius: 0px 0px 16px 16px;
    /* height: 120px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
`;
const LeftPart = styled.div``;
const Title = styled.h6`
    font-size: 1rem;
    color: #fff;
`;
const SubTitle = styled.h6`
    font-size: 1.15rem;
    color: #fff;
    font-weight: 600;
    margin-top: 8px;
`;
const RightPart = styled.div``;
const Button = styled.a`
    display: inline-block;
    padding: 12px 25px;
    background: #fff;
    border-radius: 24px;
    font-size: 0.9rem;
    font-weight: 600;
    color: #917afd;
    cursor: pointer;
`;
// const MainDiv = styled.div``;
// const MainDiv = styled.div``;
// const MainDiv = styled.div``;
