import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Context } from "./../../../contexts/Store";
import { baseConfig } from "./../../../axiosConfig";
import Loader from "../../includes/loader/Loader";
import EmptyPage from "../../includes/emptyPages/EmptyPage";
import styled from "styled-components";
import ProgramCard from "../../includes/programsCommonComponents/ProgramCard";
import WelcomeBox from "../../includes/programsCommonComponents/WelcomeBoxBig";
// import programData from "../../includes/programsCommonComponents/ProgramDummyData.js"
import Mathemathics from "../../../assets/images/program-card-bg.svg";
import Physics from "../../../assets/images/physics.svg";
import Chemistry from "../../../assets/images/chemistry.svg";
import Biology from "../../../assets/images/biology.svg";
import English from "../../../assets/images/english.svg";
import Trignometry from "../../../assets/images/trignometry.svg";
import QuantumPhysics from "../../../assets/images/quantum-physics.svg";
import MolecularChemistry from "../../../assets/images/molecular-chemistry.svg";
import InheritanceInBiology from "../../../assets/images/inheritance-in-biology.svg";

export default function Program() {
	const { state } = useContext(Context);
	const [student, setStudent] = useState();
	const [isLoading, setLoading] = useState(true);
	const [selectedProgramStatus, setSelectedProgramStatus] = useState("My Subjects");
	const [subjects, setSubjects] = useState([]);
	const [programData, setProgramData] = useState([
		{
			id: 1,
			title: "Mathemathics",
			image: Mathemathics,
		},
		{
			id: 2,
			title: "Physics",
			image: Physics,
		},
		{
			id: 3,
			title: "Chemistry",
			image: Chemistry,
		},
		{
			id: 4,
			title: "Biology",
			image: Biology,
		},
		{
			id: 5,
			title: "English",
			image: English,
		},
		{
			id: 6,
			title: "Trignometry",
			image: Trignometry,
		},
		{
			id: 7,
			title: "QuantumPhysics",
			image: QuantumPhysics,
		},
		{
			id: 8,
			title: "MolecularChemistry",
			image: MolecularChemistry,
		},
		{
			id: 9,
			title: "InheritanceInBiology",
			image: InheritanceInBiology,
		},
	]);

	const getSudentsDetais = () => {
		setLoading(true);
		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`/students/student-profile/`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setStudent(data.data);
					setTimeout(() => {
						// setLoading(false);
					}, 200);
				} else {
					setTimeout(() => {
						// setLoading(false);
					}, 200);
				}
			})
			.catch((error) => {});
	};

	const getSubjects = () => {
		setLoading(true);
		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`/students/student-subjects/`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;

				if (StatusCode === 6000) {
					setSubjects(data.data);
					setTimeout(() => {
						setLoading(false);
					}, 200);
				} else {
					setTimeout(() => {
						setLoading(false);
					}, 200);
				}
			})
			.catch((error) => {});
	};

	useEffect(() => {
		getSudentsDetais();
		getSubjects();
	}, []);

	return (
		<MainContainer>
			{!isLoading ? (
				<>
					<SectionTitle>My Program</SectionTitle>
					{/* <ScrollDiv> */}
					<div style={{ padding: "15px" }}>
						<WelcomeBox student_data={student} />
						{/* <SelectDiv>
                            <ProgramButtonDiv>
                                <ProgramButton
                                    background={
                                        selectedProgramStatus === "My Subjects"
                                            ? "#fff"
                                            : "#F5F5F5"
                                    }
                                    boxShadow={
                                        selectedProgramStatus === "My Subjects"
                                            ? "box-shadow: 0px 8px 29px rgba(0, 0, 0, 0.05)"
                                            : "none"
                                    }
                                    // style={{ marginRight: "10px" }}
                                    onClick={() => {
                                        setSelectedProgramStatus("My Subjects");
                                    }}
                                >
                                    <ButtonName
                                        color={
                                            selectedProgramStatus ===
                                            "My Subjects"
                                                ? "#404040"
                                                : "#9D9D9D"
                                        }
                                    >
                                        My Subjects
                                    </ButtonName>
                                </ProgramButton>
                                <ProgramButton
                                    background={
                                        selectedProgramStatus === "Free Course"
                                            ? "#fff"
                                            : "#F5F5F5"
                                    }
                                    boxShadow={
                                        selectedProgramStatus === "Free Course"
                                            ? "box-shadow: 0px 8px 29px rgba(0, 0, 0, 0.05)"
                                            : "none"
                                    }
                                    onClick={() => {
                                        setSelectedProgramStatus("Free Course");
                                    }}
                                >
                                    <ButtonName
                                        color={
                                            selectedProgramStatus ===
                                            "Free Course"
                                                ? "#404040"
                                                : "#9D9D9D"
                                        }
                                    >
                                        Free Course
                                    </ButtonName>
                                </ProgramButton>
                            </ProgramButtonDiv>
                        </SelectDiv> */}
						{selectedProgramStatus === "My Subjects" && (
							<ProgramCardsSection>
								{subjects.map((item) => (
									<ProgramCard key={item.id} data={item} />
								))}
							</ProgramCardsSection>
						)}
						{selectedProgramStatus === "Free Course" && (
							<ProgramCardsSection>
								{programData.map((item) => (
									<ProgramCard key={item.id} data={item} />
								))}
							</ProgramCardsSection>
						)}
					</div>
					{/* </ScrollDiv> */}
				</>
			) : (
				<Loader />
			)}
		</MainContainer>
	);
}

const MainContainer = styled.div`
	background: #ffffff;
	/* padding: 20px 15px 5px; */
	border: 1px solid #e5e5e5;
	box-sizing: border-box;
	border-radius: 10px;
	height: calc(100vh - 110px);
	overflow-y: scroll;
	/* &::-webkit-scrollbar {
        width: 3px;
    }
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #d6dee1;
        border-radius: 20px;
    } */
`;
// const ScrollDiv = styled.div`
//     height: calc(100vh - 160px);
//     overflow-y: scroll;
//     ::-webkit-scrollbar {
//         width: 8px;
//     }
//     ::-webkit-scrollbar-thumb {
//         background: #d6dee1;
//         border-right: 6px white solid;
//         background-clip: padding-box;
//         border-radius: 24px;
//     }
// `;
const SectionTitle = styled.h3`
	font-size: 1.2rem;
	font-weight: 500;
	color: #000000;
	/* margin-bottom: 10px; */
	padding: 15px 20px 0;
`;
const SelectDiv = styled.div`
	display: flex;
	margin-top: 5px;
	margin-bottom: 20px;
`;
const ProgramButtonDiv = styled.div`
	display: flex;
	align-items: center;
	background: #f5f5f5;
	padding: 6px;
	border-radius: 10px;
`;
const ProgramButton = styled.div`
	background-color: ${(props) => (props.background ? props.background : "#F5F5F5")};
	padding: 10px 8px;
	border-radius: 8px;
	cursor: pointer;
	box-shadow: ${(props) => (props.boxShadow ? props.boxShadow : "none")};
`;
const ButtonName = styled.h3`
	color: ${(props) => (props.color ? props.color : "#9D9D9D")};
	font-size: 16px;
	font-weight: 500;
	padding: 0 10px;
`;
const ProgramCardsSection = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-gap: 15px;

	@media (max-width: 1500px) {
		grid-template-columns: 1fr 1fr 1fr;
	}
	/* @media (max-width: 1180px) {
        grid-template-columns: 1fr 1fr;
    } */
	@media (max-width: 980px) {
		grid-template-columns: 1fr 1fr;
	}
	/* @media (max-width: 840px) {
        grid-template-columns: 1fr;
    } */
`;
